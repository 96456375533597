<template>
  <div class="batchmode-launched">
    <v-row class="px-lg-8 ml-lg-8 cts-transaction-launched-content">
      <v-col class="pl-lg-6 ml-lg-4 pb-0" cols="12">
        <h1
          v-if="error"
          id="processes-transaction_launched-text-title_processing"
          class="cts-color-darken"
        >
          <v-icon
            id="processes-transactions_launched-text-icon_loading"
            class="mr-2"
            color="red"
          >
            {{const_icons.ERROR}}
          </v-icon>
          {{$vuetify.lang.t('$vuetify.transactionProcessingBatchModeError')}}
        </h1>
        <h1
          v-else-if="steps.some(x => !x.completed)"
          id="processes-transaction_launched-text-title_processing"
          class="cts-color-darken"
        >
          <v-icon
            id="processes-transactions_launched-text-icon_loading"
            class="mr-2 cts-icon-animated animated-loading-icon"
            color="orange"
          >
            cached
          </v-icon>
          {{$vuetify.lang.t('$vuetify.transactionProcessingBatchMode')}}
        </h1>

        <h1 v-else class="cts-color-darken">
          <v-icon id="processes-transactions_launched-text-icon_ok" class="mr-2" color="green">
            {{const_icons.CHECK}}
          </v-icon>
          {{$vuetify.lang.t('$vuetify.transactionsProcessedBatchMode')}}
        </h1>

        <p v-if="!error" id="processes-transaction_launched_ok-text-description" class="pl-2 mt-4"
           v-html="$vuetify.lang.t('$vuetify.batchModePreviewText')">
        </p>
        <p v-if="!error" class="pl-2 mt-4">{{$vuetify.lang.t('$vuetify.batchModePreviewText1')}}</p>
        <p v-if="error" class="pl-2 mt-4">{{
            $vuetify.lang.t('$vuetify.transactionProcessingBatchModeErrorDescription')
          }}</p>
      </v-col>

      <!-- <v-divider class="mx-12 mb-2" /> -->

      <v-col v-if="error" class="pl-lg-6 ml-lg-4 pb-0" cols="12">

      </v-col>

      <v-card
        v-for="step in steps"
        v-else
        :key="step.n"
        class="mx-lg-12 my-2 cts-card-transaction cts-w-100 pa-4"
        outlined
      >
        <v-row class="cts-font-size-4 " justify="space-between">
          <v-col cols="12">
            <v-icon
              v-if="step.completed"
              id="processes-transactions_launched-text-icon_loading_step"
              class="pr-2"
              color="green"
            >
              done
            </v-icon>
            <v-icon
              v-else-if="currentStep === step.n"
              id="processes-transactions_launched-text-icon_loading_step"
              class="animated-loading-icon pr-2"
              color="orange"
            >
              cached
            </v-icon>
            <v-icon
              v-else
              id="processes-transactions_launched-text-icon_loading_step"
              class="pr-2"
              color="orange"
            >
              hourglass_empty
            </v-icon>

            <span>{{step.title}}</span>
            <span v-if="currentStep === 2 && step.n === 2">:&nbsp;{{
                extraFiles.filter(x => x.success).length
              }}/{{extraFiles.length}}</span>
          </v-col>
        </v-row>
      </v-card>
      <v-row v-if="!steps.some(x => !x.completed)" class="ma-0 pa-0 hidden-md-and-down px-6 mx-6 pt-6" dense
             justify="end">
        <v-btn
          v-if="checkPermission(const_permissions.SECTION_TRANSACTIONS)"
          id="processes-transaction_launched-button-go_to_transactions"
          class="cts-button-tertiary mr-4"
          small
          @click="goToTransactionsView"
        >
          {{$vuetify.lang.t('$vuetify.transactionRedirectToTransactions')}}
          <v-icon class="float-right" small>{{const_icons.TRANSACTIONS}}</v-icon>
        </v-btn>
        <v-btn
          id="processes-transaction_launched-button-go_to_processes"
          class="cts-button-tertiary"
          small
          @click="goToProcessesView"
        >
          {{$vuetify.lang.t('$vuetify.transactionRedirectToProcesses')}}
          <v-icon class="float-right" small>{{const_icons.PROCESSES}}</v-icon>
        </v-btn>
      </v-row>
    </v-row>

  </div>
</template>

<script>
import const_icons from "@/constants/icons"
import const_permissions from "@/constants/permissions"
import {checkPermission} from "@/util/store_utils"
import {redirect} from "@/util/router_utils"
import {TRANSACTIONS, PROCESSES} from "@/constants/router/routes"

export default {
  name: "BatchModeLaunchedSection",
  props: {
    extraFiles: Array,
    uploadingFiles: Boolean,
    error: Boolean
  },

  data() {
    return {
      checkPermission,
      const_permissions,
      const_icons,
      currentStep: 1,

      steps: [
        {n: 1, title: this.$vuetify.lang.t('$vuetify.batchModeProcessLaunchedStep1'), completed: false},
        {n: 2, title: this.$vuetify.lang.t('$vuetify.batchModeProcessLaunchedStep2'), completed: false},
        {n: 3, title: this.$vuetify.lang.t('$vuetify.batchModeProcessLaunchedStep3'), completed: false},
        {n: 4, title: this.$vuetify.lang.t('$vuetify.batchModeProcessLaunchedStep4'), completed: false},
      ]
    }
  },

  computed: {
    allFilesUploaded() {
      return this.extraFiles.every(x => x.success)
    }
  },

  methods: {
    loadingExtraFiles() {
      setTimeout(() => {
        this.$set(this.steps[this.currentStep - 1], "completed", true)
        this.currentStep++
      }, 1000)
    },

    goToTransactionsView() {
      redirect(TRANSACTIONS)
    },

    goToProcessesView() {
      redirect(PROCESSES)
    }
  },

  created() {
    if (this.extraFiles.length > 0) {
      this.loadingExtraFiles()

    } else {
      this.$set(this.steps[0], "completed", true)
      this.$set(this.steps[1], "completed", true)
      this.currentStep += 2
      this.$set(this.steps[2], "completed", true)
      this.$set(this.steps[3], "completed", true)
      this.$emit("endProcess")
    }
  },

  watch: {
    // uploadingFiles(newValue) {
    //   if (!newValue) {
    //     setTimeout(() => {
    //       this.$set(this.steps[2], "completed", true)
    //       this.$set(this.steps[3], "completed", true)
    //     }, 1000)
    //   }
    // },

    currentStep(newValue) {
      if (newValue !== 2) {
        this.$set(this.steps[newValue - 1], "completed", true)
      }

      if (newValue == 3 && !this.uploadingFiles) {
        this.currentStep++
      }
    },

    allFilesUploaded() {
      setTimeout(() => {
        this.$set(this.steps[1], "completed", true)
        this.$set(this.steps[2], "completed", true)
        this.$set(this.steps[3], "completed", true)
      }, 500)
    }
  }
}
</script>

<style lang="scss">
.batchmode-launched {
  .v-stepper {
    border-radius: 4px !important;
    box-shadow: none !important;
  }

  .v-stepper__step__step {
    background: var(--primary-alternative) !important;
  }

  .v-stepper__content {
    border-left: 1px solid var(--primary-alternative) !important;
  }

  .v-stepper__label {
    width: 100% !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

.animated-loading-icon {
  animation: loading 1s linear 0s infinite normal forwards;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animated-cloud-icon {
  animation: cloud 2s ease 0s infinite normal forwards;
}

@keyframes cloud {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
</style>
<template>
  <span>
    <v-tooltip v-if="mode=='icon'" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small v-bind="attrs" v-on="on" @click.stop="downloadTransactionCertificate">
          <v-icon aria-hidden="false"
                  class="cts-color-primary-alternative">{{const_icons.DOWNLOAD_DOCUMENT}}</v-icon>
        </v-btn>
      </template>
      <span>{{$vuetify.lang.t('$vuetify.transactionDownloadFile')}}</span>
    </v-tooltip>
    <v-btn v-else :class="mobile ? 'cts-button-standard-mobile' : 'cts-button-standard'" :disabled="buttonDisabled"
           color="primary"
           icon small @click="downloadTransactionCertificate">
    <v-icon aria-hidden="false" small>description</v-icon>
      {{$vuetify.lang.t('$vuetify.transactionDownloadFile')}}
    </v-btn>
  </span>
</template>

<script>
import const_icons from "@/constants/icons";
import {getTransactionCertification} from "@/services/transactionServices";

export default {
  name: "downloadCertificate",
  components: {},
  props: {
    hash: String,
    buttonDisabled: Boolean,
    mode: String,
    mobile: Boolean
  },
  data() {
    return {
      const_icons,
      contextMessage: false,
      error: null,
      errorMessage: ''
    }
  },
  methods: {
    downloadTransactionCertificate() {
      getTransactionCertification(this.$props.hash)
    }
  }
}
</script>

<style scoped>

</style>

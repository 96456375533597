import Axios from "@/plugins/axios"
import {BATCH_UPLOAD} from "@/constants/backOfficeRoutes"

export const getBatchId = async (processCode, name) => {
  const url = BATCH_UPLOAD.ROUTE_GET_BATCH_ID
    .replace("$process_code$", processCode)
  const params = {}
  if (name) {
    params.name = name
  }
  const res = await Axios.post(url, params)
  return res
}

export const uploadDescriptor = async (processCode, batchId, data) => {
  const url = BATCH_UPLOAD.ROUTE_UPLOAD_DESCRIPTOR
    .replace("$process_code$", processCode)
    .replace("$batch_id$", batchId)

  const res = await Axios.post(url, data)
  return res
}

export const closeProcess = async (processCode, batchId, data) => {
  const url = BATCH_UPLOAD.ROUTE_CLOSE_BATCH_UPLOAD
    .replace("$process_code$", processCode)
    .replace("$batch_id$", batchId)

  const res = await Axios.post(url, data)

  return res
}
export const cancelBatch = async (batchId) => {
  const url = BATCH_UPLOAD.ROUTE_CANCEL_BATCH
    .replace("$batch_id$", batchId)

  const res = await Axios.post(url)

  return res
}



<template>
  <div>
    <v-row class="px-0" dense>
      <v-col class="pr-8" cols="12" lg="7">
        <section-title
          :text="$vuetify.lang.t('$vuetify.batchModePreviewStep')"
          icon="visibility"
          idName="batch-mode"
        />


        <p class="cts-font-size-4 pl-2 pt-4" v-html="$vuetify.lang.t('$vuetify.batchModeCSVTableSubtitle')"></p>
        <v-col class="px-2" cols="12">
          <v-card outlined style="border-radius: 4px">
            <RecipientPreviewExpandableTable
              :recipients="csvData"
              :rowsToShow="5"
              class="bottom"
            />

          </v-card>

        </v-col>

        <!-- <v-divider class="my-4"></v-divider>

        <p class="cts-font-size-4">Aquí se muestra un resumen de los ficheros adjuntos</p>

        <ResourceTable
          :requiredFiles="requiredFiles"
          :extraFiles="extraFiles"
          :csvData="csvData"
        /> -->
      </v-col>

      <v-col class="pl-8" cols="12" lg="5">
        <SectionDataPreviewResume
          :nOfRecipients="csvData.length"
          :showLanguageSelector="false"
        />

        <section-data-preview-schedule-shipment
          v-if="checkPermission(const_permissions.SCHEDULE_SHIPMENT)"
          v-model="scheduleOptions"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SectionTitle from '@/components/sections/processes/processView/sectionTitle'
import {checkPermission} from "@/util/store_utils"
import const_permissions from "@/constants/permissions"
import SectionDataPreviewResume from "@/components/sections/processes/processView/sectionDataPreviewResume.vue"
import RecipientPreviewExpandableTable
  from "@/components/sections/processLaunch/models/batchMode/RecipientPreviewExpandableTable"
import SectionDataPreviewScheduleShipment
  from "@/components/sections/processes/processView/sectionDataPreviewScheduleShipment.vue";

// import ResourceTable from "@/components/sections/processLaunch/models/batchMode/ResourceTable"

export default {
  name: "BatchModeSectionPreview",
  components: {
    SectionDataPreviewScheduleShipment,
    SectionTitle,
    SectionDataPreviewResume,
    RecipientPreviewExpandableTable,
    // ResourceTable
  },

  props: {
    csvFilename: String,
    csvData: Array,
    extraFiles: Array,
    requiredFiles: Array
  },

  data() {
    return {
      checkPermission,
      const_permissions,
      scheduleOptions: { scheduleBatch: false, scheduledAtTimestamp: null }
    }
  },

  watch: {
    scheduleOptions: {
      handler(val) {
        this.$emit("updateScheduleTime", val)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
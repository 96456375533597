export default {
  badge: 'Badge',
  close: 'Close',
  dataIterator: {
    noResultsText: 'No item matches the search',
    loadingText: 'Loading...'
  },
  dataTable: {
    itemsPerPageText: 'Rows per page:',
    ariaLabel: {
      sortDescending: ': Descending sort. Click to remove sort.',
      sortAscending: ': Ascending sort. Click for descending sort.',
      sortNone: ': No sort. Click for ascending sort.',
      activateAscending: 'Activate ascending',
      activateDescending: 'Activate descending',
      activateNone: 'Deactivate all'
    },
    sortBy: 'Sort by'
  },
  dataFooter: {
    itemsPerPageText: 'Items per page:',
    itemsPerPageAll: 'All',
    nextPage: 'Next page',
    prevPage: 'Previous page',
    firstPage: 'First page',
    lastPage: 'Last page',
    pageText: '{0}-{1} of {2}'
  },
  datePicker: {
    itemsSelected: '{0} selected',
    prevMonthAriaLabel: 'previous month',
    nextMonthAriaLabel: 'next month',
  },
  noDataText: 'No data available',
  carousel: {
    prev: 'Previous view',
    next: 'Next view',
    ariaLabel: {
      delimiter: 'Carousel slide {0} of {1}'
    }
  },
  calendar: {
    moreEvents: '{0} more'
  },
  fileInput: {
    counter: '{0} files',
    counterSize: '{0} files ({1} in total)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  home: 'Home',
  templates: 'Flows',
  accounts: 'Accounts',
  processes: 'Processes',
  transactions: 'Transactions',
  statistics: 'Statistics',
  support: 'Support',
  contacts: 'Contacts',
  administration: 'Administration',
  search: 'Search',
  appearance: 'Styles',
  users: 'Users',
  alastria: 'Alastria',
  billing: 'Billing',
  account: 'Manage my account',
  admin: 'Administration',
  demos: 'Demos',
  selectedSubaccount: 'Selected sub-account',
  withoutSubaccount: 'Without sub-accounts',
  groups: 'groups',
  filterDate: 'Filter by date',
  subaccount: 'Sub-account',
  subaccounts: 'Sub-accounts',
  costCenters: 'Cost centers',
  subaccountsAssigned: 'Sub-accounts assigned',
  defaultCostCenter: 'Default cost center',
  selectedCostCenter: 'Selected cost center',
  availableCostCenters: 'Available cost centers',
  changeDefaultCostCenter: 'Change default cost center',
  costCenterDialogText: 'You can change the cost center selected for the transaction if you wish. This will not change the default cost center, it will only be used on a one-off basis for this transaction',
  costCenterDialogTextAccount: 'You can change the default cost center if you wish. This action will set the selected cost center as the default center',
  costCenterSelectedTitle: 'Selected cost center',
  activeSubaccount: 'Active sub-account',
  user: 'User',
  userData: 'User data',
  userCECOS: 'Cost centers',
  userCECO: 'Cost center',
  userCodeCECO: 'Code',
  userCECOPrimary: 'By default',
  userCECOCodeTitle: 'Cost center code',
  password: 'Password',
  passwordchange: 'Change password',
  oldpassword: 'Current password',
  newpassword: 'New password',
  updatePassword: 'Update password',
  repeatpassword: 'Repeat password',
  passwordrequirements: 'The password must be at least 8 characters long and meet at least 3 of the following additional rules:',
  passwordMinOneLowercase: 'Minimum of 1 lower case letter.',
  passwordMinOneUppercase: 'Minimum of 1 upper case letter.',
  passwordMinOneNumber: 'Minimum of 1 number.',
  passwordMinOneSpecialChar: 'Minimum of 1 special character.',
  passwordMinEightChars: 'Minimum of 8 characters.',
  passwordCompares: 'Password comparison required.',
  passwordFieldError: 'Field error, check rules.',
  passwordrequirementsMoreThanEightChars: 'The password must be at least 8 characters long',
  notequalpasswords: 'Passwords do not match',
  samepasswordthanolder: 'The new password matches the old one',
  emptyOldPassword: 'You have not entered the previous password',
  role: 'Role',
  lastConnection: 'Last connection',
  languageSelector: 'Select a language',
  logout: 'Log out',
  others: 'Other',
  continue: 'Continue',
  continueWSend: 'Continue with dispatch',
  sendRecoveryForm: 'Send Request',
  restartFilters: 'Restart filters',
  back: 'Back',
  backToMethods: 'Recovery methods',
  moreInfo: 'More information',
  activateuser: 'Activate user',
  exit: 'Exit',
  platformVersion: 'Platform version',
  termsAndConditionsCompliance: 'Terms and Conditions',
  privacyPoliciesCompliance: 'Data Protection Policy',
  cookiesPoliciesCompliance: 'Cookies Policy',
  cookiesPoliciesCertySign: 'Cookies in CertySign',
  alertSizeFiles: 'All files must be a maximum of 8 Mb',
  login: 'Login',
  noSubaccountsMessage: 'There are no sub-accounts assigned',
  telephone: 'Telephone',
  name: 'Name',
  lastName: 'Surname',
  email: 'E-mail',
  omit: 'Omit',
  id: 'Client reference',
  clear: 'Clear',
  add: 'Add',
  recipients: 'Recipients',
  shipmentData: 'Dispatch data',
  prevAndSend: 'Preview and send',
  previewFileSize: 'The file must be a maximum of 15 Mb',
  rulesFilesSize: 'All files must be a maximum of 15 Mb',
  sectionDataFieldsDescription: 'At the bottom, you have the different fields required to send the transaction to the recipients. The contact fields will be filled in automatically, from the recipient\'s data',
  sectionDataFieldsNotAditionalFields: 'This process does not have additional fields, complete the dispatch of your transaction from Preview.',
  sectionPreviewSubtitle: 'At the bottom, you can browse between recipients to individually check each dispatch',
  manual_version: 'Displaying the latest version of the user manual.',
  view_login: 'Login',
  view_transactions: 'Transactions',
  view_transaction: 'Transaction details',
  view_process: 'Launch process',
  view_processes: 'Processes',
  view_statistics: 'Statistics',
  view_support: 'Help and support',
  view_administration: 'Administration',
  view_groups: 'Groups',
  view_group: 'Group details',
  view_users: 'Users',
  view_account: 'My account',
  view_remember_password: 'Remember password',
  view_change_password: 'Change password',
  view_activate_user: 'Activate user',
  view_new_password: 'New password',
  view_contacts: 'Contacts',
  view_user: 'User details',
  view_withoutsubaccounts: '',
  view_forbidden: '',
  view_root: '',
  view_error500: 'Error',
  searchAgain: 'Search again',
  recipient: 'Recipient',
  sender: 'Sender',
  namesender: 'Name of sender',
  subject: 'Subject',
  emailbody: 'E-mail body',
  resourcespreview: 'Resources preview',
  attach: 'Attach',
  actions: 'Actions',
  changePassword: 'Update password',
  sessionClosed: 'Session closed correctly',
  noPermissionMessage: 'You do not have permission to access this section',
  download: 'Download',
  viewDocument: 'View Document',
  accept: 'Accept',
  reject: 'Reject',
  change: 'Change',
  addVariables: 'Add variable',
  resources: 'Resouces',
  launchDataKo: 'The data provided is not valid to make the dispatch',
  launchDataOk: 'The data is correct, you may proceed with the dispatch',
  addNewRecipient: 'Add new recipient',
  transactionRecipients: 'Transaction recipients',
  noRecipientsSelected: 'No recipient selected',
  recipientsSelected: 'Selected',
  recipientSelected: 'Selected',
  preview: 'Preview',
  summary: 'Summary',
  transaction_summary: 'Dispatch data',
  PDFpreview: 'Preview PDF',
  previewing: 'Previewing: ',
  notAvailable: 'Not available',
  hide: 'Hide',
  send: 'Send',
  showFile: 'View file',
  error500Message: 'An error has occurred. If the problem persists, you can contact support via ',
  thisForm: 'this form',
  error401Message: 'The session has expired',
  withoutData: 'No data',
  notInformed: 'Not informed',
  loginTitleText: 'Login',
  changePasswordTitleText: 'Password change',
  activateUserTitleText: 'User activation',
  homeWelcomeMessageTitle: 'Welcome to CertySign',
  homeWelcomeMessageSubtitle: 'Certified delivery flow and transaction management environment',
  changePasswordUserTitleText: 'Set new password',
  activateUserSubtitleText: 'For user activation, it is necessary to enter a new password',
  changePasswordUserSubtitleText: 'To set a new password fill in the fields',
  activateUserTitleTextWithoutHash: 'Invalid URL',
  activateUserSubtitleTextWithoutHash: 'The URL that you are using is invalid. If you wish, you can request a new URL through the option "I have forgotten my password" on the login screen.',
  goToLogin: 'Login',
  activeFilters: 'Active filters',
  rememberPassword: 'Have you forgotten your password?',
  rememberPasswordTitleText: 'Recover password',
  supportContact: 'Contact support',
  rememberPasswordSubTitleText: 'Select a method to recover password',
  rememberPasswordEmailResponse: 'If the user provided is registered, you will receive an email with instructions to change the password',
  rememberPasswordWebResponse: 'If the data entered is correct, our support team will contact you by email and provide you with instructions to change your password',
  rememberPasswordPhoneResponse: 'If the data entered is correct, our support team will contact you by telephone and provide you with instructions to change your password',
  expiredPasswordResponse: 'Your password has expired, you will receive an email shortly with instructions to set a new password',
  rememberPasswordSupportEmail: 'Recovery email',
  rememberPasswordSupportEmailSubtitle: 'We will send you an email from which you can recover your password',
  rememberPasswordSupportEmailText: 'Please fill in the form with your email registered on CertySign',
  rememberPasswordSupportWeb: 'Web support',
  rememberPasswordSupportWebSubtitle: 'Send an email to the CertySign technical support team',
  rememberPasswordSupportWebText: 'Fill in the details and the technical support team will contact you as soon as possible.',
  emailSupportServiceHours: 'Service hours:',
  rememberPasswordSupportPhone: 'Telephone support',
  rememberPasswordSupportPhoneSubtitle: 'Phone call to the CertySign technical support team',
  rememberPasswordSupportPhoneText: 'Fill in the details and the technical support team will call you as soon as possible.',
  phoneSupportServiceHours: 'Telephone support hours:',
  rememberPasswordNotSupportBySchedule: 'Option disabled for being outside service hours',
  rememberPasswordMondayToFriday: 'Monday through Friday:',
  rememberPasswordSaturdaySundayAndOthers: 'Saturdays, Sundays and holidays:',
  rememberPasswordNotIncludedDays: '(holidays not included)',
  supportFinalSendMessage: 'Your request has been sent to our Support department',
  goBackToMethods: 'Select another method',
  rememberPasswordContactName: 'Name and surname',
  goToC2C: 'Request call',
  messageDescription: 'Message',
  company: 'Company',
  legalCheckboxText: 'I accept the Terms of use of the website and data protection policy',
  selectHelps: 'How can we help you?',
  selectHelpsTooltip: 'Help and support',
  helpManual: 'See manual',
  helpGuide: 'See quick guide',
  helpSupport: 'Contact with support',
  helpSupportPhonePreffered: 'Preferred contact by phone',
  helpSupportPhoneSchedule: 'Telephone service hours, weekdays',
  requiredEmail: 'E-mail required',
  requiredPhone: 'Telephone required',
  requiredName: 'Name required',
  requiredEmailFormat: 'Invalid e-mail format',
  requiredPhoneFormat: 'Incorrect telephone format',
  requiredTextarea: 'Message required',
  tag_signature: 'Electronic signature',
  tag_email: 'E-mail',
  tag_sms: 'SMS',
  tag_cert_email: 'Certified email',
  tag_landing: 'Web page',
  tag_biometric_signature: 'Biometric signature',
  tag_postal: 'Postal',
  processesViewListTitle: 'Processes',
  processesViewListDescription: 'List with all processes available on CertySign',
  tags: 'Tags',
  searchs: 'Search',
  processInfo: 'Process information',
  processesCategoriesFilter: 'Filter by category',
  processesTagFilter: 'Filter by tag',
  processesTagOthers: 'others',
  processesSearch: 'Search processes',
  searchTransactions: 'View transactions',
  searchTransaction: 'View transaction',
  deleteFilters: 'Delete filters',
  withoutResults: 'No results',
  withoutProcessResults: 'There are no processes to be launched according to your permissions or the established search criteria',
  allProcesses: 'All processes',
  emailNotValid: 'Email not valid',
  phoneNotValid: 'Telephone number not valid',
  phoneAtLeastNineChars: 'The telephone number must have at least 9 digits',
  doesntExist: 'Does not exist',
  dontExist: 'Do not exist',
  requiredField: 'Required field',
  errorField: 'Error Field',
  numberField: 'Numeric field',
  fiveCharsField: 'Field must have 5 characters',
  CPvalidField: 'CP does not correspond to province',
  delete: 'Delete',
  save: 'Save',
  file: 'File',
  loading: 'Loading',
  countrySelector: 'Select a country',
  provinceSelector: 'Select a province',
  andNumberMore: 'and {count} more',
  selectFile: 'Select a file',
  selectFileHtml: 'Select an HTML file',
  selectFiles: 'Select files',
  allTransactions: 'All transactions',
  transactionsPerPage: 'Transactions per page',
  transactionsSend: 'Sent',
  transactionslower: 'transactions',
  transactionWithoutId: 'Assignment of ID pending',
  withoutCertificated: 'No certificate available',
  filter: 'Filter',
  transactionsProcessFilter: 'Filter by process',
  processDataPreviewText: 'Below is the summary of the new transaction, including the number of dispatches required and the associated cost center',
  processDataPreviewTextWithoutCC: 'Below is the summary of the new transaction, which indicates the required number of dispatches',
  transactionsLinkedGroupsFilter: 'Filter by group',
  transactionsDateFilter: 'Filter by date',
  numberOfTransactions: 'Number of dispatches',
  associatedCECO: 'Associated cost center',
  transactionsCategoryFilter: 'Filter by category',
  transactionsStatusFilter: 'Filter by status',
  transactionState: 'Transaction status',
  transactionSummary: 'Transaction summary',
  transactionGeneralData: 'Transaction information',
  transactionData: 'Transaction data',
  files: 'Files',
  transactionContact: 'Contact data',
  transaction: 'Transaction',
  transactionEvents: 'Certifiable transaction events',
  transactionWithoutEvents: 'There are no events recorded for the transaction',
  transactionProgress: 'Transaction progress',
  transactionSubtitle: 'Transaction status information',
  transactionDetails: 'Transaction details',
  transactionEventInfo: 'Event information',
  eventMeaningPrepend: 'What does the event mean? ',
  eventMeaningAppend: '?',
  eventMeaning: 'What does this event mean?',
  transactionLaunchedDate: 'Creation date',
  transactionLastEvent: 'Last event received',
  transactionLastEventDate: 'Last event date',
  transactionContactName: 'Name and surname',
  transactionContactMobilephone: 'Cell phone',
  transactionContactEmail: 'E-mail',
  transactionContactID: 'ID',
  advancedFilters: 'Advanced filters',
  group: 'Group',
  date: 'Date',
  hideFilters: 'Hide filters',
  processName: 'Process name',
  usernameTransactionDetail: 'Sender user',
  recipientsNumber: 'Recipient number',
  invalidTransaction: 'Invalid data',
  dataTableShowRecipients: 'Viewing dispatch <span class="font-weight-medium">:page:</span> of <span class="font-weight-medium"">{total}</span>',
  transactionRedirectToProcesses: 'Go to processes',
  transactionRedirectToTransactions: 'Go to transactions',
  transactionRedirectToNewSend: 'New dispatch',
  transactionProcessed: 'Transaction processed successfully',
  transactionsProcessed: 'Transactions processed successfully',
  transactionError: 'Transaction processed with invalid data',
  transactionsError: 'Transactions processed with invalid data',
  transactionProcessing: 'Processing transaction...',
  transactionLaunchedMessage: 'The transaction has been processed successfully, as the dispatches are processed, they will be visible in the section',
  transactionsLaunchedMessage: 'The transactions have been processed successfully, as the dispatches are processed, they will be visible in the section',
  transactionDownloadFile: 'See Certificate',
  transactionProcessingMessage: 'The system is processing the transaction, please wait a few moments',
  transactionsProcessingMessage: 'The system is processing the transactions, please wait a few moments',
  transactionsErrorMessage: 'During the processing of the transactions of the different recipients, invalid data has been detected in at least one transaction. Below is the full list of dispatch transactions',
  transactionErrorMessage: 'During the processing of the transaction, invalid data has been detected',
  recoverProcessQuestion: 'If you start a new process, you will lose the data entered so far',
  recoverProcessQuestionAppend: 'Do you want to start a new process?',
  statisticsTransactionsMultipleTitle: 'Transactions made between days <span class="font-weight-bold">{start_day} of {start_month}</span> and <span class="font-weight-bold">{end_day} of {end_month}</span>',
  statisticsTransactionsOneTitle: 'Transactions made on day {start_day} of {start_month}',
  statisticsTotalTransactionsTitle: 'Totals by transaction type',
  statisticsNoResults: 'No results match the indicated filters',
  statisticsProcessTitle: 'Process:',
  total: 'Total',
  totalTransactions: 'Total transactions',
  totalTransactionsByProcesses: 'Transaction breakdown by process',
  accountDescription: 'Account settings',
  accountTitle: 'Account',
  contactsDescription: 'List and management of contacts',
  contactsTitle: 'Contacts',
  transactionsDescription: 'Transaction list and details',
  transactionsTitle: 'Transactions',
  statisticsDescription: 'Graphic analysis of your transactions',
  statisticsTitle: 'Statistics',
  supportDescription: 'This section provides help through technical support or the user manual',
  supportManualDescription: 'In this section you can view the documentation related to the handling of the platform',
  supportManualTitle: 'View manual and guide',
  supportManualTitleOnlyManual: 'View manual',
  supportManualTitleOnlyGuide: 'View guide',
  administrationDescription: 'Platform account management',
  administrationTitle: 'Administration',
  userActive: 'Active user',
  userInactive: 'Inactive user',
  userStateTitle: 'User status',
  groupsTitle: 'Permission groups',
  groupsDescription: 'Account group management. Configuration of parameters and permissions for each group.',
  groupsAssignation: 'Assigned group',
  groupInheritFrom: 'Inherit permissions from',
  groupDataTitle: 'Group information',
  groupUsersPermissionsTitle: 'Permissions, users and processes',
  groupPermissionsTabsTitle: 'Permissions',
  groupProcessesAndSubaccounts: 'Sub-accounts and processes',
  groupLinked: 'Linked groups',
  groupPermissionsOwnerText: 'This group is the account owner, it has all permissions assigned',
  groupPermissionsOwnerSubaccountProcessesText: 'This group is the account owner, it has all sub-accounts and processes assigned',
  groupAssociatedProcesses: 'Associated processes',
  groupAssociatedSubaccounts: 'Associated sub-accounts',
  newGroup: 'New group',
  groupName: 'Group name',
  editGroup: 'Editing group',
  groupDescription: 'Description',
  groupMinDaysToChange: 'Minimum number of days of password validity',
  groupTabUsersNameTitle: 'Name and surname',
  groupTabPermissionSectionTitle: 'Sections',
  groupTabPermissionGroupTitle: 'Groups',
  groupTabPermissionUsersTitle: 'Users',
  usersSearch: 'Search users',
  subaccountWithoutProcesses: 'Sub-account without associated processes',
  selectCostCenter: 'Select a cost center',
  adminGroupInfoDescription: 'In this section you can edit the platform’s description, session policies and passwords.',
  adminGroupInfoUsers: 'In this section you can manage the users associated with this group.',
  adminGroupInfoPerms: 'In this section you can manage the permissions related to the functions that the user can perform on the platform.',
  adminGroupInfoProcesses: 'In this section you can manage user access to processes and sub-accounts. This access will grant permission to launch, view transactions and statistics of the assigned processes.',
  adminGroupInfoLinked: 'In this section you can manage the monitoring of other groups. Enabled groups will share the information of transactions launched, and statistics with the current group.',
  passwordsQuantity: 'Number of passwords stored',
  passwordExpiration: 'Days before the password expires',
  loginRetriesQuantity: 'Number of login retries',
  loginRetriesTime: 'Minutes left to retry login',
  sessionExpiration: 'Minutes before the session expires',
  processAlertExpirationQuestion: 'Do you wish to continue?',
  processAlertExpirationPhrase: 'The session will expire shortly, for security reasons',
  processAlertExpiration: 'Expiration alert',
  continueSession: 'Continue',
  usersTitle: 'Users',
  activate: 'Activated',
  deactivate: 'Disabled',
  usersPerPage: 'Users per page',
  usersDescription: 'Account user management',
  userInfoTitle: 'User information',
  userPermissionTitle: 'Permission type assigned',
  newUser: 'New user',
  editUser: 'Editing user',
  groupTitle: 'Group name',
  administrator: 'Administrator',
  groupLinkedHeader: 'Linked',
  firmaElectronica: 'Electronic signature',
  comunicacionCertificada: 'Certified communication',
  publicacionWeb: 'Web publication',
  groupVisibility: 'Group visibility',
  passwordPolicy: 'Password policy',
  frontalPolicy: 'Session policy',
  groupData: 'Group data',
  groupsPerPage: 'Groups per page',
  groupsFound: 'Found',
  cancel: 'Cancel',
  description: 'Description',
  subaccountChanged: 'Sub-account changed',
  processContinueTitle: 'You have a process pending dispatch',
  processContinueSubtitle: 'Do you wish to recover the process or start a new one?',
  newProcess: 'New process',
  recover: 'Recover',
  newShipping: 'New dispatch',
  toContinueFillFields: 'To continue, fill in the fields correctly',
  passwordRequired: 'Password required',
  userRequired: 'User required',
  fieldAlert: 'The field %%fields% will be filled automatically from the recipients',
  fieldAlertPlural: 'The fields %%fields% will be filled automatically from the recipients',
  fieldNumberPhone: 'telephone',
  fieldCountryCode: 'country code',
  fieldEmail: 'e-mail',
  fieldName: 'name',
  fieldSurname: 'surname',
  and: 'and',
  certificate: 'Certificate',
  country: 'Country',
  continueEditing: 'Edit dispatch data',
  second: 'second',
  seconds: 'seconds',
  nextRefreshAvailableAt: 'Next update available in',
  fastSearch: 'Quick search',
  update: 'Update',
  process: 'Process',
  transactionId: 'Transaction ID',
  transactionClientId: 'Client reference',
  createdDate: 'Created',
  updatedAt: 'Updated',
  state: 'Status',
  noGroupAssigned: 'No group selected',
  contact_country_code: 'Country code',
  contact_email: 'E-mail',
  contact_id: 'Client reference',
  contact_name: 'Name',
  contact_phone: 'Telephone',
  contact_surname: 'Surname',
  contact_complete_name: 'Full name',
  certificateValidDownload: 'Certificate downloaded successfully',
  certificateFailDownload: 'The certificate could not be downloaded',
  graphicBarValidDownload: 'Chart downloaded successfully',
  graphicBarFailDownload: 'The chart could not be downloaded',
  queued: 'The transaction is pending processing.',
  new_communication: 'The transaction was generated successfully.',
  orchestrator_new_communications_communication_received: 'The transaction was processed successfully.',
  commstracker_generate_url_generated: 'The shortened link URL was generated.',
  commstracker_generate_url_click: 'The transaction link has been opened.',
  communication_send_sms_sent: 'The text message has been deposited with the provider\'s delivery service center. The provider, in turn, will manage the delivery to end recipient.',
  communication_send_sms_not_sent: 'The text message could not be deposited with the provider\'s delivery service center.',
  communication_send_sms_delivered: 'The text message has been delivered to the recipient.',
  communication_send_sms_not_delivered: 'The text message could not be delivered to the recipient.',
  generate_otp_code_otp_generated: 'A one-time-password (OTP) has been generated for use.',
  generate_certificate_generated: 'The transaction completion certificate has been generated, which includes the communication content and the evidence associated with the events that occurred in the process.',
  communication_send_email_delivered: 'The message has been delivered to the recipient\'s email service provider (ESP).',
  communication_send_email_complaint: 'The message recipient has marked the received email as spam.',
  communication_send_email_queued: 'The email message has been queued.',
  communication_send_email_deferred: 'The email message has been queued and will be delivered when possible.',
  communication_send_email_bounce: 'The email message has bounced.',
  communication_send_email_sent: 'The email message has been deposited with the provider\'s delivery service. The provider, in turn, will manage the delivery to end recipient.',
  communication_send_email_sender_not_allowed: 'The email message could not be sent as the sender is not authorized.',
  communication_send_email_open: 'The email has been opened by the recipient.',
  communication_send_email_click: 'The email recipient has clicked on a link.',
  communication_send_email_block: 'The recipient\'s email address is on the exclusion list, the message has not been sent.',
  communication_send_email_unsubscribe: 'The email recipient wishes to unsubscribe from the distribution list.',
  communication_send_email_spam: 'The email message has been identified as spam.',
  communication_send_email_hard_blacklisted: 'The recipient\'s email address is on the exclusion list, the message has not been sent.',
  communication_send_email_complaint_blacklisted: 'The recipient\'s email address is on the complaint list, the message has not been sent.',
  document_upload_s3_uploaded: 'The transaction completion certificate has been safely stored, including the communication content and the evidence associated with the events that occurred in the process.',
  communication_send_telegram_delivered: 'The text message has been delivered to the recipient.',
  communication_send_telegram_not_delivered: 'The text message could not be delivered.',
  web_framework_generated: 'The graphical web interface has been generated for user interaction.',
  web_framework_solicitud_otp: 'A one-time-password (OTP) has been requested.',
  web_framework_signable_document_loaded: 'A document pending signature has been uploaded to the web interface.',
  web_framework_otp_simple_signature_ok: 'The documentation has been signed using a One-Time-Password.',
  web_framework_commited: 'The process has been completed on the web interface.',
  web_framework_document_signed: 'The documentation has been signed.',
  web_framework_form_based_identification: 'The authentication step has been completed through the form.',
  web_framework_certificate_based_identification: 'Access with personal certificate has been carried out in a satisfactory manner',
  web_framework_document_based_identification: 'The form has been correctly identified.',
  web_framework_certificate_based_identification_failed: 'The certificate-based identification process has failed.',
  web_framework_document_based_identification_failed: 'The document-based identification process has failed.',
  web_framework_form_based_identification_failed: 'The form-based authentication process has failed.',
  web_framework_web_framework_expired: 'The transaction has expired.',
  web_framework_documentation_downloaded: 'The documentation has been downloaded by the recipient.',
  web_framework_electronic_notificacion_rejected: 'The electronic notification has been rejected by the recipient.',
  web_framework_gdpr_formulary_committed: 'The consent form has been completed by the recipient.',
  web_framework_sepa_formulary_committed: 'The SEPA form has been completed by the recipient.',
  web_framework_requested_otp_code: 'A one-time-password (OTP) has been requested.',
  web_framework_entered_wrong_otp_code: 'The One-Time-Password (OTP) entered by the recipient is incorrect.',
  web_framework_entered_timedout_otp_code: 'The One-Time-Password has expired.',
  web_framework_entered_correct_otp_code: 'The One-Time-Password (OTP) entered by the recipient is correct.',
  web_framework_electronic_notificacion_accepted: 'The electronic notification has been accepted by the recipient.',
  web_framework_signature_rejected: 'The electronic signature request has been rejected by the recipient.',
  web_framework_request_otp_code_too_early: 'The One-Time-Password (OTP) has been requested by the recipient before the stipulated time required to be able to regenerate it',
  web_framework_capture_data_commited_no_response: 'An attempt was made to submit the form without data in the responses.',
  web_framework_capture_data_commited: 'The data has been sent correctly',
  web_framework_document_previewed: 'The documentation has been previewed by the recipient.',
  web_framework_document_simply_signed: 'The documentation has been signed electronically.',
  document_upload_alastria_uploaded: 'The hash on the completion certificate has been uploaded to the CertySign node on the Alastria Blockchain Network.',
  publish_downloadable_file_generated: 'The documentation has been generated for publication on the web interface.',
  publish_downloadable_file_open: 'The documentation has been opened for publication on the web interface.',
  document_sign_signed: 'The certificate has been signed.',
  rpc_callback_published: 'The RPC callback has been published',
  loop_add_added: 'The transaction has been added to the retry loop.',
  loop_add_tick: 'An additional attempt has been made',
  loop_add_timeout: 'The retry limit has been reached',
  loop_cancel_canceled: 'The loop has been canceled',
  identification_create_identification_generated: 'The process by which the user will be identified in the transaction has been created.',
  identification_create_identification_ok: 'Correctly identified.',
  test_load_processed: 'The test load has been processed',
  instance_delete_deleted: 'The instance relating to the transaction has been deleted.',
  unzip_file_extracted: 'The file has been unzipped.',
  communicalia_consent_accepted: 'The communication recipient has accepted the consent.',
  communicalia_consent_declined: 'The communication recipient has rejected the consent.',
  communicalia_consent_no_data: 'The communication recipient has not answered.',
  robinson_list_accepted: 'The recipient is not on the Robinson list',
  robinson_list_declined: 'The recipient is registered on the Robinson list',
  robinson_list_no_data: 'The recipient is registered on the Robinson list',
  publish_embeddable_file_generated: 'The embedded file for the web interface has been generated.',
  document_upload_sftp_uploaded: 'The documentation has been loaded through SFTP.',
  file_delete_deleted: 'The file has been deleted.',
  disable_framework_web_framework_web_disabled: 'The web interface associated with the transaction has been disabled.',
  generate_onespan_transaction_transaction_create: 'The electronic signature transaction with OneSpan has been created',
  generate_onespan_transaction_transaction_create_error: 'An error occurred while creating the electronic signature transaction in OneSpan.',
  generate_onespan_transaction_transaction_complete: 'The electronic signature transaction with OneSpan has been completed',
  generate_onespan_transaction_transaction_expire: 'The electronic signature transaction with OneSpan has expired',
  generate_onespan_transaction_transaction_decline: 'The electronic signature transaction with OneSpan has been rejected',
  generate_onespan_transaction_email_bounce: 'The email message, relating to the OneSpan electronic signature transaction, has bounced.',
  generate_onespan_transaction_transaction_opt_out: 'The recipient has chosen not to sign the transaction.',
  generate_onespan_transaction_transaction_delete: 'The electronic signature transaction with OneSpan has been deleted',
  generate_onespan_transaction_transation_desactivate: 'The electronic signature transaction with OneSpan has been disabled',
  generate_onespan_transaction_kba_failure: 'The transaction has been blocked due to security reasons during access or process.',
  generate_onespan_transaction_transaction_sent: 'The electronic signature transaction with OneSpan has been sent',
  generate_onespan_transaction_transaction_sent_error: 'The dispatch of the electronic signature transaction with OneSpan has failed',
  generate_onespan_transaction_package_trash: 'The transaction has been moved to the OneSpan bin',
  get_onespan_files_files_onespan_generated: 'The files required for OneSpan have been generated',
  communication_send_postal_generated: 'The postal communication dispatch has been generated.',
  communication_send_postal_sent_to_factory: 'The dispatch has been uploaded to the postal communication platform.',
  communication_send_postal_printed: 'The documentation associated with the postal dispatch has been printed.',
  communication_send_postal_pre_admission_requested: 'The communication dispatch is being pre-registered electronically on the postal operator\'s website.',
  communication_send_postal_pre_admitted: 'The communication dispatch has been registered electronically by the postal operator, prior to its deposit.',
  communication_send_postal_sent_to_logistic_operator: 'The communication dispatch is being deposited with the postal operator.',
  communication_send_postal_on_the_way_to_logistic_operator: 'The dispatch is on its way to the postal office closest to the communication recipient.',
  communication_send_postal_received_by_logistic_operator: 'The dispatch is at the postal office closest to the communication recipient.',
  communication_send_postal_not_received_by_logistic_operator: 'The communication dispatch has not arrived at the postal office.',
  communication_send_postal_on_delivery: 'The communication dispatch is on its way.',
  communication_send_postal_delivered_to_recipient: 'The postal communication dispatch has been delivered to the recipient.',
  communication_send_postal_returned_to_postal_office: 'The dispatch is at the postal office and the communication recipient has a notification in their mailbox.',
  communication_send_postal_delivered_into_postal_office: 'The postal communication dispatch has been delivered to the recipient at the postal office.',
  communication_send_postal_generic_delivered: 'The postal communication dispatch has been delivered to the recipient.',
  communication_send_postal_returned: 'It was impossible to deliver the dispatch and the postal communication has been returned to origin.',
  communication_send_postal_canceled: 'The postal communication dispatch has been canceled.',
  communication_send_postal_lost: 'The postal communication dispatch has been lost.',
  communication_send_postal_expired: 'The postal communication dispatch has been returned to origin because it was not collected by the recipient in the 15 business days following the notification deposited in the mailbox.',
  communication_send_postal_rejected: 'The postal communication dispatch has been rejected by the recipient.',
  communication_send_postal_stolen: 'The postal communication dispatch has been robbed or stolen.',
  communication_send_postal_outside_sicer: 'The communication dispatch is not registered with the postal operator.',
  communication_send_postal_bad_postal_address: 'The dispatch postal address is not correct.',
  communication_send_postal_passed_away: 'The recipient of the postal communication dispatch has passed away.',
  communication_send_postal_other: 'Other.',
  communication_send_postal_no_information: 'There is no information on the communication dispatch with the postal operator.',
  communication_send_postal_received_pee: 'Electronic Proof of Delivery (ePOD) for dispatch has been received.',
  communication_send_postal_certificate_returned: 'The return certificate has been generated with Proof of Undeliverability from the postal operator.',
  communication_send_postal_unknown: 'The recipient of the postal communication dispatch is unknown at the indicated postal address.',
  zip_files_path_zipped: 'One or more documents have been zipped',
  intromission_api_retries_muted: 'Transaction retries have been disabled',
  intromission_api_retries_unmuted: 'Transaction retries have been activated',
  intromission_api_instance_canceled: 'The transaction has been canceled',
  antivirus_filtered: 'The antivirus has filtered the transaction due to potentially dangerous resources being uploaded.',
//API messages
  user_operation_error_creating_user: 'An error occurred while creating the user',
  user_operation_user_already_exists: 'The user already exists',
  user_operation_user_created: 'User created successfully',
  user_operation_user_updated: 'User updated successfully',
  user_operation_no_exists: 'User does not exist',
  user_operation_password_changed: 'Password has been updated',
  user_operation_pass_incorrect: 'User and password combination is incorrect',
  user_operation_password_expired: 'Password has expired',
  user_operation_logout: 'Session closed correctly',
  user_operation_blocked_user: 'Blocked user',
  user_operation_wrong_subdomain: 'The username or password is not correct',
  user_operation_max_pass_tries: 'User blocked, number of tries exceeded',
  user_operation_deleted: 'User deleted correctly',
  user_operation_error_deleted: 'Error deleting user',
  user_has_been_disabled: 'Inactive user',
  user_operation_error_user_not_found: 'If the indicated email exists, you will receive an email with instructions to change your password shortly',
  user_operation_remember_password_email: 'If the indicated email exists, you will receive an email with instructions to change your password shortly',
  email_sent_to_support: 'Request sent correctly',
  user_operation_error_change_password_min_days: 'It is not possible to change the password because the minimum number of days required has not passed',
  user_operation_error_passwords_mismatch: 'Passwords do not match',
  user_operation_error_password_has_been_used_before: 'The password has been used before, please select a new one',
  user_or_group_no_exists: 'User or group does not exist',
  user_error_already_active: 'The user is already active',
  activation_email_resend: 'The activation email has been forwarded',
  group_operation_error_creating_group: 'An error occurred while creating the group',
  group_operation_group_already_exists: 'The group already exists',
  group_operation_group_created: 'Group created successfully',
  group_operation_group_updated: 'Group :group_name: updated successfully',
  //Terms and conditions
  termsConditionsDPOTitle: 'Data Protection Officer',
  termsConditionsTitular: 'Title',
  termsConditionsNIF: 'N.I.F.',
  termsConditionsDomicilioSocial: 'Registered Office',
  termsConditionsDatosRegistrales: 'Registration data',
  termsConditionsContacto: 'Contact',
  termsConditionsDPOEmailTitle: 'Customer Comms Email',
  termsConditionsDPOEmail: 'E-mail address of the Data Protection Officer.',
  termsConditions1: 'CUSTOMER COMMUNICATIONS TECKNALIA, SL, (hereinafter CCOMMS) in its capacity as owner and controller of this APP, in compliance with the provisions of article 10 of Law 34/2002, of 11 July, on Services of the Society of Information and Electronic Commerce, offers the following general information about this APP to users:',
  termsConditionsObjetoTitulo: 'PURPOSE',
  termsConditionsObjetoParrafo1: 'These General Conditions of Use (hereinafter, the “GCU”) regulate the use of the application CertySign (hereinafter, the APP) that CCOMMS makes available to users who access it in order to proceed with the request made.',
  termsConditionsObjetoParrafo2: 'Therefore, these GCU will apply both to the promotional activity and provision of information carried out through the APP and to the provision of services offered therein, in such a manner that they will be applicable at all times both for simple browsing through the APP, and also for contracting services within the APP, although the latter will be subject, additionally, to both the GCU described below, and the applicable General Contracting Conditions, as well as the Particular Conditions that may exist, if any.',
  termsConditionsUsuariosTitulo: 'USERS',
  termsConditionsUsuariosParrafo1: 'Anyone who accesses and/or uses the APP is afforded the status of user, and from that moment on they accept fully and without any reservations these GCU as well as the Particular Conditions that, where appropriate, complement, modify or replace the GCU in relation to certain services and content of the APP, They undertake to respect the restrictions on use of said site established by CCOMMS at all times.',
  termsConditionsAccesoAPPTitulo: 'ACCESS TO THE APP',
  termsConditionsAccesoAPPParrafo1: 'Access and use of the APP is restricted so prior registration by the user will be necessary to access it.',
  termsConditionsUsoAPPTitulo: 'USE OF APP',
  termsConditionsUsoAPPParrafo1: 'The user agrees to use the APP, its services and content without violating current legislation, good faith, generally accepted uses or public order. Furthermore, the use of the APP for illegal purposes of that may be harmful to CCOMMS or any third party or that in any way may cause damages or prevent the normal functioning of the APP is prohibited.',
  termsConditionsResposabilidadTitulo: 'USER LIABILITY FOR DAMAGES',
  termsConditionsResposabilidadParrafo1: 'The user is solely and exclusively liable for the use they make of the APP. Said liability will extend to the use by the user or any third party of any passwords or similar assigned to access the APP or any of its services.',
  termsConditionsResposabilidadParrafo2: 'The foregoing notwithstanding, CCOMMS reserves the right to deny or disable at any time and without the need for prior notice, access to the APP or to the restricted access, for users who do not comply with these GCU, the rules established by CCOMMS or their workers or who interfere with the proper operation, image, credibility and/or prestige of CCOMMS or its workers.',
  termsConditionsResposabilidadParrafo3: 'In this regard, CCOMMS reserves the right to cancel or restrict access to the APP to certain Users, if it detects any behaviour that in the opinion of CCOMMS is contrary to these Conditions of Use, the Law, the rules established by CCOMMS or its workers or that may interfere with the proper operation, image, credibility and/or prestige of CCOMMS or its workers. Furthermore, CCOMMS may hold Users liable for damages arising from the improper use of the APP.',
  termsConditionsResposabilidadParrafo4: 'CCOMMS does not guarantee the legality, reliability and usefulness, nor the veracity or accuracy of the content that users transmit while using the APP.',
  termsConditionsResposabilidadParrafo5: 'CCOMMS does not control the use that users make of the APP nor does it guarantee that they do so in accordance with these General Conditions.',
  termsConditionsResposabilidadParrafo6: 'CCOMMS does not control or guarantee the absence of computer viruses in the services provided by third parties through the APP that may cause alterations in your computer system (software and hardware) or in the electronic documents and files stored in your computer system.',
  termsConditionsPropiedadIntelectualTitulo: 'INTELLECTUAL PROPERTY',
  termsConditionsPropiedadIntelectualParrafo1: 'Any use or modification of the Material or the Content for any purpose other than that authorized in the General Conditions will be considered a breach of international "copyright\', laws, which protect copyright.',
  termsConditionsPropiedadIntelectualParrafo2: 'Regarding the content, it is prohibited:',
  termsConditionsPropiedadIntelectualListaELemento1: 'Its reproduction, copy, distribution, modification, or manipulation in any way and for any purpose, except when there is the prior written authorization of its legitimate owners or it is legally permitted.',
  termsConditionsPropiedadIntelectualListaELemento2: 'Any violation of the rights of CCOMMS or the legitimate owners thereof.',
  termsConditionsPropiedadIntelectualListaELemento3: 'Its use for all types of commercial or advertising purposes other than those strictly permitted.',
  termsConditionsPropiedadIntelectualParrafo3: 'CCOMMS guarantees that the content, including that which is intellectual property, is not illegal nor infringes the current regulations. Therefore, the APP will not include content that is discriminatory, pornographic, xenophobic or that in any way encourages violence.',
  termsConditionsPropiedadIntelectualParrafo4: 'In addition, CCOMMS will adopt the legal measures it deems appropriate to prevent any type of conduct that is immoral or contravenes the Law.',
  termsConditionsModificacionUnilateralTitulo: 'UNILATERAL MODIFICATION',
  termsConditionsModificacionUnilateralParrafo1: 'CCOMMS may unilaterally modify, without prior notice, whenever it deems appropriate, the APP’s structure and design, as well as modify or delete services, content and conditions of access and/or use of the APP.',
  termsConditionsExclusionGarantiasTitulo: 'EXCLUSION OF GUARANTEES AND LIABILITY',
  termsConditionsExclusionGarantiasParrafo1: 'CCOMMS does not grant any guarantee nor is it responsible, in any case, for damages of any kind that could be caused by:',
  termsConditionsExclusionGarantiasListaELemento1: 'Failure to comply with the Law, morality, generally accepted standards or public order or the negligent or improper use of the products and/or services offered, for illegal purposes or effects or contrary to what is established in these GCU or in the applicable conditions that may be applicable.',
  termsConditionsExclusionGarantiasListaELemento2: 'For the purchase or contracting of services, goods or products that may be acquired or contracted from third parties through access via the COMMS APP, especially in those cases in which the purchase or contracting process is carried out directly in the third-party app and even if distinguishing marks or a “frame” appear with the app’s graphic elements (Branding).',
  termsConditionsExclusionGarantiasListaELemento3: 'The lack of availability, maintenance and effective operation of the APP and/or its services or content.',
  termsConditionsExclusionGarantiasListaELemento4: 'The lack of usefulness, suitability or validity of the APP and/or its services or content in satisfying the needs, activities or specific results or expectations of users.',
  termsConditionsExclusionGarantiasListaELemento5: 'The existence of viruses or malware in the content.',
  termsConditionsExclusionGarantiasListaELemento6: 'The reception, obtaining, storage, dissemination or transmission by the users of the content.',
  termsConditionsExclusionGarantiasListaELemento7: 'The illegality or lack of veracity, accuracy, reliability, relevance, up-to-dateness and completeness of the content and information transmitted or made available to users, including information and services provided by third parties or by users through the APP.',
  termsConditionsExclusionGarantiasListaELemento8: 'The breach by third parties of their obligations or commitments in relation to the services provided to users through the APP.',
  termsConditionsExclusionGarantiasListaELemento9: 'For the content, files, information, advertising, opinions, concepts and images that do not depend on the APP, nor are managed by CCOMMS. Nor is COMMS liable for improper use of the APP content, this being the sole responsibility of the person who accesses or uses said content.',
  termsConditionsExclusionGarantiasListaELemento10: 'For improper use of user access codes for access to the APP service content requiring such codes nor for the consequences of any nature derived from improper use by users, for lost or forgotten codes, and improper use thereof by unauthorized third parties.',
  termsConditionsExclusionGarantiasListaELemento11: 'The incapacity of any user or the impersonation of a third party by the user.',
  termsConditionsExclusionGarantiasListaELemento12: 'For the content facilitated by users.',
  termsConditionsProteccionDatosTitulo: 'DATA PROTECTION',
  termsConditionsProteccionDatosParrafo1: 'Detailed information on Data Protection can be viewed on our page on',
  termsConditionsLeyJurisdiccionTitulo: 'APPLICABLE LAW AND JURISDICTION',
  termsConditionsLeyJurisdiccionParrafo1: 'All the General and Particular Conditions included in this APP are governed by Spanish law. Any dispute, controversy or situation derived from the General and Particular Conditions will be submitted to the Courts of the City of Madrid, with the parties expressly waiving their jurisdiction.',
  termsConditionsProteccionDatosTitle: 'View information on Data Protection',
  termsConditionsProteccionDatos: 'Data Protection',
  //Privacy Policies
  privacyPoliciesConditionsTitle: 'Mailteck & Customer Communications Tecknalia data protection',
  privacyPoliciesConditionsParrafo1: 'In accordance with the provisions of both Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (GDPR) and also Organic Law 3/2018 of 5 December on the Protection of Personal Data and the Guarantee of Digital Rights (LOPD-GDD) and other data protection regulations in force, users of the APP who have voluntarily expressed their consent for the processing of personal data in a free, clear and unequivocal manner by ticking the box "Data Protection Policy", are hereby informed that their data will be processed in accordance with the company they are registered with, either by the company MAILTECK, S.A. or by the company CUSTOMER COMMUNICATIONS TECKNALIA, S.L., which will be jointly referred to as GRUPO MAILCOMMS.',
  privacyPoliciesConditionsResponsable1Title: 'Data controller 1',
  privacyPoliciesConditionsResponsable2Title: 'Data controller 2',
  privacyPoliciesConditionsFinalidadTitle: 'Purpose',
  privacyPoliciesConditionsLegitimacionTitle: 'Legitimization',
  privacyPoliciesConditionsDestinatariosTitle: 'Recipients',
  privacyPoliciesConditionsDerechosTitle: 'Rights',
  privacyPoliciesConditionsInformacionAdicionalTitle: 'Additional information',
  privacyPoliciesConditionsPoliticaInformacion1Title: 'Information policy 1',
  privacyPoliciesConditionsPoliticaInformacion2Title: 'Information policy 2',
  privacyPoliciesConditionsIdentidadTitle: 'Identity',
  privacyPoliciesConditionsNIFTitle: 'TAX ID NUMBER',
  privacyPoliciesConditionsDireccionTitle: 'Address',
  privacyPoliciesConditionsTelefonoTitle: 'Telephone',
  privacyPoliciesConditionsCorreoTitle: 'Mail',
  privacyPoliciesConditionsDPDTitle: 'Contact with the DPO',
  privacyPoliciesConditionsDPDCustomerCommsTitle: 'Customer Comms Data Protection Officer',
  privacyPoliciesConditionsDPDMailteckTitle: 'Mailteck Data Protection Officer',
  privacyPoliciesConditionsFinalidadIntro: 'Managing and processing the contracted services',
  privacyPoliciesConditionsFinalidadParrafo1: 'GRUPO MAILCOMMS will process the data provided under the contract signed by you or by your company in order to manage and process the services agreed therein, as well as to support the Certysign application.',
  privacyPoliciesConditionsFinalidadParrafo2: 'GRUPO MAILCOMMS will only process the submitted data for the purposes described above and will not subsequently process this data in a way that is incompatible with those purposes. The submitted personal data will be stored for as long as the contractual relationship is in force.',
  privacyPoliciesConditionsLegitimacionIntro: 'Performance of the contract',
  privacyPoliciesConditionsLegitimacionParrafo1: 'The data has been provided by you or by your company through the established channels to be able to give you access to the Certysign application as a result of the service provision contract signed.',
  privacyPoliciesConditionsDestinatariosIntro: 'It will not be transferred to third parties',
  privacyPoliciesConditionsDestinatariosParrafo1: 'By legal requirement when a rule so requires.',
  privacyPoliciesConditionsDerechosIntro: 'You may exercise your rights in the legally established manner and terms.',
  privacyPoliciesConditionsDerechosElem1ListaTitulo: 'General confirmation:',
  privacyPoliciesConditionsDerechosElem1ListaDescripcion: 'Everyone has the right to obtain confirmation on whether or not GRUPO MAILCOMMS is processing personal data that concerns them.',
  privacyPoliciesConditionsDerechosElem2ListaTitulo: 'ARCO Rights:',
  privacyPoliciesConditionsDerechosElem2ListaDescripcion: 'Data subjects have the right to access their personal data, as well as to request the rectification of inaccurate data or, where appropriate, request their erasure when, among other reasons, the data are no longer necessary for the purposes for which they were collected.',
  privacyPoliciesConditionsDerechosElem3ListaTitulo: 'Restriction of processing:',
  privacyPoliciesConditionsDerechosElem3ListaDescripcion: 'In certain circumstances, you can request the restriction of processing of data, in which case they will only be stored for the exercise or defence of legal claims.',
  privacyPoliciesConditionsDerechosElem4ListaTitulo: 'Objection to processing:',
  privacyPoliciesConditionsDerechosElem4ListaDescripcion: 'In certain circumstances and for reasons related to their particular situation, data subjects may object to the processing of their data. In this case, GRUPO MAILCOMMS will no longer process the data, except for compelling legitimate grounds or for the exercise or defence of legal claims.',
  privacyPoliciesConditionsDerechosElem5ListaTitulo: 'Data portability:',
  privacyPoliciesConditionsDerechosElem5ListaDescripcion: 'In certain circumstances and for reasons related to their particular situation, data subjects may request the receipt of the personal data provided in the contact form in a structured, commonly used and machine-readable format as well as have said data transmitted to another data controller without hindrance.',
  privacyPoliciesConditionsDerechosElem6ListaTitulo: 'Claim before a public body:',
  privacyPoliciesConditionsDerechosElem6ListaDescripcion: 'If you consider that your right to personal data protection has been breached, you may file a claim with the Spanish Data Protection Agency',
  privacyPoliciesConditionsDerechosAEPDTitle: 'AEPD',
  privacyPoliciesConditionsDerechosElem7ListaTitulo: 'Channel to exercise your rights:',
  privacyPoliciesConditionsDerechosElem7ListaDescripcion: 'Data subjects may exercise their rights recognized in the regulations against and before each of the data controllers, via the addresses enabled as DPO in the section "Data Controller".',
  privacyPoliciesConditionsInformacionAdicionalIntro: 'Further information on Data Protection.',
  privacyPoliciesConditionsInformacionAdicionalLista1: 'The personal data has been obtained from the channels that the companies of the GRUPO MAILCOMMS have established to be able to manage the services contracted by you or by your company.',
  privacyPoliciesConditionsInformacionAdicionalLista2: 'The categories of data to be processed will be the data provided for the performance of each contracted service.',
  privacyPoliciesConditionsInformacionAdicionalLista3: 'GRUPO MAILCOMMS does not process special categories of data.',
  privacyPoliciesConditionsPoliticaInformacionIntro: 'MailTecK S.A. Information Security and Privacy Policy based on the ISO 27001 standard -> Read more.',
  privacyPoliciesConditionsPoliticaInformacio2nIntro: 'Customer Communication Tecknalia S.L. Information Security and Privacy Policy based on the ISO 27001 standard -> Read more.',
  privacyPoliciesConditionsPoliticaInformacionTituloH: 'ANNEXE I: INFORMATION SECURITY AND PRIVACY MANAGEMENT SYSTEM POLICY.',
  privacyPoliciesConditionsPoliticaInformacionP1: 'This Information Security and Privacy Policy is an example of the commitment of the company’s Management in providing the organization with resources, responsibilities and procedures that help guarantee compliance with current regulations as well as the integrity, confidentiality and availability of information and systems, which is crucial for the security, privacy and continuity of our organization as well as that of our customers.',
  privacyPoliciesConditionsPoliticaInformacionP2: 'Grupo Mailteck understands that the security and privacy of our customer data is highly valuable, and has therefore established an Information Security and Privacy Management System in accordance with the requirements of the ISO/IEC 27001:2013 and ISO/IEC 27701:2019 standards to guarantee the required security levels, the continuity of the services provided and the compliance of our personal data processing with the regulations, thus minimizing the risks derived from this management.',
  privacyPoliciesConditionsPoliticaInformacionCCTP2: 'Custommer Comms understands that the security and privacy of our customer data is highly valuable, and has therefore established an Information Security and Privacy Management System in accordance with the requirements of the ISO/IEC 27001:2013 and ISO/IEC 27701:2019 standards to guarantee the required security levels, the continuity of the services provided and the compliance of our personal data processing with the regulations, thus minimizing the risks derived from this management.',
  privacyPoliciesConditionsPoliticaInformacionP3: 'The goal of the Security and Privacy Policy is to establish the framework required to protect information resources against threats, whether internal or external, deliberate or accidental, in order to guarantee the confidentiality, integrity and availability of the information.',
  privacyPoliciesConditionsPoliticaInformacionP4: 'The effectiveness and application of the Information Security and Privacy Management System is the direct responsibility of the Information Security and Privacy Committee, who is responsible for the approval, distribution and compliance of this Security and Privacy Policy. An Information Security and Privacy Management System Supervisor has been appointed in its name and representation, who has sufficient authority to play an active role in the Information Security and Privacy Management System, supervising its implementation, development and maintenance.',
  privacyPoliciesConditionsPoliticaInformacionP5: 'Additionally, and to comply with current data protection regulations, a Data Protection Officer (DPO) has been appointed in order to guarantee the security and privacy of personal data, especially taking into account the activity of the company.',
  privacyPoliciesConditionsPoliticaInformacionP6: 'The Information Security and Privacy Committee will develop and approve the risk analysis methodology used in the Information Security and Privacy Management System.',
  privacyPoliciesConditionsPoliticaInformacionP7: 'Any person whose activity may, directly or indirectly, be affected by the requirements of the Information and Privacy Security Management System, is obliged to strictly comply with the Security and Privacy Policy.',
  privacyPoliciesConditionsPoliticaInformacionP8: 'Grupo Mailteck will implement all the necessary measures to comply with applicable regulations in general security and privacy and IT security, relating to IT policy, the safety of buildings and facilities and the behaviour of employees and third parties associated with Grupo Mailteck in the use of IT systems and in the processing of personal data. The measures required to ensure information security and privacy through the implementation of rules, procedures and controls should guarantee the confidentiality, integrity and availability of the information, which is essential to:',
  privacyPoliciesConditionsPoliticaInformacionCCTP8: 'Custommer Comms will implement all the necessary measures to comply with applicable regulations in general security and privacy and IT security, relating to IT policy, the safety of buildings and facilities and the behavior of employees and third parties associated with Custommer Comms, in the use of IT systems and personal data processing. The measures required to ensure information security and privacy through the implementation of rules, procedures and controls should guarantee the confidentiality, integrity and availability of the information, which is essential to:',
  privacyPoliciesConditionsPoliticaInformacionLista1: 'Comply with current legislation and regulations on privacy and information systems and with all contractual requirements regarding data protection as well as those that it deems necessary to ensure the continuous improvement of the organization.',
  privacyPoliciesConditionsPoliticaInformacionLista2: 'Ensure the confidentiality of the data managed by Grupo Mailteck.',
  privacyPoliciesConditionsPoliticaInformacionCCTLista2: 'Ensure the confidentiality of the data managed by Customer Comms.',
  privacyPoliciesConditionsPoliticaInformacionLista3: 'Ensure the availability of the information systems both in terms of services offered to clients and internal management.',
  privacyPoliciesConditionsPoliticaInformacionLista4: 'Ensure the ability to respond to emergency situations, restoring the operation of critical services in the shortest possible time.',
  privacyPoliciesConditionsPoliticaInformacionLista5: 'Avoid undue alterations in the information.',
  privacyPoliciesConditionsPoliticaInformacionLista6: 'Carry out awareness and training on information security and privacy.',
  privacyPoliciesConditionsPoliticaInformacionLista7: 'Promote and take part in the ongoing improvement of the information security and privacy management system policy.',
  cookiesPoliciesIntroText: 'This website uses cookies to improve your experience while browsing the website. You can change these settings at any time. Cookies classified as necessary are stored in your browser and are essential for the basic functionality of the website. Third-party cookies will be stored in your browser only with your consent and they will help us analyse and understand how this website is used. You have the option of not receiving these cookies, though the voluntary exclusion of any of these may mean that some functions are no longer available. For information on deleting cookies please consult your browser’s help function.',
  cookiesPoliciesNecessaryCookies: 'Essential cookies',
  cookiesPoliciesNecessaryCookiesText: 'These types of cookies are absolutely essential for the website to function properly. This category only cookies that guarantee the basic functionality and security features of the website and do not collect or store any personal information about website users.',
  cookiesPoliciesUnnecessaryCookies: 'Non-essential cookies',
  cookiesPoliciesUnnecessaryCookiesText: 'Cookies that may not be particularly necessary for the website to function and are specifically used to collect personal data from the user through analysis of advertisements and other embedded content are called non-essential cookies. Consent must be obtained from the user before executing these cookies on your website.',
  cookiesPoliciesGoogleAnalyticsCookies: 'Google Analytics cookies',
  cookiesPoliciesGoogleAnalyticsCookiesText: 'For more information on this type of cookie, you can consult the document on "Use of Google Analytics cookies on websites',
  cookiesPoliciesGoogleAnalyticsCheckboxText: 'Activate cookies',
  cookiesNotificationBarText: 'We use both our own and third-party cookies to measure visits, guarantee the functionality of the website and analyse browsing habits. Below you can accept or configure/reject the cookies. You can find more information on the ownership of the website that controls the processing of cookies and your contact information in our',
  cookiesNotificationBarConfig: 'Configure',
  cookiesPoliciesModalIntro: 'Introduction',
  cookiesPoliciesModalIntroP1: 'This website uses cookies and/or similar technologies that store and retrieve information during your browsing session in order to improve and optimise your user experience.',
  cookiesPoliciesModalIntroP2: 'In general, these technologies can serve many different purposes, such as recognising you as a user, obtaining information about your browsing habits, or customising the way content is displayed.',
  cookiesPoliciesModalIntroP3: 'In accordance with Royal Decree Act 13/2012 and the terms of Article 22.2 of the Information Society and E-Commerce Services Act, this Policy hereby informs you of the cookies collected by CUSTOMER COMMUNICATIONS TECKNALIA, S.L. through different media or communication channels, whether proprietary or third-party, exempt or otherwise from the duty to inform, and of the different means by which such cookies can be rejected, allowed or blocked.',
  cookiesPoliciesModalIntroP4: 'Below you will find detailed information about: what cookies are, what type of cookies this website uses, how to disable them in your browser and how to specifically block the installation of third-party cookies.',
  cookiesPoliciesModalResponsibility: 'Who is responsible for cookies on this website?',
  cookiesPoliciesModalResponsibilityP1: 'The entity responsible for cookies is CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (hereinafter CCOMMS), holder of tax number (N.I.F.) B-86414000, with registered office in Leganés (Madrid) Avenida de la Recomba, 14, duly registered in the Companies Register of Madrid in Volume 29,801, Folio 98, Section 8, Sheet number M-536210.',
  cookiesPoliciesModalResponsibilityP2: 'E-mail address of the Data Protection Officer.',
  cookiesPoliciesModalWhatIsCookie: 'What is a cookie?',
  cookiesPoliciesModalWhatIsCookieP1: 'A cookie is a piece of data, a file or a data storage and retrieval device that is downloaded to your system (smartphone, tablet or Smart TV) when you access our website or social media accounts or when you receive an email.',
  cookiesPoliciesModalWhatIsCookieP2: 'Cookies allow us to automatically store and retrieve data on how you use our digital media and about the system or device you use to access them.',
  cookiesPoliciesModalWhatIsCookieP3: 'A cookie is merely data stored on the user\'s computer, i.e. it is not a form of software or code fragments. In consequence, cookies cannot transmit or execute viruses, nor install malware such as Trojans or spyware.',
  cookiesPoliciesModalCookieTypes: 'What type of cookies are on the website, and what is their purpose?',
  cookiesPoliciesModalCookiesInstall: 'Which cookies can be installed on the CertySign website?',
  cookiesPoliciesModalCookiesInstallP1: 'Access to the CertySign Website implies the possible installation of proprietary and/or third party Cookies, which are detailed in the following table:',
  cookiesPoliciesModalBrowserConfigCookies: 'How to configure cookies in your browser',
  cookiesPoliciesModalBrowserConfigCookiesP1: 'You can choose to receive cookies or be informed about how they are enabled in your browser settings. All cookies except Flash cookies can be managed from your browser normally in the "Options" or "Preferences" section.',
  cookiesPoliciesModalBrowserConfigCookiesP2: 'You can disable them completely in which case your browser will not allow any to be installed (making it impossible for you to use all the features of the website). Or you can choose to only install those that come from certain websites, which will allow you to enjoy the advanced features of the websites that respect the privacy of your data and exclude those that do not.',
  cookiesPoliciesModalBrowserConfigCookiesP3: 'In the following links we provide access to the main support pages:',
  cookiesPoliciesModalBrowserConfigCookiesP4: 'The User can also oppose use of certain cookies by using the following services:',
  cookiesPoliciesModalBrowserConfigCookiesLI1: 'Blocking cookies in Chrome',
  cookiesPoliciesModalBrowserConfigCookiesLI2: 'Blocking cookies in Safari',
  cookiesPoliciesModalBrowserConfigCookiesLI3: 'Blocking cookies in Firefox',
  cookiesPoliciesModalBrowserConfigCookiesLI4: 'Blocking cookies in Internet Explorer',
  cookiesPoliciesModalBrowserConfigCookiesLI5: 'http://www.criteo.com/deactivate-criteo-banners/',
  cookiesPoliciesModalBrowserConfigCookiesLI6: 'http://youronlinechoices.eu/',
  cookiesPoliciesModalBrowserConfigCookiesLI7: 'http://www.networkadvertising.org/choices/',
  cookiesPoliciesModalUnavailableCookies: 'What happens if cookies are disabled?',
  cookiesPoliciesModalUnavailableCookiesP1: 'You may still browse our Website even if you reject cookies, although some of its services may be limited and your experience may therefore be less satisfactory.',
  cookiesPoliciesModalUnavailableCookiesP2: 'Rejecting, blocking or not allowing the cookies described in this Policy may affect the correct browsing of the content reproduced on the website.',
  cookiesPoliciesModalAcceptCookies: 'Accepting the Cookies Policy',
  cookiesPoliciesModalAcceptCookiesP1: 'Clicking on the "ACCEPT COOKIES" button implies that you accept the use of cookies.',
  cookiesPoliciesModalAcceptCookiesP2: 'However, the Cookies Policy will be displayed at the bottom of every website page every time you log in, in order to ensure you are aware of it.',
  cookiesPoliciesModalUpgradeCookies: 'Do we update our Cookies Policy?',
  cookiesPoliciesModalUpgradeCookiesP1: 'The information provided in this Cookies Policy may be revised due to updates or changes in the cookies management policy; likewise, the Spanish Data Protection Agency may issue instructions and/or recommendations to amend the content.',
  cookiesPoliciesModalUpgradeCookiesP2: 'We recommend checking this policy every time you access our Website, in order to ensure you are adequately informed about how and why we use cookies.',
  cookiesPoliciesModalRecipientCookies: 'Who is the information received by, and how does the use of cookies affect personal data protection?',
  cookiesPoliciesModalRecipientCookiesP1: 'For more information on the processing of your data see the ',
  cookiesPoliciesModalRecipientCookiesLinkCCOMMS: 'Data Protection Policy',
  cookiesPoliciesModalContactInfoCookies: 'Contact information',
  cookiesPoliciesModalContactInfoCookiesP1: 'For any questions, clarifications and/or suggestions regarding cookies, please contact us by email at ',
  cookiesPoliciesModalDatatableCookieType: 'Cookie Type',
  cookiesPoliciesModalDatatableTreatmentTerm: 'Processing Term',
  cookiesPoliciesModalDatatableTypeAnalytics: 'Analytical',
  cookiesPoliciesModalDatatableTypeSelf: 'Proprietary',
  cookiesPoliciesModalDatatableTypeAnalyticsD: 'A tool from an external provider generates an analytical cookie on the User\'s computer every time he or she visits a Service. This cookie, which is only generated during the visit, will be used to anonymously identify the visitor on future visits to CCOMMS.',
  cookiesPoliciesModalDatatableTypeAnalyticsF: 'Allow anonymous identification of online Users through the cookie (identifies browsers and devices, not people), thus allowing an approximate count of the number of visitors and their trend over time. Anonymously identify the most visited and attractive content for Users. Know if the User is visiting for the first time or is a repeat visitor. The cookie will never be associated with any personal data that may directly identify him/her unless the User decides to register with CCOMMS. Such cookies will only be used for statistical purposes, in order to help optimise and improve the User\'s experience on the site.',
  cookiesPoliciesModalDatatableTypeAnalyticsT: 'Persistent',
  cookiesPoliciesModalDatatableTypeSelfD: 'This type of cookie provides information on each anonymous User of the CCOMMS Services.',
  cookiesPoliciesModalDatatableTypeSelfF: 'Among other things, they store the length and frequency with which the user viewed the different sections and any interactions with them, as well as the user’s browsing patterns and/or behaviour.',
  cookiesPoliciesModalDatatableTypeSelfT: 'Persistent',
  eventInfo: 'Event information',
  manual: 'Manual',
  guia: 'Guide',
  supportLoggedPhonePrefferedMessage: '(User support already logged in - Preferred Phone)',
  supportLoggedNotPhoneMessage: 'No telephone indicated',
  supportLoggedUserMessage: '(User support already logged in) ',
  supportNotLoggedUserWebMessage: '(Login - Support via web) The user requests assistance to recover the password',
  supportNotLoggedUserNotAvailableMessage: 'Not available',
  supportNotLoggedUserC2CMessage: '(Login - Support via C2C) The user requests telephone assistance to recover the password',
  processA4NotSupported: 'The size of the document must not exceed A4 format, 389 pages or 15Mb.',
  cookiesGAMessage: 'Internal measurement with Google Analytics. These cookies store a unique identifier to recognize the user on future visits to the website.',
  cookiesGAFGenericMessage: 'Internal measurement with Google Analytics.',
  cookiesCookieMessage: 'Cookie',
  cookiesNecessaryCookieDescription: 'This cookie is necessary and is used to establish user preferences regarding the cookies policy.',
  documentHtmlRule: 'Accepted file type - HTML',
  rulesFilesSizePostal: 'The file must be a maximum of 15Mb',
  rulesFilesSizeEmail: 'The file must be a maximum of 14Mb',
  rulesFilesSizeMultiple: 'The maximum total size of the files is 14Mb',
  rulesFilesSizeHtml: 'The file must be a maximum of {fileSize}Mb',
  rulesFilesExtensionHtml: 'The file must be an HTML',
  group_cannot_be_empty: 'The group :group_name: cannot be empty',
  language: 'Language',
  defaultLanguage: 'Default language',
  transactionDownloadPee: 'See PEE',
  transactionTracking: 'Shipment tracking',
  documentOrder: 'Establish file order',
  yes: 'Yes',
  no: 'No',
  notifications: 'Notifications',
  activateNotifications: 'Activate',
  deactivateNotifications: 'Deactivate',
  selectCategoryNotifications: 'Select action for notifications',
  activateCategoryNotifications: 'Activate all',
  deactivateCategoryNotifications: 'Deactivate all',
  customCategoryNotifications: 'Personalize',
  enabledNotifications: 'Notifications activated',
  disabledNotifications: 'Notifications deactivated',
  customNotifications: 'Notifications personalized',
  selectNotifications: 'Select notification settings',
  enabledNotificationsDescriptionText: 'Each event will generate a notification. You will be able to choose between receiving an email for each of them, or a daily report with the notifications grouped together.',
  disabledNotificationsDescriptionText: 'You will not receive any notifications regarding the status of shipments made.',
  customNotificationsDescriptionText: 'You will be able to select processes for which you want to receive notifications regarding their status, and processes for which you do not.',
  enabledNotificationsConfigurationText: 'Settings',
  enabledNotificationsConfigurationRealTime: 'Notifications in real time',
  enabledNotificationsConfigurationGrouped: 'Grouped notifications',
  selectEnabledNotifications: 'Select type of notifications',
  enabledNotificationsRealTimeDescriptionText: 'Receive an instant email for each event.',
  enabledNotificationsGroupedDescriptionText: 'You will receive an email at the selected time with the report of all the events from the previous day.',
  enabledNotificationsScheduleRangeDescriptionText: 'Notify at the following time.',
  enabledNotificationsScheduleDescriptionText: 'Notify only at the following time.',
  chooseNotificationsRange: 'Choose how and when you want to receive notifications.',
  enabledNotificationsCustomText: 'Personalization of notifications',
  realTimeNotificationsDescription: 'Notifications will be sent in real time. For each event, an email will be sent regarding its status.',
  languageAndTimezone: 'Language',
  defaultTimeZone: 'Default time period',
  user_process_notifications_updated: 'Updated notifications',
  changeSectionConfirmation: 'Changes pending saving',
  changeSectionConfirmationText: 'You have made changes that are pending saving. If you want to save them now, click on',
  groupedNotificationsDescription: 'Notifications are sent through an attached report that contains a list of all of the transactions and the last event registered for each, in the chosen time frame.',
  groupedNotificationsReportTime: 'What time would you like to receive the report?',
  discard: 'Discard',
  cost_center_updated: 'Cost center successfully updated',
  loginInfo: 'Session login information',
  lastLoginSuccess: 'Last session login',
  lastLoginError: 'Last unsuccessful login attempt',
  noRecord: 'No registration',
  //New translations 221130
  charLimitLength: 'Field must be {limit} characters or less',
  requiredLastName: 'Surname(s) required',
  optional: 'Optional',
  availableVariables: 'Variables available',
  twoFactorText: 'The user requires two-factor authentication. To log in, enter the code that will be displayed in your multi-factor access management application.',
  mfa_configuration_required: 'The user requires two-factor authentication. To set it up, read the QR from your multi-factor access management application. Then, enter the code in the box below',
  code: 'Code',
  codeRequired: 'The code is required to log in',
  mfa_code_invalid: 'The code is incorrect',
  communicationContent: 'Content of the communication',
  uploadFiles: 'Include documents',
  modifyFiles: 'Change documents',
  fileResume: '{nfiles} file(s) ({size})MB in total',
  moreThan1City: 'The postcode corresponds to more than one town, see the drop-down menu or edit the field manually.',
  shipmentDataSectionDescription: 'The contents of the communication are added in this section. The documents must be in PDF format and A4 size.',
  zipCodeFormat: 'You must enter a valid postcode',
  deleteRecipient: 'Delete this recipient',
  mobileAddRecipientDescription: 'More than one recipient?',
  errorFilesTotalSizeExceeded: 'The total size of the files may not exceed 15MB.',
  fileExtensionNotAccepted: 'The file extension is invalid. (Accepted extensions are= {acceptedExtensions})',
  nameAndSurnameLength: 'The forename and surname may not exceed 99 characters.',
  batchModeTitle: 'Batch submission',
  batchModeCSVStep: 'Recipients',
  batchModeAttachmentsStep: 'Required files',
  batchModeDownloadSampleCSV: 'Download the sample file.',
  batchModeExtraCSVStepIntroduction: 'First you must upload a <strong>CSV</strong> or <strong>XLS</strong> file with the submission data. Below you can download a sample file that includes the necessary fields.',
  batchModeExtraCSVStepIntroduction2: 'Once you have prepared the file, you can add it by clicking or dragging it into the area provided. Remember that the file <strong>must not contain empty lines or errors</strong> in any of the recipient fields.',
  batchModeExtraCSVStepIntroduction3: 'Once the file structure has been validated, the <strong>Continue</strong> button will be enabled, and you will be able to continue with the process.',
  batchModeExtraFilesStepIntroduction: 'In this step, the resources that need to be sent to each recipient will be added.<br><br>A total of <b>{count}</b> files have been detected. You can add them by clicking or dragging them into the area provided for this purpose.<br><br>Remember that the total number of files in each submission cannot exceed <b>{fileSizeLimit} MB</b> and the overall maximum size of the submission is <b>{totalSizeLimit} GB</b>.',
  extraFilesTableHeaderFileName: 'File name',
  extraFilesTableHeaderStatus: 'Status',
  extraFilesTableStatusNotFound: 'To be uploaded',
  extraFilesTableStatusProcessing: 'Processing',
  extraFilesTableStatusUploaded: 'OK',
  extraFilesTableStatusUploadedCorrectly: 'Processed',
  extraFilesTableHeaderActions: 'Actions',
  batchModePreviewStep: 'Preview and send',
  batchModeStep1RemoveFile: 'Delete file',
  batchModeCSVTableTitle: 'The file <strong>{filename}</strong> contains <strong>{nOfRecipients}</strong> recipients.',
  batchModeCSVTableSubtitle: 'Below is a preview of the first recipients of the file. Click on ‘See more’ for more information.',
  batchModeCSVTableSubtitleNoData: 'The selected file does not contain any recipients.',
  batchModeNoDataTitle: 'No file selected. Once added, you will see the first recipients of the file.',
  dropZoneText: 'Click or drag your files here.',
  dropZoneTextSingle: 'Click or drag your file here.',
  batchModeDialogTitle: 'Add recipients',
  batchModeDialogManualColTitle: 'Manually',
  batchModeDialogManualColDescription: 'If you wish to send one or more submissions individually, please choose this option.',
  batchModeDialogBatchColTitle: 'Batch submission',
  batchModeDialogBatchColDescription: 'If you want to send a CSV or XLS file with several recipients and documents in a simple way, choose this option.',
  batchModeDialogBatchBtnManual: 'Individual submission',
  batchModeDialogBatchBtnBatch: 'Batch submission',
  batchModeResourceTableNOfFilesFound: '<b>{nOfRequiredFiles}</b> files to be added',
  batchModeResourceTableAllFilesAdded: 'All the necessary files have been added.',
  // batchModeResourceTableStatus : 'Current status= <b>$currentFiles$/</b>$nOfRequiredFiles$',
  batchModeResourceTableExpandText: 'Click for more details.',
  batchModeResourceTableFilenameText: 'File name',
  batchModeResourceTableFoundOnNRows: 'recipients involved',
  batchModeResourceTableErrorFilenameInvalid: 'One or more of the files that you have tried to add do not correspond to the expected files.',
  batchModeResourceTableErrorFileSizeLimit: 'The file size is more than {size}MB',
  batchModeResourceTableErrorTotalFileSizeLimit: 'The total size of the uploaded files cannot exceed {size}GB.',
  batchModeResourceTableInfoFileReplaced: 'The file {filename} has been replaced.',
  seeMore: 'See more',
  seeLess: 'See less',
  pending: 'Pending',
  batchModePreviewText: 'Once processed, you will be able to see the transactions in the corresponding view and you will receive an email with the information for each submission, as well as the transaction identifier associated with each recipient.',
  batchModePreviewText1: 'In the event that any of the submissions cannot be sent, the email will specify which of them have not been processed and the reason for the error.',
  csvError: 'The file could not be processed.',
  csvErrorDetailStructure: 'The file structure is wrong.',
  csvErrorDetailIncorrectFields: 'The fields do not match the required process data.',
  csvErrorDetailIncorrectExtensions: 'There are invalid file extensions in the following rows:',
  batchModeStep2SpaceLeft: 'Used space',
  batchModeStep2PendingFiles: 'Pending files',
  launchDataKoBatchMode: 'The data provided are invalid.',
  launchDataKoBatchModeStep1: 'No file has been selected.',
  launchDataOkBatchModeStep1: 'The file structure is correct. You can continue with the process.',
  launchDataKoBatchModeStep2: 'Add the necessary files.',
  launchDataOkBatchModeStep2: 'The necessary files have been added. You can continue with the process.',
  launchDataOkBatchMode: 'The data is correct. You can continue with the process.',
  batchModeProcessLaunchedStep1: 'Starting the registration process',
  batchModeProcessLaunchedStep2: 'Uploading the resources required for submissions',
  batchModeProcessLaunchedStep3: 'Uploading the recipient file',
  batchModeProcessLaunchedStep4: 'Registration process completed',
  transactionsProcessedBatchMode: 'Submission registered successfully',
  transactionProcessingBatchMode: 'Processing batch submission...',
  transactionProcessingBatchModeError: 'Error in batch submission processing',
  transactionProcessingBatchModeErrorDescription: 'An error has occurred in batch processing. Please contact the Support team.',
  csvErrorDialogDescription: 'Remember that you must upload a <b>CSV</b> or <b>XLS</b> file and that it <strong>must not contain empty lines or errors</strong> in any of the recipient fields.',
  extraFileError: 'It was not possible to add the file.',
  extraFileErrorDialogDescription: 'Please note that the size limit per transaction is <b>{fileSizeLimit} MB</b> and the maximum size of the entire batch submission is <b>{totalSizeLimit} GB</b>.',
  extraFileErrorTransactionSizeLimit: 'The maximum submission size has been exceeded for the following records= {rows}',
  htmlEditorInfoParagraph1: 'In this step, you will personalise the content of the email that will be sent to each of the recipients.',
  htmlEditorInfoParagraph3: 'You can select an editing mode:',
  htmlEditorInfoBullet1: '<span>Template selection</span>. Select a template and make the necessary adjustments from the editor.',
  htmlEditorInfoBullet2: '<span>Custom HTML</span>. Upload a custom HTML file and make the necessary adjustments from the editor.',
  htmlEditorButtonUploadFile: 'UPLOAD HTML',
  htmlEditorDragAndDrop: 'Click here or drag the attachments to the email.',
  batchModeExtraCSVStepIntroductionVariables: 'Remember that the following variables are available for use in the transaction fields:',
  batchModeContactId: 'Recipient reference',
  batchModeContactName: 'Forename of the recipient',
  batchModeContactSurname: 'Surname of the recipient',
  htmlEditorTemplateSelected: 'Selected template',
  htmlSubjectError: 'The subject line has more than <span>{subjectLimit}</span> characters.',
  htmlEditorInfoParagraph4: 'Please note that the maximum total file size is <b>{totalSizeLimit} Mb.</b>',
  backToProcess: 'Back to processes',
  infoAndHelp: 'Information and assistance',
  modelEmailHtmlTemplateSelector: 'Select template',
  modelEmailEmailBodyNotHtml: "The selected file is not in html format",
  templateSelectorDefaultTemplate: 'Default',
  templateSelectorCustomTemplate: 'Customised',
  templateSelectorNoLogoTemplate: 'No logo',
  filesExcedingSizeLimit: 'Files exceeding the size limit',
  emailAttachmentsSectionTitle: 'Email attachments',
  emailContent: 'Email content',
  emailSubject: 'Email subject',
  multipleFileExtensionError: "Hay uno o más ficheros con extensiones inválidas. (Las extensiones aceptadas son: {acceptedExtensions})",
  filePasswordProtectedOrDamage: "Invalid file. Check that the file is not damaged or password protected and try again",
  backLostChangesHTMLText: "If you go back, you will lose the changes in the email template as well as the attached files. <br>Are you sure you want to <b>go back</b> to the previous step?",
  backLostChangesHTMLConfirmBack: "Go back to the previous step",
  backLostChangesHTMLConfirmContinue: "Continue with sending",
  processStepperDigitalSignatureConfigurationTitle: 'Configuration',
  processStepperDigitalSignatureConfigurationDescription: 'Personalization of the signing ceremony',
  processStepperDigitalSignatureSignersTitle: 'Signers',
  processStepperDigitalSignatureSignersDescription: 'Configuration of the signers and signing experience',
  processStepperDigitalSignatureDocumentsTitle: 'Documents',
  processStepperDigitalSignatureDocumentsDescription: 'Selection of documents and interaction of the signers',
  processStepperDigitalSignatureSummaryTitle: 'Confirmation and Submission',
  processStepperDigitalSignatureSummaryDescription: 'Review and confirmation of the signing ceremony',
  transactionName: 'Transaction Name',
  transactionInterveners: 'Transaction Interveners',
  more: 'more',
  finished: 'Completed',
  eventsInfo: 'Events Information',
  transactionInvalidAlert: 'During the verification of the transaction input data, possible errors were detected that would prevent it from executing correctly. The transaction has been blocked preventively. You must launch a new transaction with the correct input data.',
  transactionEntryData: 'Transaction Input Data',
  goToSignature: 'Go to Signature',
  intervenersNotDetected: 'No interveners detected',
  transactionEventsRegistered: 'Events registered in the transaction',
  aboutEvents: 'About Events',
  notEvents: 'No events have been registered in the transaction.',
  notEventsRegistered: 'No events have been registered in the transaction. If the transaction was created recently, it is possible that it is still being generated.',
  downloadCertificate: 'Download Certificate',
  notValid: 'Not Valid',
  transactionPending: 'In Progress',
  refresh: 'Refresh',
  creation: 'Creation',
  transactionDigitalSignatureInfo: 'Digital Signature Transaction Information',
  reminders: 'Reminders',
  input: {
    clear: 'Clear'
  },
  intervenerStartCeremony: 'To start this intervener’s signing turn, a new tab will open. Remember that if there is a signing order in the transaction, previous interveners must have completed the documents.',
  details: 'Details',
  front_renderer_builder_transaction_opened: 'Signing transaction visited',
  front_renderer_builder_transaction_finished: 'The signing transaction has been completed by all interveners',
  front_renderer_builder_intervener_signed: 'An intervener has completed the signing of the associated documents',
  front_renderer_builder_transaction_created: 'The signing transaction has been successfully created',
  front_renderer_builder_transaction_invalid: 'The signing transaction contains errors and could not be created correctly',
  front_renderer_builder_intervener_finished: 'Intervener has completed all signatures',
  certificate_builder_generated: 'The completion certificate of the transaction has been generated, which includes the content of the communication and the evidence associated with the events that occurred in the process.',
  transactionFilesParagraph: 'List of files contained in the transaction',
  transactionDigitalProcessed: 'Transaction "{transactionName}" processed successfully',
  signatureType: 'Signature Type',
  faceToFace: 'In-Person',
  remote: 'Remote',
  transactionWarning: 'With Errors',
  cancelTransaction: 'Cancel Transaction',
  identifyTransactionPlataformCommunications: 'Identify the transaction in the platform and in the communications',
  placeholderTransactionName: 'Transaction Name',
  determineMethodAccessSigning: 'Determine the method of access to the signing ceremony',
  transactionType: 'Transaction Type',
  descriptionRemoteSignature: '<p><b>Remote electronic signature</b> allows signers to access the signing ceremony from anywhere and any device through available communication channels.</p>',
  descriptionPresentialSignature: '<p><b>In-person signature</b> allows signers to access from the device used to initiate the transaction.</p>',
  setSignatureReminders: 'Set reminders to access the signing ceremony',
  transactionReminder: 'Transaction Reminders',
  transactionRedirectToTransactionDetail: 'View the transaction',
  transaccion_cancelada: 'Transaction cancelled',
  cancelled: 'Cancelled',
  digital_certificate: 'Cert.',
  cert: 'Cert.',
  nif: 'ID/Tax Number',
  sms_otp: 'SMS OTP',
  email_otp: 'Email OTP',
  video_id: 'Identification by document',
  ama: 'AMA Portugal',
  lastUpdate: 'Last Update',
  cancelTransactionAdvertisement: 'The cancellation of the transaction is not reversible. Remember that the cancellation process may take a few minutes. Are you sure you want to cancel the transaction?',
  transaction_cancelled: 'Transaction cancelled',
  transaction_not_cancelled: 'The transaction could not be cancelled',
  signed: 'Signature completed',
  addReminder: 'Add Reminder',
  sendingNewReminder: 'Add a reminder, setting the days that must pass between the first sending or the previous reminder',
  confirm: 'Confirm',
  sinceCreation: 'since creation',
  sinceReminderNumber: 'since reminder No.',
  reminderLimitReached: 'You have reached the reminder limit',
  reminderLimitReachedZero: 'No reminders can be added',
  customTextComplementSignatureAccessInformation: 'Informational text included in the access emails to the signature',
  globalMessageInterveners: 'Global message to the interveners',
  exampleInformSignatory: 'Enter the content of the message',
  days: 'days',
  infoReminder: '<p>Reminders for the signing ceremony are communications sent to signers after creation. The limit is determined by the expiration of the transaction or, if any, the limits of the process itself.</p>',
  infoReminderDays: '<p>The days indicated in the first reminder will be counted <b>from the creation of the transaction</b>, while the days indicated in subsequent reminders will be counted from the sending of the previous reminder.</p>',
  determineDaysSigningProcessRemainActive: 'Define the days that the signing ceremony will remain active',
  transactionExpiration: 'Transaction Expiration',
  orderOfSignature: 'Order of Signature',
  signatoriesAdded: 'added to the transaction',
  infoTransactionWithoutSignatures: 'The transaction does not yet have signers. You can start adding them from the bottom button.',
  intervenersLimitReached: 'You have reached the limit of signers',
  addSigner: 'Add Signer',
  signerEdition: 'Signer Configuration',
  nameSurname: 'Name and Surname / Business Name',
  placeholderSignerName: 'John Example Example',
  identificationType: 'Identification Type',
  withoutIdentification: 'Without identification',
  securitySignature: 'Confirmation method',
  shippingChannel: 'Shipping channel',
  completetionEmail: 'Completion email',
  personalizedMessage: 'Personalized message from the initial email',
  numberDocument: 'Document No.',
  contactMethod: 'Contact method',
  notIndicated: 'Not indicated',
  identificationSignature: 'Identification/Signature',
  documents: 'Document/s',
  infoTransactionWithoutDocuments: 'The transaction still has no documents. You can start adding them from the button below.',
  documentsLimitReached: 'You have reached the document limit',
  addDocument: 'Add document',
  requirementsAddDocument: 'You must consider that the documents must meet the following requirements:',
  addDocumentError: 'The document could not be added',
  documentNameRepeated: 'The name of the document you are trying to add already exists in the transaction.',
  documentDetail: 'Document details',
  documentActions: 'Actions',
  withoutActions: 'Without actions',
  positionSignature: 'Position signatures',
  deleteDocument: 'Delete document',
  size: 'Size',
  totalFileSizeExceeded: 'The total size of the uploaded files cannot exceed {size&Unit}',
  minCharacters: 'Minimum {min} characters',
  maxCharacters: 'Maximum {max} characters',
  expirationDays: 'Must be greater than 0 and less than {max} days',
  expirationMustBeLongerThanReminders: 'The expiration must be greater than the reminders',
  reminderRuleAvailableDaysInput: 'Must be greater than 0 and less than {max} days',
  remoteDigitalSignature: 'Remote electronic signature',
  presentialSignature: 'In-person signature',
  none: 'None',
  noneMasc: 'None',
  certificateDigital: 'Digital certificate',
  alertAbandonProcess: 'If you leave the process now, you will lose the entered data. Are you sure you want to exit?',
  amaPT: 'Ama Portugal',
  graphometricSignature: 'Graphometric signature',
  signature: 'Signature',
  documentSignatures: 'Document signatures',
  closeDocument: 'Close document',
  selectSignersActions: 'Select a signer to know the available actions on the document',
  sendSignedDocuments: 'Send signed documents',
  notSendSignedDocuments: 'Do not send signed documents',
  documentLimitSizeAlert: 'The size of the document cannot exceed 12 MB',
  documentFormatAlert: 'Documents must be in PDF format and not be encrypted or corrupted',
  documentLimitCountAlert: 'The document limit for the transaction is ',
  signersAdded: 'Signer/s',
  sendTransaction: 'Send signature ceremony',
  sendTransactionInfo: 'At the top, as a summary, you have the data entered during the configuration. Once reviewed, you can proceed with the submission by clicking the button below',
  transactionOkMessage: 'The transaction has been successfully processed; in the next few minutes, you will be able to see the related events from the "Transaction Details" view. You can access it by clicking the button below',
  transactionsOkMessage: 'The transactions have been successfully processed; in the next few minutes, they will be visible in the "Transactions" view. You can access it by clicking the button below',
  goToPresentialCeremony: 'Start in-person signature ceremony',
  interactionType: 'Type of interaction',
  signDocuments: 'Sign documents',
  acceptDocuments: 'Accept documents',
  requireCompleteReading: 'Complete review of the document',
  availableActions: 'Available actions',
  signerAcceptDocument: 'The signer must accept this document',
  signaturesNotAvailable: 'Signatures are not available if the signer has "Document Acceptance" enabled',
  selectSigners: 'Select signers',
  selectSignersDescription: 'Select the signers of the transaction who must participate in the document',
  action: 'action',
  singleClickSignatureInfoText: 'Document acceptance has been activated, so it will be signed with a single click.',
  requireScrollToBottomInfoText: 'Complete review of the document is activated',
  page: 'Page',
  interveners: 'Interveners',
  "face-to-face": 'In-person',
  expiration: 'Expiration',
  signatureStatus: 'Signature status',
  remaining: 'Remaining/s',
  completed: 'Completed',
  contact: 'Contact',
  events: 'event/s',
  notSpecified: 'Not specified',
  intervener: 'Intervener',
  reference: 'Reference',
  expired: 'Expired',
  documentViewerDialogSubtitle: 'Select the signers who will interact with the document, as well as the actions to be taken',
  front_renderer_builder_intervener_identification_success: 'Intervener has been successfully identified in the signing ceremony',
  front_renderer_builder_signature_otp_generated: 'The access code to the transaction has been generated',
  front_renderer_builder_signature_otp_sent: 'The access code to the transaction has been sent to the intervener`s device',
  front_renderer_builder_transaction_expired: 'The transaction is no longer accessible as it has expired',
  batches: 'Batch shipments',
  view_batches: 'Batch shipments',
  open: 'Open',
  selectProcess: 'Select a process',
  transactionsSearchPlaceHolder: 'Search by Transaction ID or participant',
  batchesDescription: 'This section displays the batch shipments made on the platform',
  scheduleTime: 'Shipping date',
  schedule: 'Status/Shipping',
  totalRecords: 'Records',
  showTransactions: 'View transactions',
  cancelBatch: 'Cancel shipments',
  cancelBatchConfirmText: 'Are you sure you want to cancel the shipment and all associated transactions?',
  selectDateFilter: 'Date filter',
  stateFilterPlaceholder: 'Status filter',
  processFilterPlaceholder: 'Process filter',
  batchSearchTooltip: 'The available fields for search are',
  batchSearchTooltipFirstItem: 'Batch shipment ID',
  batchSearchTooltipSecondItem: 'Sender user email',
  batchSearchTooltipThirdItem: 'Shipment file name',
  processed: 'Processed',
  batchCancelled: 'Cancelled',
  batchDetail: 'Batch shipment details',
  batchInfo: 'Shipment information',
  batchInfoSectionIntroductionText: 'If desired, you can select an identifying name that will be used throughout the process. If this field is omitted, the process name will be used.',
  batchNamePlaceholder: 'Default name (process name)',
  shipmentScheduling: 'Shipping scheduling',
  scheduleShipmentIntroduction: 'You can schedule the shipment for a specific date and time. If no date is selected, the shipment will be sent immediately.',
  shippingDate: 'Shipping date',
  time: 'Time',
  selectDate: 'Date',
  selectTime: 'Time',
  scheduledShipmentIncompleteDate: 'You must select a date and time to schedule the shipment',
  scheduleShipment: 'Schedule shipment',
  edit: 'Edit',
  digitalSignatureEmptyStepMessage: 'There is no field available to edit, press the <b>Continue</b> button to proceed.',
  shipmentScheduled: 'Shipment scheduled for',
  scheduled: 'Scheduled',
  sentTransactions: 'Sent transactions',
  sent: 'Sent',
  pendingTransactions: 'Scheduled transactions',
  see: 'See',
  backToTransactions: 'Back to sent transactions',
  shipmentDate: 'Shipping date',
  selectProcessFirst: 'To view the available statuses, first select a process'


}

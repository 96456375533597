export default {
  badge: 'Plaka',
  close: 'Itxi',
  dataIterator: {
    noResultsText: 'Ez dago bilaketarekin bat egiten duen elementurik',
    loadingText: 'Kargatzen...'
  },
  dataTable: {
    itemsPerPageText: 'Lerro orri bakoitzeko:',
    ariaLabel: {
      sortDescending: ': Beheranzko ordenan. Sakatu ordena kentzeko.',
      sortAscending: ': Goranzko ordenan. Sakatu beherantz ordenatzeko.',
      sortNone: ': Ordenatu gabe. Sakatu gorantz ordenatzeko.',
      activateAscending: 'Aktibatu goranzkoa',
      activateDescending: 'Aktibatu beheranzkoa',
      activateNone: 'Desaktibatu dena'
    },
    sortBy: 'Honen arabera ordenatua'
  },
  dataFooter: {
    itemsPerPageText: 'Elementuak orri bakoitzeko:',
    itemsPerPageAll: 'Denak',
    nextPage: 'Hurrengo orria',
    prevPage: 'Aurreko orria',
    firstPage: 'Lehenengo orria',
    lastPage: 'Azken orria',
    pageText: '{0}-{1} tik {2}'
  },
  datePicker: {
    itemsSelected: '{0} hautatu dira',
    prevMonthAriaLabel: 'aurreko hilabetea',
    nextMonthAriaLabel: 'hurrengo hilabetea'
  },
  noDataText: 'Ez dago daturik eskuragarri',
  carousel: {
    prev: 'Aurreko bisuala',
    next: 'Hurrengo bisuala',
    ariaLabel: {
      delimiter: 'Menu birakariko {0}etik {1} diapositiba'
    }
  },
  calendar: {
    moreEvents: '{0} gehiago'
  },
  fileInput: {
    counter: '{0} fitxategi',
    counterSize: '{0} fitxategi ({1} guztira)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  home: 'Hasiera',
  templates: 'Fluxuak',
  accounts: 'Kontuak',
  processes: 'Prozesuak',
  transactions: 'Transakzioak',
  statistics: 'Estatistikak',
  support: 'Euskarria',
  contacts: 'Kontaktuak',
  administration: 'Administrazioa',
  search: 'Bilaketa',
  appearance: 'Estiloak',
  users: 'Erabiltzaileak',
  alastria: 'Alastria',
  billing: 'Fakturazioa',
  account: 'Kudeatu nire kontua',
  admin: 'Administrazioa',
  demos: 'Demoak',
  selectedSubaccount: 'Azpikontua hautatu da',
  withoutSubaccount: 'Azpikonturik gabe',
  groups: 'taldeak',
  filterDate: 'Data-iragazkia',
  subaccount: 'Azpikontua',
  subaccounts: 'Azpikontuak',
  costCenters: 'Kostu-zentroak',
  subaccountsAssigned: 'Esleitutako azpikontuak',
  defaultCostCenter: 'Kostuaren zentro lehenetsia',
  selectedCostCenter: 'Hautatutako kostu-zentroa',
  availableCostCenters: 'Kostu-zentro eskuragarriak',
  changeDefaultCostCenter: 'Kostu-zentro lehenetsia aldatu',
  costCenterDialogText: 'Nahi izanez gero, transakziorako hautatu duzun kostu-zentroa aldatu dezakezu. Horrek ez du aldatuko kostu-zentro lehenetsia, transakzio honetarako bakarrik erabiliko da',
  costCenterDialogTextAccount: 'Nahi izanez gero, kostu-zentro lehenetsia aldatu dezakezu. Ekintza honekin hautatutako kostu-zentroa lehenetsiko da',
  costCenterSelectedTitle: 'Hautatutako kostu-zentroa',
  activeSubaccount: 'Azpikontu aktiboa',
  user: 'Erabiltzailea',
  userData: 'Erabiltzailearen datuak',
  userCECOS: 'Kostu-zentroak',
  userCECO: 'Kostu-zentroa',
  userCodeCECO: 'Kodea',
  userCECOPrimary: 'Lehenetsia',
  userCECOCodeTitle: 'Kostu-zentroaren kodea',
  password: 'Pasahitza',
  passwordchange: 'Aldatu pasahitza',
  oldpassword: 'Uneko pasahitza',
  newpassword: 'Pasahitz berria',
  updatePassword: 'Eguneratu pasahitza',
  repeatpassword: 'Errepikatu pasahitza',
  passwordrequirements: 'Pasahitzak gutxienez 8 karaktere izango ditu, eta hurrengo 3 arau osagarriak beteko ditu gutxienez:',
  passwordMinOneLowercase: 'Gutxienez letra minuskula 1.',
  passwordMinOneUppercase: 'Gehienez letra minuskula 1.',
  passwordMinOneNumber: 'Gutxienez zenbaki 1.',
  passwordMinOneSpecialChar: 'Gutxienez karaktere berezi 1.',
  passwordMinEightChars: 'Gutxienez 8 karaktere.',
  passwordCompares: 'Pasahitza alderatu behar da nahitaez.',
  passwordFieldError: 'Errorea eremuan, ikus arauak.',
  passwordrequirementsMoreThanEightChars: 'Pasahitzak gutxienez 8 karaktere izango ditu',
  notequalpasswords: 'Pasahitzak ez datoz bat',
  samepasswordthanolder: 'Pasahitz berria aurrekoaren berdina da',
  emptyOldPassword: 'Ez duzu sartu aurreko pasahitza',
  role: 'Rola',
  lastConnection: 'Azken konexioa',
  languageSelector: 'Hautatu hizkuntza',
  logout: 'Itxi saioa',
  others: 'Beste batzuk',
  continue: 'Jarraitu',
  continueWSend: 'Jarraitu bidalketarekin',
  sendRecoveryForm: 'Bidali eskakizuna',
  restartFilters: 'Berrabiarazi iragazkiak',
  back: 'Itzuli',
  backToMethods: 'Berreskuratzeko metodoak',
  moreInfo: 'Informazio gehiago',
  activateuser: 'Aktibatu erabiltzailea',
  exit: 'Irten',
  platformVersion: 'Plataformaren bertsioa',
  termsAndConditionsCompliance: 'Zehaztapenak eta baldintzak',
  privacyPoliciesCompliance: 'Datuak babesteko politika',
  cookiesPoliciesCompliance: 'Cookieen politika',
  cookiesPoliciesCertySign: 'Cookieak CertySign-en',
  alertSizeFiles: 'Fitxategi guztiek gehienez 8MB pisatu behar dute',
  login: 'Hasi saioa',
  noSubaccountsMessage: 'Ez daukazu azpikonturik esleituta',
  telephone: 'Telefonoa',
  name: 'Izena',
  lastName: 'Abizenak',
  email: 'Helbide elektronikoa',
  omit: 'Saltatu',
  id: 'Bezeroaren erreferentzia',
  clear: 'Garbitu',
  add: 'Gehitu',
  recipients: 'Hartzaileak',
  shipmentData: 'Bidalketa-datuak',
  prevAndSend: 'Aurreikusi eta bidali',
  previewFileSize: 'Fitxategiak gehienez 15Mb pisatu behar ditu',
  rulesFilesSize: 'Fitxategi guztiek gehienez 15MB pisatu behar dute',
  sectionDataFieldsDescription: 'Beheko aldean, hartzaileei transakzioa bidaltzeko beharrezko eremu ezberdinak dituzu. Harremanetarako datuen eremuak automatikoki beteko dira, hartzailearen datuetatik',
  sectionDataFieldsNotAditionalFields: 'Prozesu honek ez dauka beste eremurik, amaitu zure transakzioaren bidalketa Aurrebistatik.',
  sectionPreviewSubtitle: 'Beheko aldean, hartzaileen artean nabigatu daiteke, bidalketa bakoitza banan-banan egiaztatzeko',
  manual_version: 'Erabiltzailearen eskuliburuaren azkenengo bertsioa erakusten.',
  view_login: 'Hasi saioa',
  view_transactions: 'Transakzioak',
  view_transaction: 'Transakzioaren xehetasunak',
  view_process: 'Abiarazi prozesua',
  view_processes: 'Prozesuak',
  view_statistics: 'Estatistikak',
  view_support: 'Laguntza eta euskarria',
  view_administration: 'Administrazioa',
  view_groups: 'Taldeak',
  view_group: 'Taldearen xehetasunak',
  view_users: 'Erabiltzaileak',
  view_account: 'Nire kontua',
  view_remember_password: 'Gogorarazi pasahitza',
  view_change_password: 'Aldatu pasahitza',
  view_activate_user: 'Aktibatu erabiltzailea',
  view_new_password: 'Pasahitz berria',
  view_contacts: 'Kontaktuak',
  view_user: 'Erabiltzailearen xehetasunak',
  view_withoutsubaccounts: '',
  view_forbidden: '',
  view_root: '',
  view_error500: 'Errorea',
  searchAgain: 'Bilatu berriz',
  recipient: 'Hartzailea',
  sender: 'Igorlea',
  namesender: 'Igorlearen izena',
  subject: 'Gaia',
  emailbody: 'Emailaren gorputza',
  resourcespreview: 'Baliabideen aurrebista',
  attach: 'Erantsi',
  actions: 'Ekintzak',
  changePassword: 'Eguneratu pasahitza',
  sessionClosed: 'Saioa behar bezala itxi da',
  noPermissionMessage: 'Ez daukazu baimenik atal honetara sartzeko',
  download: 'Deskargatu',
  viewDocument: 'Ikus Dokumentua',
  accept: 'Onartu',
  reject: 'Baztertu',
  change: 'Aldatu',
  addVariables: 'Gehitu aldagaia',
  resources: 'Baliabideak',
  launchDataKo: 'Emandako datuek ez dute bidalketa egiteko balio',
  launchDataOk: 'Datuak zuzenak dira, bidali dezakezu',
  addNewRecipient: 'Gehitu beste hartzaile bat',
  transactionRecipients: 'Transakzioaren hartzaileak',
  noRecipientsSelected: 'Ez da hartzailerik hautatu',
  recipientsSelected: 'Hautatu dira',
  recipientSelected: 'Hautatu da',
  preview: 'Aurrebista',
  summary: 'Laburpena',
  transaction_summary: 'Bidalketaren laburpena',
  PDFpreview: 'PDFa aurreikusi',
  previewing: 'Aurrebista: ',
  notAvailable: 'Ez dago erabilgarri',
  hide: 'Ezkutatu',
  send: 'Bidali',
  showFile: 'Ikus fitxategia',
  error500Message: 'Errore bat egon da. Azaroak irauten badu, jarri harremanetan euskarriarekin honen bitartez: ',
  thisForm: 'inprimaki hau',
  error401Message: 'Saioa iraungi da',
  withoutData: 'Daturik gabe',
  notInformed: 'Ez da informatu',
  loginTitleText: 'Hasi saioa',
  changePasswordTitleText: 'Pasahitza aldatzea',
  activateUserTitleText: 'Erabiltzailea aktibatzea',
  homeWelcomeMessageTitle: 'Ongi etorri CertySign-era',
  homeWelcomeMessageSubtitle: 'Entrega ziurtatuaren eta transakzioen fluxuen kudeaketa-ingurunea',
  changePasswordUserTitleText: 'Pasahitz berria ezarri',
  activateUserSubtitleText: 'Erabiltzailea aktibatzeko, pasahitz berri bat idatzi behar da',
  changePasswordUserSubtitleText: 'Pasahitz berria ezartzeko, bete eremuak',
  activateUserTitleTextWithoutHash: 'URLak ez du balio',
  activateUserSubtitleTextWithoutHash: 'Erabiltzen ari zaren URLak ez du balio. Nahi baduzu, beste URL bat eska dezakezu login pantailako aukera honen bitartez "Pasahitza ahaztu dut".',
  goToLogin: 'Saio hasiera',
  activeFilters: 'Aktibatutako iragazkiak',
  rememberPassword: 'Pasahitza ahaztu al duzu?',
  rememberPasswordTitleText: 'Berreskuratu pasahitza',
  supportContact: 'Euskarriarekin harremanetan jarri',
  rememberPasswordSubTitleText: 'Hautatu pasahitza berreskuratzeko metodo bat',
  rememberPasswordEmailResponse: 'Erabiltzailea alta emanda baldin badago, email bat jasoko du pasahitza aldatzeko jarraibideekin',
  rememberPasswordWebResponse: 'Sartutako datuak zuzenak badira, gure euskarri-taldea email bidez zurekin harremanetan jarriko da eta pasahitza aldatzeko jarraibideak emango dizkizu',
  rememberPasswordPhoneResponse: 'Sartutako datuak zuzenak badira, gure euskarri-taldea telefono bidez zurekin harremanetan jarriko da eta pasahitza aldatzeko jarraibideak emango dizkizu',
  expiredPasswordResponse: 'Zure pasahitza iraungi da, laster, email bat jasoko duzu beste pasahitz bat ezartzeko jarraibideekin',
  rememberPasswordSupportEmail: 'Pasahitza berreskuratzeko emaila',
  rememberPasswordSupportEmailSubtitle: 'Email bat bidaliko dizugu, beratik zure pasahitza berreskuratzeko',
  rememberPasswordSupportEmailText: 'Arren, bete inprimakia CertySign-en erregistratutako emailarekin',
  rememberPasswordSupportWeb: 'Web bidezko euskarria',
  rememberPasswordSupportWebSubtitle: 'Bidali email bat CertySign-eko euskarri teknikoaren taldeari',
  rememberPasswordSupportWebText: 'Bete datuak eta euskarri teknikoaren taldeak ahal bezain laster erantzungo dizu.',
  emailSupportServiceHours: 'Arreta-ordutegia:',
  rememberPasswordSupportPhone: 'Telefono bidezko euskarria',
  rememberPasswordSupportPhoneSubtitle: 'Telefono-deia CertySign-eko euskarri teknikoaren taldeari',
  rememberPasswordSupportPhoneText: 'Bete datuak eta euskarri teknikoaren taldeak ahal bezain laster deituko dizu.',
  phoneSupportServiceHours: 'Telefono bidezko euskarriaren ordutegia:',
  rememberPasswordNotSupportBySchedule: 'Aukera desgaitu da ordutegitik kanpo egoteagatik',
  rememberPasswordMondayToFriday: 'Astelehenetik ostiralera:',
  rememberPasswordSaturdaySundayAndOthers: 'Larunbatak, igandeak eta jai-egunak:',
  rememberPasswordNotIncludedDays: '(jai-egunak kanpo)',
  supportFinalSendMessage: 'Zure eskaera gure Euskarriaren sailera bidali da',
  goBackToMethods: 'Beste metodo bat hautatu',
  rememberPasswordContactName: 'Izen-abizenak',
  goToC2C: 'Eskatu deia',
  messageDescription: 'Mezua',
  company: 'Enpresa',
  legalCheckboxText: 'Orrialdearen Erabilera-baldintzak eta datuen babesaren politika onartzen ditut',
  selectHelps: 'Nola lagundu zaitzakegu?',
  selectHelpsTooltip: 'Laguntza eta euskarria',
  helpManual: 'Ikus eskuliburua',
  helpGuide: 'Ikus gida azkarra',
  helpSupport: 'Euskarriarekin harremanetan jarri',
  helpSupportPhonePreffered: 'Ahal dela, telefonoz kontaktatu',
  helpSupportPhoneSchedule: 'Telefono bidezko arretaren ordutegia, lanegunak',
  requiredEmail: 'Emaila',
  requiredPhone: 'Telefonoa',
  requiredName: 'Izena',
  requiredEmailFormat: 'Emailaren formatua ez da zuzena',
  requiredPhoneFormat: 'Telefonoaren formatua ez da zuzena',
  requiredTextarea: 'Mezua',
  tag_signature: 'Sinadura elektronikoa',
  tag_email: 'Helbide elektronikoa',
  tag_sms: 'Sms-a',
  tag_cert_email: 'Email ziurtatua',
  tag_landing: 'Web orria',
  tag_biometric_signature: 'Sinadura biometrikoa',
  tag_postal: 'Posta',
  processesViewListTitle: 'Prozesuak',
  processesViewListDescription: 'CertySign-en erabilgarri dauden prozesu guztien zerrenda',
  tags: 'Etiketak',
  searchs: 'Bilatu',
  processInfo: 'Prozesuaren informazioa',
  processesCategoriesFilter: 'Kategorien araberako iragazkia',
  processesTagFilter: 'Etiketen araberako iragazkia',
  processesTagOthers: 'beste batzuk',
  processesSearch: 'Bilatu prozesuak',
  searchTransactions: 'Kontsultatu transakzioak',
  searchTransaction: 'Kontsultatu transakzioa',
  deleteFilters: 'Ezabatu iragazkiak',
  withoutResults: 'Emaitzarik gabe',
  withoutProcessResults: 'Ez daukazu prozesurik ateratzeko, zure baimenen edo ezarritako bilaketa-irizpideen arabera',
  allProcesses: 'Prozesu guztiak',
  emailNotValid: 'Emaila ez da baliozkoa',
  phoneNotValid: 'Telefonoa ez da baliozkoa',
  phoneAtLeastNineChars: 'Telefonoak, gutxienez, 9 zenbaki izan behar ditu',
  doesntExist: 'Ez da existitzen',
  dontExist: 'Ez dira existitzen',
  requiredField: 'Derrigorrezko eremua',
  errorField: 'Eremu okerra',
  numberField: 'Zenbakizko eremua',
  fiveCharsField: 'Eremuak 5 karaktere izan behar ditu',
  CPvalidField: 'CP ez dator lurralde historikoarekin',
  delete: 'Ezabatu',
  save: 'Gorde',
  file: 'Fitxategia',
  loading: 'Kargatzen',
  countrySelector: 'Hautatu herrialde bat',
  provinceSelector: 'Hautatu probintzia bat',
  andNumberMore: 'eta {count} gehiago',
  selectFile: 'Hautatu fitxategi bat',
  selectFileHtml: 'Hautatu html fitxategi bat',
  selectFiles: 'Hautatu fitxategiak',
  allTransactions: 'Transakzio guztiak',
  transactionsPerPage: 'Transakzioak orri bakoitzean',
  transactionsSend: 'Bidali dira',
  transactionslower: 'transakzioak',
  transactionWithoutId: 'ID esleitzeko zain',
  withoutCertificated: 'Ziurtagiria ez dago erabilgarri',
  filter: 'Iragazi',
  transactionsProcessFilter: 'Prozesuaren araberako iragazkia',
  processDataPreviewText: 'Jarraian, transakzio berriaren laburpena aurki dezakezu, beharrezko bidalketa-kopurua eta lotutako kostuaren zentroa barne hartuz',
  processDataPreviewTextWithoutCC: 'Jarraian, transakzio berriaren laburpena aurki dezakezu, zeinak beharrezko bidalketa-kopurua adierazten duen',
  transactionsLinkedGroupsFilter: 'Taldearen araberako iragazkia',
  transactionsDateFilter: 'Dataren araberako iragazkia',
  numberOfTransactions: 'Bidalketa-kopurua',
  associatedCECO: 'Lotutako kostuaren zentroa',
  transactionsCategoryFilter: 'Kategoriaren araberako iragazkia',
  transactionsStatusFilter: 'Egoeraren araberako iragazkia',
  transactionState: 'Transakzioaren egoera',
  transactionSummary: 'Transakzioaren laburpena',
  transactionGeneralData: 'Transakzioaren informazioa',
  transactionData: 'Transakzioaren datuak',
  files: 'Fitxategiak',
  transactionContact: 'Harremanetarako datuak',
  transaction: 'Transakzioa',
  transactionEvents: 'Transakzioaren ziurtatu daitezkeen ekitaldiak',
  transactionWithoutEvents: 'Ez dago ekitaldirik erregistratuta transakziorako',
  transactionProgress: 'Transakzioaren aurrerapena',
  transactionSubtitle: 'Transakzioaren egoeraren informazioa',
  transactionDetails: 'Transakzioaren xehetasunak',
  transactionEventInfo: 'Ekitaldiaren informazioa',
  eventMeaningPrepend: 'Zer esan nahi du ekitaldiak ',
  eventMeaningAppend: '?',
  eventMeaning: 'Zer esan nahi du ekitaldi honek?',
  transactionLaunchedDate: 'Sorrera-data',
  transactionLastEvent: 'Jasotako azken ekitaldia',
  transactionLastEventDate: 'Azken ekitaldiaren data',
  transactionContactName: 'Izen-abizenak',
  transactionContactMobilephone: 'Telefono mugikorra',
  transactionContactEmail: 'Helbide elektronikoa',
  transactionContactID: 'ID',
  advancedFilters: 'Iragazki aurreratuak',
  group: 'Taldea',
  date: 'Data',
  hideFilters: 'Ezkutatu iragazkiak',
  processName: 'Prozesuaren izena',
  usernameTransactionDetail: 'Erabiltzaile igorlea',
  recipientsNumber: 'Hartzaile-kopurua',
  invalidTransaction: 'Datu baliogabeak',
  dataTableShowRecipients: 'Bidalketa bistaratzen <span class=\'font-weight-medium\'>:page:</span> hemendik: <span class=\'font-weight-medium\'>{total}</span>',
  transactionRedirectToProcesses: 'Joan prozesuetara',
  transactionRedirectToTransactions: 'Joan transakzioetara',
  transactionRedirectToNewSend: 'Bidalketa berria',
  transactionProcessed: 'Transakzioa behar bezala prozesatu da',
  transactionsProcessed: 'Transakzioak behar bezala prozesatu dira',
  transactionError: 'Transakzioa baliogabeekin prozesatu da',
  transactionsError: 'Transakzioak baliogabeekin prozesatu dira',
  transactionProcessing: 'Transakzioa prozesatzen...',
  transactionLaunchedMessage: 'Transakzioa behar bezala prozesatu da, bidalketak prozesatu ahala, ikusgarri egongo dira atal honetan',
  transactionsLaunchedMessage: 'Transakzioak behar bezala prozesatu dira, bidalketak prozesatu ahala, ikusgarri egongo dira atal honetan',
  transactionDownloadFile: 'Ikus ziurtagiria',
  transactionProcessingMessage: 'Sistema transakzioa prozesatzen ari da, itxaron pixka bat mesedez',
  transactionsProcessingMessage: 'Sistema transakzioak prozesatzen ari da, itxaron pixka bat mesedez',
  transactionsErrorMessage: 'Hartzaile ezberdinen transkazioen prozesuan zehar, gutxienez, transakzio baten datu baliogabeak hauteman dira. Jarraian, bidalketaren transakzioen zerrenda osoa ageri da',
  transactionErrorMessage: 'Transakzioaren prozesuan zehar, datu baliogabeak hauteman dira',
  recoverProcessQuestion: 'Prozesu berri bati ekitera bazoaz, aurrerapenak galduko dituzu',
  recoverProcessQuestionAppend: 'Prozesu berri bati ekin nahi al diozu?',
  statisticsTransactionsMultipleTitle: 'Egunaren bitartean egindako transakzioak <span class="font-weight-bold">{start_day} egunaren eta {start_month}</span> hileko <span class="font-weight-bold">{end_day} egunaren eta {end_month}</span>',
  statisticsTransactionsOneTitle: '{start_month} hileko {start_day} egunean egindako transakzioak',
  statisticsTotalTransactionsTitle: 'Totalak transakzio mota bakoitzeko',
  statisticsNoResults: 'Adierazitako iragazkiekin bat datozen emaitzarik ez dago',
  statisticsProcessTitle: 'Prozesua',
  total: 'Totala',
  totalTransactions: 'Transakzioak guztira',
  totalTransactionsByProcesses: 'Transakzio xehatuak prozesuen arabera',
  accountDescription: 'Kontuaren konfigurazioa',
  accountTitle: 'Kontua',
  contactsDescription: 'Kontaktuen zerrenda eta kudeaketa',
  contactsTitle: 'Kontaktuak',
  transactionsDescription: 'Transakzioen zerrenda eta xehetasunak',
  transactionsTitle: 'Transakzioak',
  statisticsDescription: 'Zure transakzioen analisi grafikoa',
  statisticsTitle: 'Estatistikak',
  supportDescription: 'Atal honetan, laguntza aurkituko duzu laguntza teknikoaren edo erabiltzaile eskuliburuaren bitartez',
  supportManualDescription: 'Atal honetan, plataformaren erabilerarekin lotutako dokumentazioa aurkituko duzu',
  supportManualTitle: 'Kontsultatu eskuliburua eta gida',
  supportManualTitleOnlyManual: 'Kontsultatu eskuliburua',
  supportManualTitleOnlyGuide: 'Kontsultatu gida',
  administrationDescription: 'Plataformaren kontuen administrazioa',
  administrationTitle: 'Administrazioa',
  userActive: 'Erabiltzaile aktiboa',
  userInactive: 'Erabiltzaile desaktibatua',
  userStateTitle: 'Erabiltzailearen egoera',
  groupsTitle: 'Baimenen taldeak',
  groupsDescription: 'Kontuaren taldeen kudeaketa. Talde bakoitzaren parametroen eta baimenen konfigurazioa.',
  groupsAssignation: 'Esleitutako taldea',
  groupInheritFrom: 'Honen baimenak oinordetu',
  groupDataTitle: 'Taldearen informazioa',
  groupUsersPermissionsTitle: 'Baimenak, erabiltzaileak eta prozesuak',
  groupPermissionsTabsTitle: 'Baimenak',
  groupProcessesAndSubaccounts: 'Azpikontuak eta prozesuak',
  groupLinked: 'Lotutako taldeak',
  groupPermissionsOwnerText: 'Talde hau kontuaren jabea da, baimen guztiak ditu esleituta',
  groupPermissionsOwnerSubaccountProcessesText: 'Talde hau kontuaren jabea da, azpikontu eta prozesu guztiak esleituta daude',
  groupAssociatedProcesses: 'Lotutako prozesuak',
  groupAssociatedSubaccounts: 'Lotutako azpikontuak',
  newGroup: 'Talde berria',
  groupName: 'Taldearen izena',
  editGroup: 'Taldea editatzen',
  groupDescription: 'Deskripzioa',
  groupMinDaysToChange: 'Pasahitzaren indarraldiaren gutxieneko egunak',
  groupTabUsersNameTitle: 'Izen-abizenak',
  groupTabPermissionSectionTitle: 'Atalak',
  groupTabPermissionGroupTitle: 'Taldeak',
  groupTabPermissionUsersTitle: 'Erabiltzaileak',
  usersSearch: 'Bilatu erabiltzaileak',
  subaccountWithoutProcesses: 'Azpikontua lotutako prozesurik gabe',
  selectCostCenter: 'Hautatu kostu-zentro bat',
  adminGroupInfoDescription: 'Atal honetan plataformaren deskribapena, saioko politikak eta pasahitzak editatu daitezke.',
  adminGroupInfoUsers: 'Atal honetan talde honi lotutako erabiltzaileak kudeatu daitezke.',
  adminGroupInfoPerms: 'Atal honetan erabiltzaileak plataforman egin ditzakeen funtzioekin lotutako baimenak kudeatu daitezke.',
  adminGroupInfoProcesses: 'Atal honetan prozesuetara eta azpikontuetara sarrera kudeatu dezake erabiltzaileak. Sarbide horrek esleitutako prozesuen transakzioak abiarazteko, bistaratzeko eta estatistikak egiteko baimena emango dio.',
  adminGroupInfoLinked: 'Atal honetan beste talde batzuen gainbegiratzea kudeatu dezakezu. Gaituta dauden taldeek abiarazitako transakzioen informazioa eta estatistikak partekatuko dituzte uneko taldearekin.',
  passwordsQuantity: 'Biltegiratutako pasahitzen kopurua',
  passwordExpiration: 'Pasahitza iraungitzeko egunak',
  loginRetriesQuantity: 'Login saiakera-kopurua',
  loginRetriesTime: 'Login-a berriz saiatzeko minutuak',
  sessionExpiration: 'Saioa iraungitzeko minutuak',
  processAlertExpirationQuestion: 'Jarraitu nahi duzu?',
  processAlertExpirationPhrase: 'Segurtasun arrazoiengatik, laster iraungiko du saioak',
  processAlertExpiration: 'Iraungitze alerta',
  continueSession: 'Jarraitu',
  usersTitle: 'Erabiltzaileak',
  activate: 'Aktibatuta',
  deactivate: 'Desaktibatuta',
  usersPerPage: 'Erabiltzaileak orri bakoitzeko',
  usersDescription: 'Kontuaren erabiltzaileen kudeaketa',
  userInfoTitle: 'Erabiltzailearen informazioa',
  userPermissionTitle: 'Esleitutako baimen mota',
  newUser: 'Erabiltzaile berria',
  editUser: 'Erabiltzailea editatzen',
  groupTitle: 'Taldearen izena',
  administrator: 'Administratzailea',
  groupLinkedHeader: 'Lotua',
  firmaElectronica: 'Sinadura elektronikoa',
  comunicacionCertificada: 'Komunikazio ziurtatua',
  publicacionWeb: 'Web argitalpena',
  groupVisibility: 'Taldearen ikusgarritasuna',
  passwordPolicy: 'Pasahitzen politika',
  frontalPolicy: 'Saioaren politika',
  groupData: 'Taldearen datuak',
  groupsPerPage: 'Talde orri bakoitzeko',
  groupsFound: 'Aurkitu dira',
  cancel: 'Utzi',
  description: 'Deskripzioa',
  subaccountChanged: 'Azpikontua aldatu da',
  processContinueTitle: 'Prozesu bat bidaltzeke daukazu',
  processContinueSubtitle: 'Prozesua berreskuratu edo berri bat hasi nahi al duzu?',
  newProcess: 'Prozesu berria',
  recover: 'Berreskuratu',
  newShipping: 'Bidalketa berria',
  toContinueFillFields: 'Jarraitzeko, behar bezala bete eremuak',
  passwordRequired: 'Pasahitza behar da',
  userRequired: 'Erabiltzailea behar da',
  fieldAlert: '%%fields%% eremua automatikoki beteko da hartzaileak ataletik',
  fieldAlertPlural: '%%fields%% eremuak automatikoki beteko dira hartzaileak ataletik',
  fieldNumberPhone: 'telefonoa',
  fieldCountryCode: 'herrialdearen kodea',
  fieldEmail: 'helbide elektronikoa',
  fieldName: 'izena',
  fieldSurname: 'abizenak',
  and: 'eta',
  certificate: 'Ziurtagiria',
  country: 'Herrialdea',
  continueEditing: 'Editatu bidalketa-datuak',
  second: 'segundo',
  seconds: 'segundo',
  nextRefreshAvailableAt: 'Hurrengo eguneratzea eskuragarri hemen',
  fastSearch: 'Bilaketa bizkorra',
  update: 'Eguneratu',
  process: 'Prozesua',
  transactionId: 'Transakzioaren ID',
  transactionClientId: 'Bezeroaren erreferentzia',
  createdDate: 'Sortua',
  updatedAt: 'Eguneratua',
  state: 'Egoera',
  noGroupAssigned: 'Ez da hautatu taldea',
  contact_country_code: 'Herrialdearen kodea',
  contact_email: 'Helbide elektronikoa',
  contact_id: 'Bezeroaren erreferentzia',
  contact_name: 'Izena',
  contact_phone: 'Telefonoa',
  contact_surname: 'Abizenak',
  contact_complete_name: 'Izen-abizenak',
  certificateValidDownload: 'Ziurtagiria behar bezala deskargatu da',
  certificateFailDownload: 'Ezin izan da deskargatu ziurtagiria',
  graphicBarValidDownload: 'Grafikoa behar bezala deskargatu da',
  graphicBarFailDownload: 'Ezin izan da deskargatu grafikoa',
  //events
  queued: 'Transakzioa prozesatzeke dago.',
  new_communication: 'Transakzioa ongi sortu da.',
  orchestrator_new_communications_communication_received: 'Transakzioa ongi prozesatu da.',
  commstracker_generate_url_generated: 'URL laburtuaren esteka sortu da.',
  commstracker_generate_url_click: 'Transakzioaren esteka ireki da.',
  communication_send_sms_sent: 'Testu-mezua hornitzailearen bidalketa zerbitzu gunean utzi da. Honek, aldi berean, bidalketa azken hartzaileari helaraziko dio.',
  communication_send_sms_not_sent: 'Testu honen mezua ezin izan da utzi hornitzailearen bidalketa zerbitzuaren zentroan.',
  communication_send_sms_delivered: 'Testu mezua entregatu zaio hornitzaileari.',
  communication_send_sms_not_delivered: 'Testu mezua ezin izan zaio entregatu hartzaileari.',
  generate_otp_code_otp_generated: 'Erabilera bakarreko gako bat (OTP) sortu da erabiltzeko.',
  generate_certificate_generated: 'Transakzioaren bukaeraren ziurtagiria sortu da, zeinak komunikazioaren edukia eta prozesuan gertatutako ekitaldiei lotutako frogak biltzen dituen.',
  communication_send_email_delivered: 'Mezua hartzailearen posta elektronikoko zerbitzuaren (ESP) hornitzaileari entregatu zaio.',
  communication_send_email_complaint: 'Mezuaren hartzaileak jasotako helbide elektronikoa jaso nahi ez dela markatu da.',
  communication_send_email_queued: 'Mezu elektronikoa itsatsi da.',
  communication_send_email_deferred: 'Mezu elektronikoa itsatsi da eta ahal bezain laster entregatuko da.',
  communication_send_email_bounce: 'Mezu elektronikoak errebotatu du.',
  communication_send_email_sent: 'Mezu elektronikoa hornitzailearen bidalketa-zerbitzuan utzi da. Honek, aldi berean, bidalketa azken hartzaileari helaraziko dio.',
  communication_send_email_sender_not_allowed: 'Mezu elektronikoa ezin izan da bidali, igorleak ez baitu baimenik.',
  communication_send_email_open: 'Hartzaileak ireki du mezu elektronikoa.',
  communication_send_email_click: 'Mezu elektronikoaren hartzaileak esteka bat sakatu du.',
  communication_send_email_block: 'Hartzailearen posta elektronikoa salbuespenen zerrendan dago, mezua ez da bidali.',
  communication_send_email_unsubscribe: 'Mezu elektronikoaren hartzaileak baja eman nahi du banaketa zerrendatik.',
  communication_send_email_spam: 'Mezu elektronikoa jaso nahi ez den mezu gisa identifikatu da.',
  communication_send_email_hard_blacklisted: 'Hartzailearen posta elektronikoa salbuespenen zerrendan dago, mezua ez da bidali.',
  communication_send_email_complaint_blacklisted: 'Hartzailearen helbide elektronikoa kexen zerrendan dago, ez da bidali mezua.',
  document_upload_s3_uploaded: 'Modu seguruan biltegiratu da transakzioaren bukaerako ziurtagiria, zeinak komunikazioaren edukia eta prozesuan gertatutako ekitaldiei lotutako frogak biltzen dituen.',
  communication_send_telegram_delivered: 'Testu mezua entregatu zaio hornitzaileari.',
  communication_send_telegram_not_delivered: 'Ezin izan da entregatu testu-mezua',
  web_framework_generated: 'Interfaze grafiko weba sortu da hartzaileak interaktuatu ahal izan dezan.',
  web_framework_solicitud_otp: 'Erabilera bakarreko gako bat (OTP) eskatu da.',
  web_framework_signable_document_loaded: 'Web interfazean kargatu da sinatzeko dokumentua.',
  web_framework_otp_simple_signature_ok: 'Erabilera bakarreko gako bitartez sinatu da dokumentua.',
  web_framework_commited: 'Prozesua osatu da web interfazean.',
  web_framework_document_signed: 'Dokumentazioa sinatu da.',
  web_framework_form_based_identification: 'Formulario bidez autentifikatzeko urratsa burutu da.',
  web_framework_certificate_based_identification: 'Ziurtagiri pertsonalarekin sartu da',
  web_framework_document_based_identification: 'Formularioan ongi identifikatu da.',
  web_framework_certificate_based_identification_failed: 'Ziurtagiri bitartez identifikatzeko prozesuak huts egin du.',
  web_framework_document_based_identification_failed: 'Dokumentu bitartez identifikatzeko prozesuak huts egin du.',
  web_framework_form_based_identification_failed: 'Formulario bitartez identifikatzeko prozesuak huts egin du.',
  web_framework_web_framework_expired: 'Transakzioak iraungi du.',
  web_framework_documentation_downloaded: 'Hartzaileak deskargatu du dokumentazioa.',
  web_framework_electronic_notificacion_rejected: 'Hartzaileak jakinarazpen elektronikoa baztertu du.',
  web_framework_gdpr_formulary_committed: 'Hartzaileak baimena emateko formularioa bete du.',
  web_framework_sepa_formulary_committed: 'Hartzaileak SEPA formularioa bete du.',
  web_framework_requested_otp_code: 'Erabilera bakarreko gako bat (OTP) eskatu da.',
  web_framework_entered_wrong_otp_code: 'Hartzaileak sartutako erabilera bakarreko gakoa (OTP) okerra da.',
  web_framework_entered_timedout_otp_code: 'Erabilera bakarreko gakoak iraungi du.',
  web_framework_entered_correct_otp_code: 'Hartzaileak sartutako erabilera bakarreko gakoa (OTP) zuzena da.',
  web_framework_electronic_notificacion_accepted: 'Hartzaileak jakinarazpen elektronikoa onartu du.',
  web_framework_signature_rejected: 'Hartzaileak sinadura elektronikoaren eskaera baztertu du.',
  web_framework_request_otp_code_too_early: 'Hartzaileak erabilera bakarreko gakoa (OTP) hura sortzeko behar den denbora tartearen auretik eskatu du',
  web_framework_capture_data_commited_no_response: 'Formularioa bidaltzen saiatu da erantzunetan daturik jarri gabe.',
  web_framework_capture_data_commited: 'Datuak ongi bidali dira',
  web_framework_document_previewed: 'Hartzaileak aurreikusi du dokumentazioa.',
  web_framework_document_simply_signed: 'Dokumentazioa elektronikoki sinatu da.',
  document_upload_alastria_uploaded: 'Bukaera ziurtagiriaren hash-a CertySignen nodora igo da Alastriaren Blockchain Sarean.',
  publish_downloadable_file_generated: 'Dokumentazioa sortu da web interfazean argitaratzeko.',
  publish_downloadable_file_open: 'Dokumentazioa ireki da web interfazean argitaratzeko.',
  document_sign_signed: 'Ziurtagiria sinatu da',
  rpc_callback_published: 'RPC deiaren itzulera argitaratu da',
  loop_add_added: 'Transakzioa gehitu da saiakeren begiztan.',
  loop_add_tick: 'Saiaketa bat gehiago egin da',
  loop_add_timeout: 'Saiakeren gehieneko muga agortu da',
  loop_cancel_canceled: 'Begizta ezereztu da',
  identification_create_identification_generated: 'Transakzioan erabiltzailea identifikatzeko prozesua sortu da.',
  identification_create_identification_ok: 'Ongi identifikatu da',
  test_load_processed: 'Karga-proba prozesatu da',
  instance_delete_deleted: 'Transakzioari dagokion instantzia ezabatu da.',
  unzip_file_extracted: 'Fitxategia deskonprimatu da.',
  communicalia_consent_accepted: 'Komunikazioaren hartzaileak baimena onartu du.',
  communicalia_consent_declined: 'Komunikazioaren hartzaileak baimena baztertu du.',
  communicalia_consent_no_data: 'Komunikazioaren hartzaileak ez du erantzun.',
  robinson_list_accepted: 'Hartzailea ez dago Robinsonen zerrendan',
  robinson_list_declined: 'Hartzailea ez dago alta emanda Robinson zerrendan',
  robinson_list_no_data: 'Hartzailea ez dago alta emanda Robinson zerrendan',
  publish_embeddable_file_generated: 'Atxikitako fitxategia sortu da web interfazerako.',
  document_upload_sftp_uploaded: 'Dokumentazioa SFTP bitartez kargatu da.',
  file_delete_deleted: 'Fitxategia ezabatu da.',
  disable_framework_web_framework_web_disabled: 'Transakzioari lotutako web interfazea desaktibatu da.',
  generate_onespan_transaction_transaction_create: 'Sinadura elektronikoko transakzioa sortu da OneSpanen',
  generate_onespan_transaction_transaction_create_error: 'Errore bat gertatu da sinadura elektronikoko transakzioa sortzean OneSpanen.',
  generate_onespan_transaction_transaction_complete: 'Sinadura elektronikoko transakzioa sortu da OneSpanen.',
  generate_onespan_transaction_transaction_expire: 'Sinadura elektronikoko transakzioak iraungi du OneSpanen.',
  generate_onespan_transaction_transaction_decline: 'OneSpaneko sinadura elektronikoko transakzioa baztertu da.',
  generate_onespan_transaction_email_bounce: 'OneSpanen sinadura elektronikoko transakzioari dagokion posta elektronikoko mezuak errebotatu du.',
  generate_onespan_transaction_transaction_opt_out: 'Hartzaileak transakzioa ez sinatzea aukeratu du.',
  generate_onespan_transaction_transaction_delete: 'OneSpanen sinadura elektronikoko transakzioa ezabatu da.',
  generate_onespan_transaction_transation_desactivate: 'OneSpanen sinadura elektronikoko transakzioa desaktibatu da.',
  generate_onespan_transaction_kba_failure: 'Transakzioa blokeatu da sarbidean edo prozesuan segurtasun arrazoiengatik.',
  generate_onespan_transaction_transaction_sent: 'OneSpanen sinadura elektronikoko transakzioa bidali da.',
  generate_onespan_transaction_transaction_sent_error: 'OneSpanen sinadura elektronikoko transakzioak huts egin du.',
  generate_onespan_transaction_package_trash: 'Transakzioa OneSpanen zakarrontzira eraman da',
  get_onespan_files_files_onespan_generated: 'OneSpanentzat beharrezkoak diren fitxategiak sortu dira',
  communication_send_postal_generated: 'Posta komunikazioaren bidalketa sortu da',
  communication_send_postal_sent_to_factory: 'Posta komunikazioko plataforman kargatu da bidalketa.',
  communication_send_postal_printed: 'Posta bidalketari lotutako dokumentazioa inprimatu da.',
  communication_send_postal_pre_admission_requested: 'Komunikazioaren bidalketa telematikoki ari da aurre-erregistratzen posta eragilearen webgunean.',
  communication_send_postal_pre_admitted: 'Komunikazioaren bidalketa telematikoki erregistratu du posta eragileak, gorde aurretik.',
  communication_send_postal_sent_to_logistic_operator: 'Komunikazioaren bidalketa posta eragilean uzten ari da.',
  communication_send_postal_on_the_way_to_logistic_operator: 'Bidalketa komunikazioaren hartzailetik gertuen dagoen posta bulegorako bidean da.',
  communication_send_postal_received_by_logistic_operator: 'Bidalketa komunikazioaren hartzailetik gertuen dagoen posta bulegoan dago.',
  communication_send_postal_not_received_by_logistic_operator: 'Komunikazioaren bidalketa ez da iritsi posta bulegora.',
  communication_send_postal_on_delivery: 'Posta komunikazioaren bidalketa bidean da.',
  communication_send_postal_delivered_to_recipient: 'Posta komunikazioaren bidalketa entregatu zaio hartzaileari.',
  communication_send_postal_returned_to_postal_office: 'Bidalketa posta bulegoan dago eta komunikazioaren hartzaileak abisua du postontzian.',
  communication_send_postal_delivered_into_postal_office: 'Posta komunikazioaren bidalketa posta bulegoan entregatu zaio hartzaileari.',
  communication_send_postal_generic_delivered: 'Posta komunikazioaren bidalketa entregatu zaio hartzaileari.',
  communication_send_postal_returned: 'Ezin izan da entregatu bidalketa eta posta komunikazioa jatorrira itzuli da.',
  communication_send_postal_canceled: 'Posta komunikazioaren bidalketa ezereztu da.',
  communication_send_postal_lost: 'Posta komunikazioaren bidalketa galdu da.',
  communication_send_postal_expired: 'Posta-komunikaziaoren bidalketa jatorrira itzuli da, hartzaileak ez baitu jaso ontzian utzitako oharraren ondorengo 15 egun balioduneko epean.',
  communication_send_postal_rejected: 'Hartzaileak posta-komunikazioaren bidalketa baztertu du.',
  communication_send_postal_stolen: 'Posta-komunikazioaren bidalketa ebatsi edo lapurtu egin dute.',
  communication_send_postal_outside_sicer: 'Komunikazioaren bidalketa ez dago erregistratuta posta-operadorean.',
  communication_send_postal_bad_postal_address: 'Bidalketaren posta-helbidea ez da zuzena.',
  communication_send_postal_passed_away: 'Posta-komunikazioaren bidalketaren hartzailea hil egin da.',
  communication_send_postal_other: 'Beste batzuk.',
  communication_send_postal_no_information: 'Posta-operadorean ez dago komunikazioaren bidalketaren informaziorik.',
  communication_send_postal_received_pee: 'Bidalketaren Entregaren Froga Elektronikoa (EFE) jaso da.',
  communication_send_postal_certificate_returned: 'Itzultze-ziurtagiria egin da posta-operadorean entregatzeko Ezintasunaren Frogarekin.',
  communication_send_postal_unknown: 'Komunikazioaren bidalketaren hartzailea ez dute ezagutzen adierazitako posta-helbidean.',
  zip_files_path_zipped: 'Dokumentu bat edo batzuk konprimatu dira',
  intromission_api_retries_muted: 'Transakzioa egiteko saiakerak desaktibatu dira',
  intromission_api_retries_unmuted: 'Transakzioa egiteko saiakerak aktibatu dira',
  intromission_api_instance_canceled: 'Transakzioa ezeztatu egin da',
  antivirus_filtered: 'Antibirusak transakzioa iragazi du, arriskutsuak izan daitezkeen baliabideak igo baitira.',
  //API messages
  user_operation_error_creating_user: 'Errore bat gertatu da erabiltzailea sortzean',
  user_operation_user_already_exists: 'Erabiltzailea dagoeneko badago',
  user_operation_user_created: 'Erabiltzailea behar bezala sortu da',
  user_operation_user_updated: 'Erabiltzailea behar bezala eguneratu da',
  user_operation_no_exists: 'Erabiltzailea ez da existitzen',
  user_operation_password_changed: 'Pasahitza eguneratu da',
  user_operation_pass_incorrect: 'Erabiltzailea eta pasahitzaren konbinazioa ez da zuzena',
  user_operation_password_expired: 'Pasahitza iraungi da',
  user_operation_logout: 'Saioa behar bezala itxi da',
  user_operation_blocked_user: 'Erabiltzailea blokeatuta dago',
  user_operation_wrong_subdomain: 'Erabiltzailearen izena edo pasahitza ez da zuzena',
  user_operation_max_pass_tries: 'Erabiltzailea blokeatu egin da, saiakera-kopurua gainditu duzu',
  user_operation_deleted: 'Erabiltzailea behar bezala ezabatu da',
  user_operation_error_deleted: 'Errorea egon da erabiltzailea ezabatzean',
  user_has_been_disabled: 'Erabiltzaile desaktibatua',
  user_operation_error_user_not_found: 'Adierazitako emaila zuzena bada, aurki mezu bat jasoko duzu pasahitza aldatzeko jarraibideekin',
  user_operation_remember_password_email: 'Adierazitako emaila zuzena bada, aurki mezu bat jasoko duzu pasahitza aldatzeko jarraibideekin',
  email_sent_to_support: 'Eskaera behar bezala bidali da',
  user_operation_error_change_password_min_days: 'Ezin da pasahitza aldatu, ez da gutxieneko egun-kopurua igaro eta',
  user_operation_error_passwords_mismatch: 'Pasahitzak ez datoz bat',
  user_operation_error_password_has_been_used_before: 'Pasahitz hau lehenago erabilita dago, aukeratu beste bat',
  user_or_group_no_exists: 'Erabiltzailea edo taldea ez da existitzen',
  user_error_already_active: 'Erabiltzailea aktibatuta dago',
  activation_email_resend: 'Aktibazio-emaila berriz bidali da',
  group_operation_error_creating_group: 'Errore bat egon da taldea sortzean',
  group_operation_group_already_exists: 'Taldea dagoeneko existitzen da',
  group_operation_group_created: 'Taldea behar bezala sortu da',
  group_operation_group_updated: 'Grupo :group_name: behar bezala eguneratu da',
  //Terms and conditions
  termsConditionsDPOTitle: 'Datuak Babesteko Ordezkaria',
  termsConditionsTitular: 'Titularra',
  termsConditionsNIF: 'I.F.Z.',
  termsConditionsDomicilioSocial: 'Egoitza soziala',
  termsConditionsDatosRegistrales: 'Erregistroko datuak',
  termsConditionsContacto: 'Kontaktua',
  termsConditionsDPOEmailTitle: 'Email Customer Comms',
  termsConditionsDPOEmail: 'Datuak Babesteko Ordezkariaren posta elektronikoa',
  termsConditions1: 'CUSTOMER COMMUNICATIONS TECKNALIA, S.L.-k (aurrerantzean CCOMMS), APP honen titular eta arduradun gisa duen izaera dela eta, uztailaren 11ko 34/2002 Legearen 10. artikuluan ezarriari jarraikiz, Informazioaren Gizarteko Zerbitzuena eta Merkataritza Elektronikoarena, erabiltzaileen esku jartzen du APP honi buruzko honako informazio orokor hau:',
  termsConditionsObjetoTitulo: 'XEDEA',
  termsConditionsObjetoParrafo1: 'Erabilera Baldintza Orokor hauek (aurrerantzean, \'EBO\') CCOMMSek erabiltzaileen esku jartzen duen CertySign Aplikazioaren (aurrerantzean, APPa) erabilera arautzen dute, egindako eskaera gauzatzeari ekiteko helburuarekin.',
  termsConditionsObjetoParrafo2: 'Hortaz, EBO hauek APPren bitartez egiten den sustapen-jardueran eta informazioaren banaketan aplikatuko dira, eta baita bertan eskainitako zerbitzuetan ere; hori dela eta, APPan zehar nabigatzeko edota APParen markoan zerbitzuak kontratatzeko jarduerak zuzenduko dituzte, eta azkeneko jarduera hauek, EBOez gain, aplikatu beharreko Kontratazioaren Baldintza Orokorren eta Baldintza Berezien (egongo balira) mende ere egongo dira.',
  termsConditionsUsuariosTitulo: 'ERABILTZAILEAK',
  termsConditionsUsuariosParrafo1: 'APPra sartzeak edota hura erabiltzeak erabiltzaile izaera ematen dio hori egin duenari, eta beraz, une horretatik aurrera eta inolako erreserbarik gabe, EBO hauek eta EBOak osatzen, aldatzen edo ordezkatzen dituzten Baldintza Bereziak onartzen ditu, APPren zerbitzu eta eduki jakin batzuei dagokienez; eta derrigortuta dago CCOMMSek une bakoitzean orrialdearen erabilerarekin lotuta ezartzen dituen murriztapenak errespetatzera.',
  termsConditionsAccesoAPPTitulo: 'APP-RAKO SARBIDEA',
  termsConditionsAccesoAPPParrafo1: 'APPrako sarbidea eta erabilera mugatuta dago, eta bertara sartzeko, ezinbestekoa izango da erabiltzailea aurrez erregistratzea.',
  termsConditionsUsoAPPTitulo: 'APP-REN ERABILERA',
  termsConditionsUsoAPPParrafo1: 'Erabiltzailea konprometitzen da APPa, bere zerbitzuak eta edukiak indarrean dagoen legedia, fede ona, orokorrean onartutako erabilerak eta ordena publikoa urratu gabe erabiltzera. Halaber, debekatuta dago APPa legez kontrako edo CCOMMSen edo hirugarrenen aurkako helburuekin erabiltzea, edo APPren ohiko funtzionamendua kaltetu edo galarazi dezakeen era batean erabiltzea.',
  termsConditionsResposabilidadTitulo: 'KALTE-GALEREN ONDORIOZKO ERABILTZAILEAREN ERANTZUKIZUNA',
  termsConditionsResposabilidadParrafo1: 'APPren erabileraren erantzukizuna erabiltzailearena bakarrik izango da. Erantzukizun hori erabiltzaileak edo hirugarren batek APPra edo bere edozein zerbitzutara sartzeko esleituta duen pasahitzaren edo antzekoen erabilerara zabalduko da.',
  termsConditionsResposabilidadParrafo2: 'Aurrekoari kalterik egin gabe, CCOMMSek beretzat gordetzen du edozein unetan eta aurrez jakinarazteko beharra izan gabe, APPrako sarbidea ukatzeko edo desgaitzeko eskubidea, EBO hauek edo CCOMMSek edo bere kolaboratzaileek ezarritako arauak hausten dituzten erabiltzeei, edo CCOMMSen edo bere kolaboratzaileen funtzionamendua, irudia, sinesgarritasuna edota izen ona asaldatu dezaketen erabiltzaileei.',
  termsConditionsResposabilidadParrafo3: 'Ildo honetan, CCOMMSek beretzat gordetzen du Erabiltzaile jakin batzuei APPrako sarbidea ezeztatzeko edo mugatzeko ahalmena, baldin eta CCOMMSen iritziz Erabilera Baldintza hauen, Legearen, CCOMMSek edo bere kolaboratzaileek ezarritako arauen aurkako edo CCOMMSen edo bere kolabroatzaileen funtzionamendua, irudia, sinesgarritasuna edota izen ona asaldatu dezaketen portaerak baldin badaude. Halaber, CCOMMSek APPa modu okerrean erabiltzetik eratorritako kalte-galeren erantzukizuna eskatu ahalko die Erabiltzaileei.',
  termsConditionsResposabilidadParrafo4: 'CCOMMSek ez du bermatzen erabiltzaileek APPa erabiltzen duten bitartean transmititzen dituzten edukien zilegitasuna, fidagarritasuna, erabilgarritasuna, egiatasuna eta zehaztasuna.',
  termsConditionsResposabilidadParrafo5: 'CCOMMSek ez du kontrolatzen erabiltzaileek APPrekin egiten duten erabilera, eta ez du bermatzen Baldintza Orokor hauek jarraikiz egingo dutenik ere.',
  termsConditionsResposabilidadParrafo6: 'CCOMMSek ez du kontrolatzen eta bermatzen hirugarrenek APPren bitartez eskainitako zerbitzuetan birus informatikorik egongo ez denik, sistema informatikoan (software eta hardware) edo bere sistema informatikoan biltegiratutako dokumentu eta fitxategi elektronikoetan nahasmenduak eragin ditzaketenak.',
  termsConditionsPropiedadIntelectualTitulo: 'JABETZA INTELEKTUALA',
  termsConditionsPropiedadIntelectualParrafo1: 'Materiala edo Edukiak Baldintza Orokorretan baimentzen dena ez den beste helburu baterako erabiltzea edo aldatzea, egile-eskubideak babesten dituzten "copyright"-aren nazioarteko legeen urraketa gisa hartuko da.',
  termsConditionsPropiedadIntelectualParrafo2: 'Edukiei dagokienez, debekatuta dago:',
  termsConditionsPropiedadIntelectualListaELemento1: 'Edozein modutan eta edozein helbururekin erreproduzitzea, kopiatzea, banatzea, aldatzea edo manipulatzea, baldin eta legezko titularren aurretiko baimen idatzia ez badago edota legeak baimentzen ez badu.',
  termsConditionsPropiedadIntelectualListaELemento2: 'CCOMMSen edo bere legezko titularren eskubideak edozein modutan urratzea.',
  termsConditionsPropiedadIntelectualListaELemento3: 'Hertsiki baimendutakoak ez diren merkataritza edo publizitate helburuetarako erabiltzea.',
  termsConditionsPropiedadIntelectualParrafo3: 'CCOMMSek bermatzen du, edukiak, jabetza intelektualekoak barne, ez direla legez kontrakoak eta ez dutela indarrean dagoen legedia urratzen. Ondorioz, APPko edukiek ez dute izaera xenofobo, pornografiko, diskriminatzaile edo arrazistarik izango, ezta indarkeria bultzatzen duenik ere.',
  termsConditionsPropiedadIntelectualParrafo4: 'Gainera, CCOMMSek komenigarritzat jotzen dituen legezko neurriak hartuko ditu Legearen edo moralaren aurkako portaera oro galarazteko.',
  termsConditionsModificacionUnilateralTitulo: 'ALDEBAKARREKO ALDAKETA',
  termsConditionsModificacionUnilateralParrafo1: 'CCOMMSek egokitzat hartzen duenean, alde bakarretik eta aurrez jakinarazi gabe aldatu ahal izango du APPren egitura eta diseinua, baita APPren zerbitzuak, edukiak eta sarbide eta erabilera baldintzak aldatu edo ezabatu ere.',
  termsConditionsExclusionGarantiasTitulo: 'BERMEEN ETA ERANTZUKIZUNAREN BAZTERKETA',
  termsConditionsExclusionGarantiasParrafo1: 'CCOMMSek ez du inolako bermerik ematen eta inola ere ez da honako jarduera hauen ondorioz sor daitezkeen kalte-galeren erantzule egiten:',
  termsConditionsExclusionGarantiasListaELemento1: 'Legea, morala, ohitura onak eta ordena publikoa urratzea, baita eskainitako produktuak edota zerbitzuak modu arduratsuan eta egokian ez erabiltzea ere, edo EBO hauetan edo aplikatu beharreko baldintzetan (aplikatu beharko balira) ezarritako helburuen aurka edo legearen aurka erabiltzea.',
  termsConditionsExclusionGarantiasListaELemento2: 'CCOMMSen APPko sarbide baten bitartez eskuratu edo hirugarrenei kontratatu daitezkeen zerbitzuak, ondasunak edo produktuak eskuratu edo kontratatzeagatik, bereziki, erosketa edo kontratazio-prozesua zuzenean hirugarrenaren app-ean egiten denean eta app-aren elementu grafikoak (Branding) dituen bereizgarriak edo \'frame\' bat agertu arren.',
  termsConditionsExclusionGarantiasListaELemento3: 'APPren edota bere zerbitzu edo edukien erabilgarritasun, mantentze eta funtzionamendu egokiaren falta.',
  termsConditionsExclusionGarantiasListaELemento4: 'APPren edota bere zerbitzu edo edukien erabilgarritasun, egokitzapen edo baliotasun falta, erabiltzaileen beharrak, jarduerak, emaitza zehatzak edo itxaropenak asebetetzeari dagokionez.',
  termsConditionsExclusionGarantiasListaELemento5: 'Edukietan birusak edo programa gaiztoak eta kaltegarriak egotea.',
  termsConditionsExclusionGarantiasListaELemento6: 'Erabiltzaileek edukiak jasotzea, lortzea, biltzea, hedatzea edo transmititzea.',
  termsConditionsExclusionGarantiasListaELemento7: 'Erabiltzaileei transmititutako edo haien esku jarritako edukien eta informazioen egiatasun, zehaztasun, fidagarritasun, egokitasun, gaurkotasun eta sakontasun falta, hirugarrenek edo erabiltzaileek APPren bitartez eskainitako informazioak eta zerbitzuak barne.',
  termsConditionsExclusionGarantiasListaELemento8: 'Hirugarrenek APPren bitartez erabiltzaileei eskainitako zerbitzuei dagokienez dituzten betebeharrak eta konpromisoak haustea.',
  termsConditionsExclusionGarantiasListaELemento9: 'APPren mende ez dauden eta CCOMMSek kudeatzen ez dituen eduki, fitxategi, informazio, publizitate, iritzi, kontzeptu eta irudiena. Ez da APPko edukiekin egiten den erabilera okerraren erantzule egiten ere, erantzukizun osoa edukiak atzitzen edo erabiltzen dituen pertsonarena baita.',
  termsConditionsExclusionGarantiasListaELemento10: 'Sarbide-gakoak behar dituzten APParen zerbitzuetako edukietan, erabiltzaileen sarbide-gakoen erabilera okerrarena, eta erabiltzaileek horiekin egiten duten erabilera okerretik, gakoak galtzetik edo ahaztetik eratorritako ondorioena, eta baimenik ez daukaten hirugarrenek egindako erabilera okerrarena.',
  termsConditionsExclusionGarantiasListaELemento11: 'Erabiltzaile baten ezintasuna edo erabiltzaileak hirugarren baten identitatea ordeztea.',
  termsConditionsExclusionGarantiasListaELemento12: 'Erabiltzaileek emandako edukiena.',
  termsConditionsProteccionDatosTitulo: 'DATUEN BABESA',
  termsConditionsProteccionDatosParrafo1: 'Datuen Babesari buruzko informazio zehatza kontsulta dezakezu gure orrialdean',
  termsConditionsLeyJurisdiccionTitulo: 'APLIKATU BEHARREKO LEGEA ETA JURISDIKZIOA',
  termsConditionsLeyJurisdiccionParrafo1: 'APP honetan barne hartzen diren Baldintza guztiak, Orokorrak eta Bereziak, Espainiako araudiari jarraitzen diote. Baldintza Orokorretatik eta Berezietatik eratorritako gatazka, eztabaida edo egoera guztiak Madrilgo hiriko Epaitegien eta Auzitegien mende egongo dira, eta alderdiek berariaz uko egingo diote bere foruari.',
  termsConditionsProteccionDatosTitle: 'Kontsultatu Datuen Babesari buruzko informazioa',
  termsConditionsProteccionDatos: 'Datuen Babesa',
  //Privacy Policies
  privacyPoliciesConditionsTitle: 'Mailteck & Customer Communications Tecknalia-en datuen babesa',
  privacyPoliciesConditionsParrafo1: '2016ko apirilaren 27ko 2016/679 (EB) Erregelamenduan, pertsona fisikoen babesarena, datu pertsonalen tratamenduari eta datu hauen zirkulazio askeari dagokionez (DBAO), eta abenduaren 5eko 3/2018 Lege Organikoan, Datu Pertsonalen Babesarena eta eskubide digitalen bermearena (DBLO-EDB), eta datuen babesari buruz indarrean dauden gainerako araudietan ezarriari jarraikiz, APPren erabiltzaileei jakinarazten zaie, euren datu pertsonalak tratatzeko adostasuna adierazi dutela, modu aske, argi eta nabarian, \'Datuak babesteko politika\' laukitxoa onartu duenean, eta euren datuak alta eman duten enpresaren arabera tratatuko direla, hau da, MAILTECK, S.A. enpresak edo CUSTOMER COMMUNICATIONS TECKNALIA, S.L. enpresak tratatuko dituela, zeintzuek elkarrekin, MAILCOMMS TALDEA izena izango duten.',
  privacyPoliciesConditionsResponsable1Title: '1. arduraduna',
  privacyPoliciesConditionsResponsable2Title: '2. arduraduna',
  privacyPoliciesConditionsFinalidadTitle: 'Helburua',
  privacyPoliciesConditionsLegitimacionTitle: 'Legitimazioa',
  privacyPoliciesConditionsDestinatariosTitle: 'Hartzaileak',
  privacyPoliciesConditionsDerechosTitle: 'Eskubideak',
  privacyPoliciesConditionsInformacionAdicionalTitle: 'Informazio gehigarria',
  privacyPoliciesConditionsPoliticaInformacion1Title: '1. informazioaren politika',
  privacyPoliciesConditionsPoliticaInformacion2Title: '2. informazioaren politika',
  privacyPoliciesConditionsIdentidadTitle: 'Identitatea',
  privacyPoliciesConditionsNIFTitle: 'IFZ',
  privacyPoliciesConditionsDireccionTitle: 'Helbidea',
  privacyPoliciesConditionsTelefonoTitle: 'Telefonoa',
  privacyPoliciesConditionsCorreoTitle: 'Posta',
  privacyPoliciesConditionsDPDTitle: 'Harremana DBOrekin',
  privacyPoliciesConditionsDPDCustomerCommsTitle: 'Customer Comms-eko Datuak Babesteko Ordezkaria',
  privacyPoliciesConditionsDPDMailteckTitle: 'Mailteck-eko Datuak Babesteko Ordezkaria',
  privacyPoliciesConditionsFinalidadIntro: 'Kontratatutako zerbitzuak kudeatzea eta tramitatzea',
  privacyPoliciesConditionsFinalidadParrafo1: 'MAILCOMMS TALDEAk zuk edo zure enpresak sinatutako kontratu baten betearazpenaren parte gisa tratatuko ditu emandako datuak, bertan hitzartutako zerbitzuak kudeatzeko eta tramitatzeko helburuarekin, eta Certysign aplikazioaren euskarria emateko helburuarekin.',
  privacyPoliciesConditionsFinalidadParrafo2: 'MAILCOMMS TALDEAk aurrez aipatutako helburuetarako soilik tratatuko ditu emandako datuak, eta aurrerago ez dira tratatuko helburu horiekin bat ez datorren modu batean. Emandako datu pertsonalak kontratu-harremanak irauten duen bitartean gordeko dira.',
  privacyPoliciesConditionsLegitimacionIntro: 'Kontratuaren betearazpena',
  privacyPoliciesConditionsLegitimacionParrafo1: 'Ezarritako kanalen bitartez, zuk edo zure enpresak datuak eman dituzue Certysign aplikaziorako sarbidea eman ahal izateko, sinatutako zerbitzu-eskaintzaren kontratuaren ondorio gisa.',
  privacyPoliciesConditionsDestinatariosIntro: 'Ez zaizkie hirugarrenei lagatzen',
  privacyPoliciesConditionsDestinatariosParrafo1: 'Eskakizun legal baten bidez, arau batek hala agintzen duenean.',
  privacyPoliciesConditionsDerechosIntro: 'Zure eskubideak legeak aurreikusitako moduan eta epeetan erabil ditzakezu.',
  privacyPoliciesConditionsDerechosElem1ListaTitulo: 'Baieztapen orokorra:',
  privacyPoliciesConditionsDerechosElem1ListaDescripcion: 'Pertsona guztiek daukate MAILCOMMS TALDEA beraien datu pertsonalak tratatzen ari den jakiteko eskubidea.',
  privacyPoliciesConditionsDerechosElem2ListaTitulo: 'AZEU eskubideak:',
  privacyPoliciesConditionsDerechosElem2ListaDescripcion: 'Pertsona interesatuek euren datu pertsonalak atzitzeko, okerrak diren datuak zuzentzea eskatzeko, eta hala badagokio, ezabatzea eskatzeko eskubidea daukate, besteak beste, datuak bildu ziren helbururako dagoeneko beharrezkoak ez direnean.',
  privacyPoliciesConditionsDerechosElem3ListaTitulo: 'Tratamendua mugatzea:',
  privacyPoliciesConditionsDerechosElem3ListaDescripcion: 'Egoera batzuetan, interesatuek euren datuen tratamendua mugatzea eskatu ahal izango dute, eta kasu horretan, erreklamazioak egiteko edo horietatik defendatzeko soilik gordeko ditugu.',
  privacyPoliciesConditionsDerechosElem4ListaTitulo: 'Tratamenduari uko egitea:',
  privacyPoliciesConditionsDerechosElem4ListaDescripcion: 'Egoera batzuetan, eta bere egoera bereziarekin lotutako arrazoiak direla eta, interesatuek euren datuen tratamenduari uko egin diezaiokete. Ondorioz, MAILCOMMS TALDEAk datuak tratatzeari utziko dio, ezinbesteko legezko arrazoiak baldin badaude edo balizko erreklamazioetatik defendatzeko baldin bada izan ezik.',
  privacyPoliciesConditionsDerechosElem5ListaTitulo: 'Datuen eramangarritasuna:',
  privacyPoliciesConditionsDerechosElem5ListaDescripcion: 'Egoera batzuetan, eta bere egoera bereziarekin lotutako arrazoiak direla eta, interesatuek harremanetarako inprimakian emandako datu pertsonalak jasotzeko eskaera egin ahal izango dute, ohikoa den eta makinak irakur dezakeen formatu egituratu batean, eta datu horiek tratamenduaren beste arduradun bati transmititzeko gaitasuna ere izango dute, inolako eragozpenik gabe.',
  privacyPoliciesConditionsDerechosElem6ListaTitulo: 'Erakunde publiko baten aurrean erreklamazioa egitea:',
  privacyPoliciesConditionsDerechosElem6ListaDescripcion: 'Datu pertsonalak babesteko duzun eskubidea urratu dela uste baduzu, erreklamazio bat aurkeztu ahal izango duzu Datuak Babesteko Espainiako Agentzian',
  privacyPoliciesConditionsDerechosAEPDTitle: 'DBEA',
  privacyPoliciesConditionsDerechosElem7ListaTitulo: 'Eskubideak erabiltzeko kanala:',
  privacyPoliciesConditionsDerechosElem7ListaDescripcion: 'Interesatuek araudiak aitortzen dizkien eskubideak erabili ahal izango dituzte, arduradun bakoitzaren aurrean eta horien aurka, "Arduraduna" atalean DPO gisa gaitutako helbideetan.',
  privacyPoliciesConditionsInformacionAdicionalIntro: 'Datuen Babesari buruzko informazio gehiago.',
  privacyPoliciesConditionsInformacionAdicionalLista1: 'Datu pertsonalak MAILCOMMS TALDEKO enpesek zuk edo zure enpresak kontratatutako zerbitzuak kudeatu ahal izateko ezarri dituzten kanalen bidez lortu dira.',
  privacyPoliciesConditionsInformacionAdicionalLista2: 'Tratatuko diren datuen kategoriak kontratatutako zerbitzu bakoitza garatzeko emandako datuak izango dira.',
  privacyPoliciesConditionsInformacionAdicionalLista3: 'MAILCOMMS TALDEAk ez ditu bereziki babestutako datuak tratatzen.',
  privacyPoliciesConditionsPoliticaInformacionIntro: 'MailTecK, S.A. ISO 27001 arauan oinarritutako Informazioaren Segurtasun eta Pribatutasunaren Politika -> Gehiago irakurri.',
  privacyPoliciesConditionsPoliticaInformacio2nIntro: 'Customer Communication Tecknalia, S.L. ISO 27001 arauan oinarritutako Informazioaren Segurtasun eta Pribatutasunaren Politika -> Gehiago irakurri.',
  privacyPoliciesConditionsPoliticaInformacionTituloH: 'I. ERANSKINA: INFORMAZIOAREN SEGURTASUN ETA PRIBATUTASUNAREN KUDEAKETA-SISTEMAREN POLITIKA.',
  privacyPoliciesConditionsPoliticaInformacionP1: 'Informazioaren Segurtasun eta Pribatutasun Politika honek Zuzendaritzaren konpromisoa erakusten du, erakundeari indarrean dagoen araudia betetzea eta informazioaren eta sistemen zuzentasuna, konfidentzialtasuna eta erabilgarritasuna bermatzea ahalbidetzen duten baliabideak, erantzukizunak eta prozedurak eskaintzen baitizkio, eta hori erabakigarria da gure erakundearen eta gure bezeroen segurtasuna, pribatutasuna eta jarraitutasuna bermatzeko.',
  privacyPoliciesConditionsPoliticaInformacionP2: 'Mailteck Taldeak, gure bezeroei dagokien segurtasuna eta pribatutasuna balio handiko baliabidea dela jakinik, Informazioaren Segurtasun eta Pribatutasunaren Kudeaketa-sistema bat ezarri du, ISO/IEC 27001:2013 eta ISO/IEC 27701:2019 arauetako baldintzekin bat etorriz, beharrezkoa den Segurtasun-maila, eskainitako zerbitzuen Jarraitutasuna eta izaera pertsonaleko datuen tratamendua Betetzen dela bermatzeko, hala, kudeaketa honetatik eratorritako arriskuak minimizatuz.',
  privacyPoliciesConditionsPoliticaInformacionCCTP2: 'Custommer Comms-ek, gure bezeroei dagokien segurtasuna eta pribatutasuna balio handiko baliabidea dela jakinik, Informazioaren Segurtasun eta Pribatutasunaren Kudeaketa-sistema bat ezarri du, ISO/IEC 27001:2013 eta ISO/IEC 27701:2019 arauetako baldintzekin bat etorriz, beharrezkoa den Segurtasun-maila, eskainitako zerbitzuen Jarraitutasuna eta izaera pertsonaleko datuen tratamendua Betetzen dela bermatzeko, hala, kudeaketa honetatik eratorritako arriskuak minimizatuz.',
  privacyPoliciesConditionsPoliticaInformacionP3: 'Segurtasun eta Pribatutasun Politikaren helburua informazio-baliabideak barneko zein kanpoko mehatxuen aurrean (nahita edo nahigabekoak) babesteko beharrezkoa den jarduketa-esparrua zehaztea da, informazioaren konfidentzialtasuna, zuzentasuna eta erabilgarritasuna betetzen direla ziurtatzeko askoarekin.',
  privacyPoliciesConditionsPoliticaInformacionP4: 'Informazioaren Segurtasun eta Pribatutasunaren Kudeaketa-sistemaren eraginkortasuna eta aplikazioa Informazioaren Segurtasun eta Pribatutasunaren Batzordearen erantzukizuna da, zeina Segurtasun eta Pribatutasun Politika hau onartzearen, hedatzearen eta betetzearen arduraduna den. Bere izenean eta ordezkaritzan, Informazioaren Segurtasun eta Pribatutasunaren Kudeaketa-sistemaren Arduradun bat izendatu da, zeinak aginpide nahikoa duen Informazioaren Segurtasun eta Pribatutasunaren Kudeaketa-sisteman paper aktibo bat gauzatzeko, horren ezarpena, garapena eta mantentzea gainbegiratuz.',
  privacyPoliciesConditionsPoliticaInformacionP5: 'Gainera, eta indarrean dagoen datuen babesaren araudia betearazteko, Datuak Babesteko Ordezkari bat izendatu da, izaera pertsonaleko datuen segurtasuna eta pribatutasuna bermatzeko helburuarekin, bereziki, konpainiaren jarduera kontuan hartuta.',
  privacyPoliciesConditionsPoliticaInformacionP6: 'Informazioaren Segurtasun eta Pribatutasun Batzordeak Informazioaren Segurtasun eta Pribatutasunaren Kudeaketa-sisteman arriskuak aztertzeko erabilitako metodologia garatu eta onartuko du.',
  privacyPoliciesConditionsPoliticaInformacionP7: 'Informazioaren Segurtasun eta Pribatutasunaren Kudeaketa-sistemako baldintze, zuenean ala zeharka, pertsona baten jarduerari eragiten badiote, aipatu pertsona derrigortuta dago Segurtasun eta Pribatutasun Politika hertsiki betetzera.',
  privacyPoliciesConditionsPoliticaInformacionP8: 'Mailteck Taldean, segurtasun eta pribatutasun orokorraren eta segurtasun informatikoaren arloan aplikatu beharreko araudia betetzeko beharrezko neurri guztiak ezarriko dira, politika informatikoari, eraikinen eta instalazioen segurtasunari eta langileen eta Mailteck Taldearekin lotutako hirugarrenen portaerari dagokionez, sistema informatikoen erabileran eta datu pertsonalen tratamenduan. Arauak, prozedurak eta kontrolak aplikatzearen bidez informazioaren segurtasuna eta pribatutasuna bermatzeko beharrezko neurriek, informazioaren konfidentzialtasuna, zuzentasuna eta erabilgarritasuna bermatu beharko dute, honetarako ezinbestekoak:',
  privacyPoliciesConditionsPoliticaInformacionCCTP8: 'Custommer Comms-en, segurtasun eta pribatutasun orokorraren eta segurtasun informatikoaren arloan aplikatu beharreko araudia betetzeko beharrezko neurri guztiak ezarriko dira, politika informatikoari, eraikinen eta instalazioen segurtasunari eta langileen eta Custommer Comms-ekin lotutako hirugarrenen portaerari dagokionez, sistema informatikoen erabileran eta datu pertsonalen tratamenduan. Arauak, prozedurak eta kontrolak aplikatzearen bidez informazioaren segurtasuna eta pribatutasuna bermatzeko beharrezko neurriek, informazioaren konfidentzialtasuna, zuzentasuna eta erabilgarritasuna bermatu beharko dute, honetarako ezinbestekoak:',
  privacyPoliciesConditionsPoliticaInformacionLista1: 'Pribatutasunaren eta informazio-sistemen arloan indarrean dagoen legedia, arautegia eta araudia, eta datuen babesaren arloko kontratuaren baldintza guztiak betetzea, baita erakundearen etengabeko hobekuntza lortzeko egokitzat hartzen direnak ere.',
  privacyPoliciesConditionsPoliticaInformacionLista2: 'Mailteck Taldeak kudeatutako datuen konfidentzialtasuna ziurtatzea.',
  privacyPoliciesConditionsPoliticaInformacionCCTLista2: 'Custommer Comms-ek kudeatutako datuen konfidentzialtasuna ziurtatzea.',
  privacyPoliciesConditionsPoliticaInformacionLista3: 'Informazio-sistemen erabilgarritasuna ziurtatzea, bai bezeroei eskainitako zerbitzuetan, bai barne-kudeaketan.',
  privacyPoliciesConditionsPoliticaInformacionLista4: 'Larrialdi-egoeren aurrean erantzuteko gaitasuna ziurtatzea, zerbitzu erabakigarrien funtzionamendua ahal bezain laster berrezarriz.',
  privacyPoliciesConditionsPoliticaInformacionLista5: 'Informazioaren nahasmendu desegokiak saihestea.',
  privacyPoliciesConditionsPoliticaInformacionLista6: 'Informazioaren segurtasun eta pribatutasunaren kontzientziazioa eta prestakuntza sustatzea.',
  privacyPoliciesConditionsPoliticaInformacionLista7: 'Informazioaren segurtasun eta pribatutasunaren kudeaketa-sistemaren etengabeko hobekuntzaren lorpena sustatzea eta horretan parte hartzea.',
  cookiesPoliciesIntroText: 'Web orri honek cookieak erabiltzen ditu webgunean nabigatzen ari zaren bitartean zure esperientzia hobetzeko. Ezarpen hauek edozein unetan alda ditzakezu. Beharrezkotzat sailkatutako cookieak zure nabigatzailean biltegiratuko dira eta funtsezkoak dira webgunearen oinarrizko funtzionaltasunek funtzionatzeko. Hirugarrenen cookieak zure nabigatzailean biltegiratuko dira, soilik zure baimenarekin, eta webgune hau nola erabiltzen den aztertzen eta ulertzen laguntzen digute. Cookie hauek ez jasotzea erabakitzeko aukera daukazu, baina cookie hauek nahita baztertzeak berekin ekar dezake funtzio batzuek erabilgarri egoteari uztea. Cookieak ezabatzeari buruzko informazioa lortzeko, kontsultatu zure nabigatzaileko laguntzaren funtzioa.',
  cookiesPoliciesNecessaryCookies: 'Beharrezko cookieak',
  cookiesPoliciesNecessaryCookiesText: 'Mota honetako cookieak erabat funtsezkoak dira webguneak behar bezala funtziona dezan. Kategoria honek webgunearen oinarrizko funtzionaltasunak eta segurtasun-ezaugarriak bermatzen dituzten cookieak soilik barne hartzen ditu, eta ez dute webguneko erabiltzaileei buruzko inolako informazio pertsonalik biltzen.',
  cookiesPoliciesUnnecessaryCookies: 'Beharrezkoak ez diren cookieak',
  cookiesPoliciesUnnecessaryCookiesText: 'Webguneak funtzionatu ahal izateko bereziki beharrezkoak ez diren cookiei, zehazki erabiltzailearen datu pertsonalak biltzeko erabiltzen direnei, azterketen, iragarkien eta beste eduki batzuen bitartez, beharrezkoak ez diren cookieak esaten zaie. Derrigorrezkoa da erabiltzailearen baimena izatea cookie hauek bere webgunean egikaritu aurretik.',
  cookiesPoliciesGoogleAnalyticsCookies: 'Google Analytics-en cookieak',
  cookiesPoliciesGoogleAnalyticsCookiesText: 'Mota honetako cookiei buruzko informazio gehiago izateko, "Google Analytics-en cookien erabilera webguneetan" dokumentua kontsulta dezakezu.',
  cookiesPoliciesGoogleAnalyticsCheckboxText: 'Cookieak aktibo mantendu',
  cookiesNotificationBarText: 'Gure eta hirugarrenen cookieak erabiltzen ditugu webguneko bisitak neurtzeko, funtzionaltasuna bermatzeko eta bertako nabigazioa aztertzeko. Jarraian, cookieak onar ditzakezu edo cookien erabilera konfiguratu/baztertu dezakezu. Webaren titulartasunari buruzko informazio gehiago lor dezakezu, zeina cookien eta zure harremanetarako datuen tratamenduaren arduraduna den, hemen',
  cookiesNotificationBarConfig: 'Konfiguratu',
  cookiesPoliciesModalIntro: 'Sarrera',
  cookiesPoliciesModalIntroP1: 'Webgune honek nabigatzen duzun bitartean informazioa biltegiratzen eta berreskuratzen duten cookieak edota antzeko teknologiak erabiltzen ditu, erabiltzailearen esperientzia hobetzeko eta optimizatzeko.',
  cookiesPoliciesModalIntroP2: 'Orokorrean, teknologia hauek helburu oso ezberdinetarako balio dezakete, esaterako, erabiltzaile bat ezagutzeko, nabigazio-ohiturei buruzko informazioa lortzeko, edo edukia erakusteko modua pertsonalizatzeko.',
  cookiesPoliciesModalIntroP3: '13/2012 Errege Lege-dekretuarekin eta Informazioaren Gizartearen eta Merkataritza Elektronikoaren Zerbitzuen Legearen 22.2 artikuluarekin bat etorriz, Politika honen bidez, alde batetik, CUSTOMER COMMUNICATIONS TECKNALIA, S.L.-k biltzen dituen cookie ezberdinen berri ematen zaizu, bere nahiz hirugarrenen komunikazio-bide eta -kanalen bitartez jasotzen dituenak, informatzeko beharretik salbuetsita daudenak ala ez, eta beste alde batetik, horiek ukatu, ahalbidetu edo blokeatzeko erabil ditzakezun bide ezberdinak azaltzen zaizkizu.',
  cookiesPoliciesModalIntroP4: 'Jarraian, honi buruzko informazioa zehazten da: "Cookieak" zer diren, webgune honek zein tipologia erabiltzen duen, nabigatzaile batean nola desaktiba daitezkeen eta zehazki hirugarrenen Cookien instalazioa nola blokea daitekeen.',
  cookiesPoliciesModalResponsibility: 'Zein da webgune honetako cookien erabileraren arduraduna?',
  cookiesPoliciesModalResponsibilityP1: 'Cookien erabileraren arduraduna CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (aurrerantzean, CCOMMS) da, B-86414000 I.F.Z duena eta egoitza soziala Leganesen (Madril) duena, Recomba hiribidea 14 helbidean; eta Madrilgo Merkataritza Erregistroan behar bezala inskribatuta dago, 29.801 Liburukian, 98. folioan, 8. atalean, M-536210 orrian.',
  cookiesPoliciesModalResponsibilityP2: 'Datuak Babesteko Ordezkariaren posta elektronikoa.',
  cookiesPoliciesModalWhatIsCookie: 'Zer da cookie bat?',
  cookiesPoliciesModalWhatIsCookieP1: 'Cookie bat datu bat, fitxategi bat edo datuak biltegiratzeko eta berreskuratzeko gailu bat da (DBBG), web nabigatzaile batek erabiltzaile baten gailuan (Smartphone, tableta edo telebista) automatikoki biltegiratzen dituen datuak, alegia, web orrietan edo sare sozialetako kontuetan sartzean edo posta elektroniko bat jasotzean.',
  cookiesPoliciesModalWhatIsCookieP2: 'Cookiek aipatu baliabide digitalekin eta horietara sartzeko tresna edo gailuarekin egindako erabilerari buruzko datuak modu automatikoan biltegiratzea eta berreskuratzea ahalbidetzen dute.',
  cookiesPoliciesModalWhatIsCookieP3: 'Cookie bat erabiltzailearen ordenagailuan biltegiratzen diren datuak soilik dira, hortaz, ez dira software bat edo kode-zatiak, datu soilak dira. Beraz, hasiera batean, cookiek ezin dute birus bat transmititu edo egikaritu, ezta malwareak instalatu ere, esaterako, troiarrak edo espioitza-programak.',
  cookiesPoliciesModalCookieTypes: 'Zein cookie mota daude web orrian eta zein helburu dute?',
  cookiesPoliciesModalCookiesInstall: 'Zein cookie instala daitezke CertySign-en web orrian?',
  cookiesPoliciesModalCookiesInstallP1: 'CertySign-eko web-sarbideak hurrengo taulan adierazten diren Cookieak, bereak edota hirugarrenenak, instala daitezkeela dakar berekin:',
  cookiesPoliciesModalBrowserConfigCookies: 'Nola konfiguratu cookieak nabigatzailean',
  cookiesPoliciesModalBrowserConfigCookiesP1: 'Cookierik ez jasotzeko edo cookien finkapenaren berri izateko aukera daukazu, zure nabigatzailea ondore horretarako konfiguratzen baduzu; cookie guztiak, Flash-enak izan ezik, zure nabigatzailetik kudeatu daitezke, "Aukerak" edo "Lehentasunak" atalean.',
  cookiesPoliciesModalBrowserConfigCookiesP2: 'Cookieak guztiz desaktiba ditzakezu, era horretan, nabigatzaileak ez du bat ere instalatzen utziko (web orriko funtzionaltasun guztiak erabiltzea ezinezkoa eginez), edo soilik web jakin batzuetatik datozen cookieak desaktiba ditzakezu, eta hala, zure datuen pribatutasuna errespetatzen duten webguneetako funtzionaltasun aurreratuez gozatu ahal izango duzu, eta errespetatzen ez dutenak baztertu.',
  cookiesPoliciesModalBrowserConfigCookiesP3: 'Hurrengo esteka hauetan, euskarri-orrialde nagusietarako sarbidea ematen dizugu:',
  cookiesPoliciesModalBrowserConfigCookiesP4: 'Halaber, Erabiltzaileak cookie jakin batzuen erabilerari uko egin diezaioke, honako zerbitzu hauen bidez:',
  cookiesPoliciesModalBrowserConfigCookiesLI1: 'Cookieak blokeatzea Chrome-n',
  cookiesPoliciesModalBrowserConfigCookiesLI2: 'Cookieak blokeatzea Safari-n',
  cookiesPoliciesModalBrowserConfigCookiesLI3: 'Cookieak blokeatzea Firefox-en',
  cookiesPoliciesModalBrowserConfigCookiesLI4: 'Cookieak blokeatzea Internet Explorer-en',
  cookiesPoliciesModalBrowserConfigCookiesLI5: 'http://www.criteo.com/deactivate-criteo-banners/',
  cookiesPoliciesModalBrowserConfigCookiesLI6: 'http://youronlinechoices.eu/',
  cookiesPoliciesModalBrowserConfigCookiesLI7: 'http://www.networkadvertising.org/choices/',
  cookiesPoliciesModalUnavailableCookies: 'Zer gertatzen da cookieak desgaitzen badira?',
  cookiesPoliciesModalUnavailableCookiesP1: 'Cookieak baztertzen badituzu, gure Webgunean nabigatzen jarraitu ahal izango duzu, baina zerbitzu batzuen erabilera mugatuta egon daiteke eta, beraz, baliteke zure esperientzia horren pozgarria ez izatea.',
  cookiesPoliciesModalUnavailableCookiesP2: 'Politika honetan deskribatutako cookieak baztertzeak, blokeatzeak edo ez baimentzeak webgunean erreproduzitzen diren edukietan zehar behar bezala ez nabigatzea eragin dezake.',
  cookiesPoliciesModalAcceptCookies: 'Cookieen politika onartzea',
  cookiesPoliciesModalAcceptCookiesP1: '"ONARTU COOKIEAK" botoia sakatzen baduzu, cookien erabilera onartzen duzula adierazten duzu.',
  cookiesPoliciesModalAcceptCookiesP2: 'Hala ere, saioa hasten den bakoitzean, atariko edozein orrialderen beheko aldean Cookieen politika erakutsiko da, jakinaren gainean egon zaitezen.',
  cookiesPoliciesModalUpgradeCookies: 'Gure cookien politika eguneratzen al dugu?',
  cookiesPoliciesModalUpgradeCookiesP1: 'Baliteke, Cookieen Politika honetan emandako informazioak aldaketa batzuk jasatea, cookien tratamenduaren politikan izandako eguneraketak edo aldaketak direla eta, halaber, Datuen Babesaren Espainiako Agentziak politika aldaraziko luketen argibideak edota gomendioak eman ditzake.',
  cookiesPoliciesModalUpgradeCookiesP2: 'Hori dela eta, Webgunean sartzen zaren bakoitzean politika hau berrikustea gomendatzen dizugu, cookieak nola eta zertarako erabiltzen ditugun behar bezala jakin dezazun.',
  cookiesPoliciesModalRecipientCookies: 'Nortzuk dira informazioaren hartzaileak eta nola eragiten dio cookien erabilerak datu pertsonalen babesari?',
  cookiesPoliciesModalRecipientCookiesP1: 'Zure datuei buruzko informazio gehiago izateko, kontsultatu ',
  cookiesPoliciesModalRecipientCookiesLinkCCOMMS: 'Datuak Babesteko Politika',
  cookiesPoliciesModalContactInfoCookies: 'Harremanetarako informazioa',
  cookiesPoliciesModalContactInfoCookiesP1: 'Cookiei buruzko edozein zalantza, azalpen edota iradokizunetarako, jarri gurekin harremanetan posta elektroniko honen bitartez ',
  cookiesPoliciesModalDatatableCookieType: 'Cookie mota',
  cookiesPoliciesModalDatatableTreatmentTerm: 'Tratamenduaren epea',
  cookiesPoliciesModalDatatableTypeAnalytics: 'Analitikoak',
  cookiesPoliciesModalDatatableTypeSelf: 'Propioak',
  cookiesPoliciesModalDatatableTypeAnalyticsD: 'Erabiltzaile batek Zerbitzu bat bisitatzen duen bakoitzean, kanpoko hornitzaile baten tresna batek Cookie analitiko bat sortzen du Erabiltzailearen ordenagailuan. Cookie honek, bisitan bakarrik sortzen denak, CCOMMSi hurrengo bisitetan bisitaria era anonimoan identifikatzeko balioko dio.',
  cookiesPoliciesModalDatatableTypeAnalyticsF: 'Erabiltzaile nabigatzaileen identifikazio anonimoa ahalbidetzea, "Cookiearen" bitartez (nabigatzaileak eta gailuak identifikatzen ditu, ez pertsonak), eta beraz, bisitari-kopuruaren gutxi gorabeherako zenbaketa eta denboran zehar izandako joera. Bisita gehien dituzten eta Erabiltzaileentzat erakargarrienak diren edukiak modu anonimoan identifikatzea. Sartzen ari den Erabiltzailea berria den ala bisita errepikatzen ari den jakitea. Erabiltzaileak COOMMSen erregistratzea erabakitzen ez badu, "Cookiea" inoiz ez da erabiltzailea zuzenean identifikatzea ahalbidetuko duten datu pertsonalekin lotuta egongo. Cookie horiek estatistika-helburuekin soilik erabiliko dira, Erabiltzaileen webguneko esperientzia optimizatzen eta hobetzen lagunduko dutenak.',
  cookiesPoliciesModalDatatableTypeAnalyticsT: 'Iraunkorrak',
  cookiesPoliciesModalDatatableTypeSelfD: 'Mota honetako Cookiek Erabiltzaile anonimo bakoitzari CCOMMSen Zerbitzuetan erakutsitako informazioa zabaltzea ahalbidetzen dute.',
  cookiesPoliciesModalDatatableTypeSelfF: 'Besteak beste, atal ezberdinen bistaratzearen iraupena edo maiztasuna, horiekiko elkarrekintza, edo Erabiltzailearen nabigazio- edota portaera-ohiturak biltegiratzen dira.',
  cookiesPoliciesModalDatatableTypeSelfT: 'Iraunkorrak',
  eventInfo: 'Ekitaldiaren informazioa',
  manual: 'Eskuliburua',
  guia: 'Gida',
  supportLoggedPhonePrefferedMessage: '(Saioa hasitako erabiltzailearen euskarria - Telefonoa nahiago)',
  supportLoggedNotPhoneMessage: 'Ez du telefonoa markatzen',
  supportLoggedUserMessage: '(Saioa hasitako erabiltzailearen euskarria)',
  supportNotLoggedUserWebMessage: '(Saio hasiera - Web bidezko euskarria) Erabiltzaileak pasahitza berreskuratzeko laguntza eskatzen du',
  supportNotLoggedUserNotAvailableMessage: 'Ez dago erabilgarri',
  supportNotLoggedUserC2CMessage: '(Saio hasiera - C2C bidezko euskarria) Erabiltzaileak telefono bidezko laguntza eskatzen du pasahitza berreskuratzeko',
  processA4NotSupported: 'Dokumentuak A4 izan behar dira, ez ditu 389 orrialde edo 15mb gainditu behar.',
  cookiesGAMessage: 'Barne-neurketa Google Analytics-ekin. Cookie hauek identifikadore bakar bat biltegiratzen dute, webgunea bisitatzen duen hurrengo aldietan erabiltzailea ezagutzeko.',
  cookiesGAFGenericMessage: 'Barne-neurketa Google Analytics-ekin',
  cookiesCookieMessage: 'Cookiea',
  cookiesNecessaryCookieDescription: 'Cookie hau beharrezkoa da eta erabiltzailearen lehentasunak ezartzeko erabiltzen da, cookien politikari dagokionez.',
  documentHtmlRule: 'Onartutako fitxategi mota - HTML',
  rulesFilesSizePostal: 'Fitxategiak ez du pisua izan behar 15Mb',
  rulesFilesSizeEmail: 'Fitxategiak ez du pisua izan behar 14Mb',
  rulesFilesSizeMultiple: 'Fitxategiaren gehieneko tamaina guztira da 14Mb',
  rulesFilesSizeHtml: 'Fitxategiak ez du pisua izan behar {fileSize}Mb',
  rulesFilesExtensionHtml: 'Fitxategiak HTML bat izan behar du',
  group_cannot_be_empty: ':group_name: taldea ezin da hutsik egon',
  language: 'Hizkuntza',
  defaultLanguage: 'Hizkuntza lehenetsia',
  transactionDownloadPee: 'Ikus PEE',
  transactionTracking: 'Bidalketaren jarraipena',
  documentOrder: 'Ezarri dokumentuen ordena',
  yes: 'Bai',
  no: 'Ez',
  notifications: 'Jakinarazpenak',
  activateNotifications: 'Aktibatu',
  deactivateNotifications: 'Desaktibatu',
  selectCategoryNotifications: 'Hautatu jakinarazpenetarako ekintza',
  activateCategoryNotifications: 'Aktibatu denak',
  deactivateCategoryNotifications: 'Desaktibatu denak',
  customCategoryNotifications: 'Pertsonalizatu',
  enabledNotifications: 'Aktibatutako jakinarazpenak',
  disabledNotifications: 'Desaktibatutako jakinarazpenak',
  customNotifications: 'Jakinarazpen pertsonalizatuak',
  selectNotifications: 'Hautatu jakinarazpenen konfigurazioa',
  enabledNotificationsDescriptionText: 'Egindako gertaera bakoitzak jakinarazpen bat sortuko du. Bi aukera izango dituzu: horietariko bakoitzeko mezu elektroniko bat jaso, edo jakinarazpenak multzokatuta dituen eguneroko txosten bat jaso.',
  disabledNotificationsDescriptionText: 'Ez duzu jasoko egindako bidalketen egoerari buruzko jakinarazpenik.',
  customNotificationsDescriptionText: 'Egoerei buruzko jakinarazpenak jasotzeko eta ez jasotzeko prozesuak aukeratu ahal izango dituzu.',
  enabledNotificationsConfigurationText: 'Konfigurazioa',
  enabledNotificationsConfigurationRealTime: 'Jakinarazpenak denbora errealean',
  enabledNotificationsConfigurationGrouped: 'Multzokatutako jakinarazpenak',
  selectEnabledNotifications: 'Hautatu jakinarazpen-mota',
  enabledNotificationsRealTimeDescriptionText: 'Jaso berehalako mezu elektroniko bat egindako gertaera bakoitzeko.',
  enabledNotificationsGroupedDescriptionText: 'Aukeratutako ordutegian mezu elektroniko bat jasoko duzu, aurreko egunean egindako gertaera guztien txostenarekin.',
  enabledNotificationsScheduleRangeDescriptionText: 'Jakinarazi ordutegi honetan.',
  enabledNotificationsScheduleDescriptionText: 'Jakinarazi soilik ordutegi honetan.',
  chooseNotificationsRange: 'Aukeratu nola eta noiz jaso nahi dituzun jakinarazpenak.',
  enabledNotificationsCustomText: 'Jakinarazpenak pertsonalizatzea',
  realTimeNotificationsDescription: 'Jakinarazpenak denbora errealean bidaltzen dira. Egindako ekitaldi bakoitzeko, mezu elektroniko bat bidaliko da egoeraren berri emateko.',
  languageAndTimezone: 'Hizkuntza',
  defaultTimeZone: 'Ordu-tarte lehenetsia',
  user_process_notifications_updated: 'Jakinarazpen eguneratuak',
  changeSectionConfirmation: 'Gorde gabeko aldaketak',
  changeSectionConfirmationText: 'Gorde gabe dauden aldaketak egin dituzu. Orain gorde nahi badituzu, egin klik hemen:',
  groupedNotificationsDescription: 'Jakinarazpenak erantsitako txosten baten bidez bidaltzen dira. Txosten horrek transakzio guztien zerrenda eta horietako bakoitzean erregistratutako azken gertaera jasotzen ditu, hautatutako ordutegian.',
  groupedNotificationsReportTime: 'Zer ordutan jaso nahi duzu txostena',
  discard: 'Baztertu',
  cost_center_updated: 'Behar bezala eguneratu da kostu zentroa',
  loginInfo: 'Saio-hasieraren informazioa',
  lastLoginSuccess: 'Azken saio-hasiera',
  lastLoginError: 'Azken sarbide-saiakerak huts egin du',
  noRecord: 'Erregistrorik gabe',
  charLimitLength: 'Eremuak {limit} karaktere edo gutxiago izan behar ditu',
  requiredLastName: 'Izen-abizenak eskatzen dira',
  optional: 'Hautazkoa',
  availableVariables: 'Aldagai erabilgarriak',
  twoFactorText: 'Erabiltzaileak autentikazio-faktore bikoitza eskatzen du. Saioa hasteko, sar ezazu faktore anitzeko sarbideak kudeatzeko zure aplikazioan ikustarazi ahalko duzun kodea.',
  mfa_configuration_required: 'Erabiltzaileak autentikazio-faktore bikoitza eskatzen du. Hori konfiguratzeko, irakurri QRa faktore anitzeko sarbideen kudeaketako zure aplikaziotik. Ondoren, sartu kodea beheko laukian',
  code: 'Kodea',
  codeRequired: 'Saioa hasteko kodea behar da',
  mfa_code_invalid: 'Kodea ez da zuzena',
  communicationContent: 'Komunikazioaren edukia',
  uploadFiles: 'Erantsi dokumentuak',
  modifyFiles: 'Aldatu dokumentuak',
  fileResume: '{nfiles} fitxategi ({size})MB, guztira',
  moreThan1City: 'Posta-kodea herri bati baino gehiagori dagokio, begiratu zabalgarrian edo editatu eremua eskuz',
  shipmentDataSectionDescription: 'Atal honetan, komunikazioaren edukiak gehitzen dira. Dokumentuek PDF formatua eta A4 tamaina izan behar dute',
  zipCodeFormat: 'Posta-kode baliodun bat sartu behar duzu',
  deleteRecipient: 'Ezabatu hartzaile hori',
  mobileAddRecipientDescription: 'Hartzaile bat baino gehiago?',
  errorFilesTotalSizeExceeded: 'Fitxategien guztizko tamaina ezin da 15MB baino handiagoa izan',
  fileExtensionNotAccepted: 'Fitxategiaren luzapenak ez du balio. (Onartutako luzapenak hauek dira= {acceptedExtensions})',
  nameAndSurnameLength: 'Izen-abizenek ezin dute 99 karaktere baino gehiago izan',
  batchModeTitle: 'Sortaka bidaltzea',
  batchModeCSVStep: 'Hartzaileak',
  batchModeAttachmentsStep: 'Eskatutako fitxategiak',
  batchModeDownloadSampleCSV: 'Deskargatu adibideko fitxategia',
  batchModeExtraCSVStepIntroduction: 'Lehenik eta behin, <strong>CSV</strong> edo <strong>XLS</strong> fitxategi bat igo behar duzu bidalketen datuekin. Jarraian, beharrezko eremuak biltzen dituen adibideko fitxategi bat deskarga dezakezu.',
  batchModeExtraCSVStepIntroduction2: 'Fitxategia prest duzunean, gehitu egin dezakezu, klik eginez edo horretarako prestatutako eremuan arrastatuz. Gogoan izan fitxategiak <strong>ezin duela lerro hutsik edo akatsik</strong> izan hartzaileetan.',
  batchModeExtraCSVStepIntroduction3: 'Fitxategiaren egitura baliozkotu ondoren, <strong>Jarraitu</strong> botoia gaituko da eta prozesuarekin jarraitu ahal izango duzu.',
  batchModeExtraFilesStepIntroduction: 'Urrats honetan, hartzaile bakoitzari bidalketa egiteko behar diren baliabideak gehituko dira.<br><br>Guztira, <b>{count}</b> fitxategi detektatu dira. Gehitu ditzakezu klik eginez edo arrastatuz horretarako prestatutako eremuan.<br><br>Gogoratu bidalketa bakoitzeko fitxategien batura ezin dela <b>{fileSizeLimit} MB</b> baino handiagoa izan eta bidalketak <b>{totalSizeLimit} GB</b>ko gehieneko tamaina orokorra duela.',
  extraFilesTableHeaderFileName: 'Fitxategiaren izena',
  extraFilesTableHeaderStatus: 'Egoera',
  extraFilesTableStatusNotFound: 'Igotzeko zain',
  extraFilesTableStatusProcessing: 'Prozesatzen',
  extraFilesTableStatusUploaded: 'OK',
  extraFilesTableStatusUploadedCorrectly: 'Prozesatua',
  extraFilesTableHeaderActions: 'Ekintzak',
  batchModePreviewStep: 'Aurrebistaratu eta bidali',
  batchModeStep1RemoveFile: 'Fitxategia ezabatu',
  batchModeCSVTableTitle: '<strong>{filename}</strong> fitxategiak <strong>{nOfRecipients}</strong> hartzaile ditu.',
  batchModeCSVTableSubtitle: 'Jarraian, fitxategiaren lehen hartzaileen aurrebista duzu, egin klik Ikusi gehiago botoian, informazio gehiago izateko.',
  batchModeCSVTableSubtitleNoData: 'Hautatutako fitxategiak ez du hartzailerik.',
  batchModeNoDataTitle: 'Ez duzu fitxategirik hautatu. Gehitu ondoren, fitxategiaren lehen hartzaileak izango dituzu.',
  dropZoneText: 'Egin klik edo arrastatu hemen zure fitxategiak',
  dropZoneTextSingle: 'Egin klik edo arrastatu hemen zure fitxategia',
  batchModeDialogTitle: 'Gehitu hartzaileak',
  batchModeDialogManualColTitle: 'Eskuz',
  batchModeDialogManualColDescription: 'Banaka bidalketa bat edo batzuk egin nahi badituzu, hautatu aukera hau.',
  batchModeDialogBatchColTitle: 'Sortaka bidaltzea',
  batchModeDialogBatchColDescription: 'Hainbat hartzaile eta dokumenturekin csv edo xls fitxategi baten bidez bidalketak egin nahi badituzu, modu errazean hautatu aukera hau.',
  batchModeDialogBatchBtnManual: 'Banakako bidaltzea',
  batchModeDialogBatchBtnBatch: 'Sortaka bidaltzea',
  batchModeResourceTableNOfFilesFound: '<b>{nOfRequiredFiles}</b> fitxategi gehitu gabe',
  batchModeResourceTableAllFilesAdded: 'Beharrezko fitxategi guztiak gehitu dira',
  // batchModeResourceTableStatus: 'Egungo egoera= <b>$currentFiles$</b>/$nOfRequiredFiles$',
  batchModeResourceTableExpandText: 'Egin klik xehetasun gehiago ikusteko',
  batchModeResourceTableFilenameText: 'Fitxategiaren izena',
  batchModeResourceTableFoundOnNRows: 'eragindako hartzaileak',
  batchModeResourceTableErrorFilenameInvalid: 'Gehitzen saiatu diren fitxategietako bat edo gehiago ez datoz bat espero ziren fitxategiekin.',
  batchModeResourceTableErrorFileSizeLimit: 'Fitxategiaren tamaina {size}MB baino handiagoa da',
  batchModeResourceTableErrorTotalFileSizeLimit: 'Igotako fitxategien guztizkoa ezin da {size}GB baino handiagoa izan',
  batchModeResourceTableInfoFileReplaced: '{filename} fitxategia ordeztu da.',
  seeMore: 'Ikusi gehiago',
  seeLess: 'Ikusi gutxiago',
  pending: 'Egiteke',
  batchModePreviewText: 'Prozesatu ondoren, transakzioak bistaratu ahal izango dituzu dagokion ikuspegian, eta e-mail bat jasoko duzu bidalketa bakoitzaren informazioarekin, bai eta hartzaile bakoitzari lotutako transakzio-identifikatzailea ere.',
  batchModePreviewText1: 'Ezin bada bidalketaren bat egin, e-mailean zehaztuko da zein ez diren prozesatu errorearen arrazoiarekin.',
  csvError: 'Ezin izan da fitxategia prozesatu',
  csvErrorDetailStructure: 'Fitxategiaren egitura okerra da.',
  csvErrorDetailIncorrectFields: 'Eremuak ez datoz bat prozesuaren beharrezko datuekin',
  csvErrorDetailIncorrectExtensions: 'Fitxategi-luzapen batzuk baliogabeak dira lerro hauetan:',
  batchModeStep2SpaceLeft: 'Erabilitako espazioa',
  batchModeStep2PendingFiles: 'Egiteke dauden fitxategiak',
  launchDataKoBatchMode: 'Emandako datuak ez dira baliozkoak',
  launchDataKoBatchModeStep1: 'Ez da fitxategirik hautatu',
  launchDataOkBatchModeStep1: 'Fitxategiaren egitura zuzena da, prozesuarekin jarrai dezake',
  launchDataKoBatchModeStep2: 'Gehitu beharrezko fitxategiak',
  launchDataOkBatchModeStep2: 'Beharrezko fitxategiak gehitu dira, prozesuarekin jarrai dezakezu',
  launchDataOkBatchMode: 'Datuak zuzenak dira, prozesuarekin jarrai dezakezu',
  batchModeProcessLaunchedStep1: 'Erregistratzeko prozesua abiarazten',
  batchModeProcessLaunchedStep2: 'Bidalketak egiteko behar diren baliabideak igotzen',
  batchModeProcessLaunchedStep3: 'Hartzaileen fitxategia igotzen',
  batchModeProcessLaunchedStep4: 'Erregistro-prozesua amaituta',
  transactionsProcessedBatchMode: 'Sortaka bidaltzea behar bezala erregistratu da',
  transactionProcessingBatchMode: 'Sortaka bidaltzea prozesatzen...',
  transactionProcessingBatchModeError: 'Errorea sortakako bidalketa prozesatzean',
  transactionProcessingBatchModeErrorDescription: 'Errorea gertatu da sorta prozesatzean; mesedez, jarri harremanetan euskarriarekin.',
  csvErrorDialogDescription: 'Gogoratu <b>CSV</b> edo <b>XLS</b> fitxategi bat igo behar duzula, eta fitxategi horrek <strong>ezin duela lerro hutsik eduki, ezta errorerik</strong> ere hartzaileetan.',
  extraFileError: 'Ezin izan da fitxategia gehitu',
  extraFileErrorDialogDescription: 'Gogoratu transakzio bakoitzeko tamainaren muga <b>{fileSizeLimit} MB</b> dela eta lotearen bidalketa osoak <b>{totalSizeLimit} GB</b>ko gehieneko tamaina duela.',
  extraFileErrorTransactionSizeLimit: 'Honako erregistro hauetan bidalketarako gehieneko tamaina gainditu da= {rows}',
  htmlEditorInfoParagraph1: 'Urrats honetan, hartzaile bakoitzari bidaliko zaion e-mailaren edukia pertsonalizatuko da.',
  htmlEditorInfoParagraph3: 'Editatzeko modu bat hauta dezakezu:',
  htmlEditorInfoBullet1: '<span>Txantiloia hautatzea</span>. Hautatu txantiloi bat eta egin beharrezko doikuntzak editoretik.',
  htmlEditorInfoBullet2: '<span>HTML pertsonalizatua</span>. Igo HTML fitxategi pertsonalizatu bat eta egin beharrezko doikuntzak editoretik.',
  htmlEditorButtonUploadFile: 'HTML IGO',
  htmlEditorDragAndDrop: 'Egin klik hemen edo arrastatu e-mailera erantsitako fitxategiak.',
  batchModeExtraCSVStepIntroductionVariables: 'Gogoan izan aldagai hauek dituzula eskura transakzio-eremuetan erabiltzeko:',
  batchModeContactId: 'Hartzailearen erreferentzia',
  batchModeContactName: 'Hartzailearen izena',
  batchModeContactSurname: 'Hartzailearen abizenak',
  htmlEditorTemplateSelected: 'Aukeratutako txantiloia',
  htmlSubjectError: 'Gaiak <span>{subjectLimit}</span> karaktere baino gehiago ditu',
  htmlEditorInfoParagraph4: 'Gogoratu fitxategien gehienezko tamaina <b>{totalSizeLimit} Mb</b> dela.',
  backToProcess: 'Itzuli prozesuetara',
  infoAndHelp: 'Informazioa eta laguntza',
  modelEmailHtmlTemplateSelector: 'Hautatu txantiloia',
  modelEmailEmailBodyNotHtml: "Hautatutako fitxategia ez da html",
  templateSelectorDefaultTemplate: 'Lehenespenez',
  templateSelectorCustomTemplate: 'Pertsonalizatua',
  templateSelectorNoLogoTemplate: 'Logotiporik gabe',
  filesExcedingSizeLimit: 'Tamaina-muga gainditzen duten fitxategiak',
  emailAttachmentsSectionTitle: 'Mezu elektronikoko fitxategi erantsiak',
  emailContent: 'Mezu elektronikoaren edukia',
  emailSubject: 'Mezu elektronikoaren gaia',
  multipleFileExtensionError: "Hay uno o más ficheros con extensiones inválidas. (Las extensiones aceptadas son: {acceptedExtensions})",
  filePasswordProtectedOrDamage: "Fitxategi baliogabea. Egiaztatu fitxategia ez dagoela kaltetuta edo pasahitz babestuta dagoela eta saiatu berriro",
  backLostChangesHTMLText: "Atzera joaten baduzu, email- txantiloiaren aldaketak galduko dituzu baita erantsitako fitxategiak ere. <br> Ziur zaude aurreko pausura <b>itzuli nahi</b> duzula?",
  backLostChangesHTMLConfirmBack: "Aurreko pausura itzuli",
  backLostChangesHTMLConfirmContinue: "Bidalketa jarraitu",
  processStepperDigitalSignatureConfigurationTitle: 'Konfigurazioa',
  processStepperDigitalSignatureConfigurationDescription: 'Sinaduraren ekitaldia pertsonalizatzea',
  processStepperDigitalSignatureSignersTitle: 'Sinatzaileak',
  processStepperDigitalSignatureSignersDescription: 'Sinatzaileen konfigurazioa eta sinadura esperientzia',
  processStepperDigitalSignatureDocumentsTitle: 'Dokumentuak',
  processStepperDigitalSignatureDocumentsDescription: 'Dokumentuen hautaketa eta sinatzaileen interakzioa',
  processStepperDigitalSignatureSummaryTitle: 'Berretsi eta Bidali',
  processStepperDigitalSignatureSummaryDescription: 'Sinaduraren ekitaldiaren berrikuspena eta berrespena',
  transactionName: 'Transakzioaren izena',
  transactionInterveners: 'Transakzioaren parte-hartzaileak',
  more: 'gehiago',
  finished: 'Amaituta',
  eventsInfo: 'Gertaeren informazioa',
  transactionInvalidAlert: 'Transakzioaren sarrera-datuak egiaztatzen ari zela, akats posibleak aurkitu dira, eta horrek ez du ondo exekutatzea eragingo. Transakzioa aurretik blokeatu da. Datu sarrera egokiekin transakzio berri bat abiarazi beharko duzu.',
  transactionEntryData: 'Transakzioaren sarrera-datuak',
  goToSignature: 'Joan sinadurara',
  intervenersNotDetected: 'Ez da parte-hartzaile bat ere aurkitu',
  transactionEventsRegistered: 'Transakzioan izena emandako gertaerak',
  aboutEvents: 'Gertaien inguruan',
  notEvents: 'Ez da gertaerarik erregistratu transakzioan.',
  notEventsRegistered: 'Ez da gertaerarik erregistratu transakzioan. Transakzioa azkenaldian sortu bada, agian oraindik sortzen ari da.',
  downloadCertificate: 'Deskargatu ziurtagiria',
  notValid: 'Balio gabe',
  transactionPending: 'Emanari',
  refresh: 'Eguneratu',
  creation: 'Sortzea',
  transactionDigitalSignatureInfo: 'Sinadura digitaleko transakzioaren informazioa',
  reminders: 'Gogorarazpenak',
  input: {
    clear: 'Garbitu'
  },
  intervenerStartCeremony: 'Parte-hartzaile honen sinadura txanda hasteko, fitxa berria irekiko da. Gogoratu, transakzioan sinadura ordena badago, aurreko parte-hartzaileek dokumentuak osatu beharko dituztela.',
  details: 'Xehetasunak',
  front_renderer_builder_transaction_opened: 'Sinadura transakzioa bisitatu da',
  front_renderer_builder_transaction_finished: 'Sinadura transakzioa guztiek osatu dute',
  front_renderer_builder_intervener_signed: 'Parte-hartzaile batek lotutako dokumentuen sinadura osatu du',
  front_renderer_builder_transaction_created: 'Sinadura transakzioa ondo sortu da',
  front_renderer_builder_transaction_invalid: 'Sinadura transakzioak akatsak ditu eta ezin izan da ondo sortu',
  front_renderer_builder_intervener_finished: 'Parte-hartzailea sinadura guztiak osatu ditu',
  certificate_builder_generated: 'Transakzioaren amaierako ziurtagiria sortu da, komunikazioaren edukia eta prozesuan gertatutako gertaeren froga lotuak biltzen dituena.',
  transactionFilesParagraph: 'Transakzioan dauden fitxategien zerrenda',
  transactionDigitalProcessed: 'Transakzioa "{transactionName}" arrakastaz prozesatu da',
  signatureType: 'Sinaduraren mota',
  faceToFace: 'Aldez aurreko',
  remote: 'Urruneko',
  transactionWarning: 'Akatsak ditu',
  cancelTransaction: 'Transakzioa ezeztatu',
  identifyTransactionPlataformCommunications: 'Identifikatu transakzioa plataforma eta komunikazioetan',
  placeholderTransactionName: 'Transakzioaren izena',
  determineMethodAccessSigning: 'Ezarri sinadura ekitaldira sartzeko metodoa',
  transactionType: 'Transakzioaren mota',
  descriptionRemoteSignature: '<p><b>Sinadura elektroniko urruna</b> sinatzaileei sinaduraren ekitaldira edozein lekutik eta gailu batetik sartzeko aukera ematen die komunikazio kanal erabilgarrien bidez.</p>',
  descriptionPresentialSignature: '<p><b>Sinadura aldez aurrekoa</b> sinatzaileei transakzioa abiarazteko erabiltzen duten gailutik sartzeko aukera ematen die.</p>',
  setSignatureReminders: 'Ezarri sinadura ekitaldira sartzeko gogorarazpenak',
  transactionReminder: 'Transakzioaren gogorarazpenak',
  transactionRedirectToTransactionDetail: 'Ikusi transakzioa',
  transaccion_cancelada: 'Transakzioa ezeztatu da',
  cancelled: 'Ezeztatua',
  digital_certificate: 'Ziurt.',
  cert: 'Ziurt.',
  nif: 'DNI/NIF',
  sms_otp: 'SMS OTP',
  email_otp: 'Email OTP',
  video_id: 'Dokumentua identifikatzea',
  ama: 'AMA Portugal',
  lastUpdate: 'Azken eguneratzea',
  cancelTransactionAdvertisement: 'Transakzioaren ezeztapena ez da birsortzailera. Gogoratu ezeztapen prozesuak minutu batzuk behar ditzakeela. Ziur al zaude transakzioa ezeztatu nahi duzula?',
  transaction_cancelled: 'Transakzioa ezeztatu da',
  transaction_not_cancelled: 'Transakzioa ezeztatu ezin izan da',
  signed: 'Sinadura osatuta',
  addReminder: 'Gogorarazpen bat gehitu',
  sendingNewReminder: 'Gogorarazpen bat gehitu, lehenengo bidalketa edo aurreko gogorarazpenaren artean igaro behar diren egunetan ezarriz',
  confirm: 'Berretsi',
  sinceCreation: 'sortzetik',
  sinceReminderNumber: 'gogorarazpenaren nºtik',
  reminderLimitReached: 'Gogorarazpenen muga iritsi duzu',
  reminderLimitReachedZero: 'Ez da gogorarazpenik gehitu daiteke',
  customTextComplementSignatureAccessInformation: 'Sinadurara sartzeko emailak barne hartzen dituen informazio-testua',
  globalMessageInterveners: 'Mezu globala parte-hartzaileentzat',
  exampleInformSignatory: 'Sartu mezuaren edukia',
  days: 'egunak',
  infoReminder: '<p>Sinaduraren ekitaldiaren gogorarazpenak sinatzaileei sortzean ondoren bidalitako komunikazioak dira. Muga transakzioaren iraungitzeak edo, horrela bada, prozesuaren berezko muga irizpideak arabera ezarrita dago.</p>',
  infoReminderDays: '<p>Lehenengo gogorarazpenean adierazitako egunetan <b>transakzioaren sortzetik</b> zenbatuko dira, eta ondorengo gogorarazpenetan adierazitako egunetan aurreko gogorarazpenaren bidalketatik zenbatuko dira.</p>',
  determineDaysSigningProcessRemainActive: 'Ezarri sinadura ekitaldia aktibo mantenduko duten egunak',
  transactionExpiration: 'Transakzioaren iraungitze data',
  orderOfSignature: 'Sinaduraren ordena',
  signatoriesAdded: 'transakzioan gehituak',
  infoTransactionWithoutSignatures: 'Transakzioak oraindik sinatzaileak ez ditu. Eman beheko botoitik has zaitezke.',
  intervenersLimitReached: 'Sinatzaileen muga iritsi duzu',
  addSigner: 'Gehitu sinatzaile',
  signerEdition: 'Sinatzailearen konfigurazioa',
  nameSurname: 'Izena eta abizenak / Sozio-izena',
  placeholderSignerName: 'Juan Adibide Adibide',
  identificationType: 'Identifikazio mota',
  withoutIdentification: 'Identifikaziorik gabe',
  securitySignature: 'Berrespen metodoa',
  shippingChannel: 'Bidalketa kanal',
  completetionEmail: 'Amaierako emaila',
  personalizedMessage: 'Hasierako emailaren mezua pertsonalizatua',
  numberDocument: 'Nº Dokumentua',
  contactMethod: 'Kontaktua metodoa',
  notIndicated: 'Ez da adierazi',
  identificationSignature: 'Identifikazioa/Sinadura',
  documents: 'Dokumentuak',
  infoTransactionWithoutDocuments: 'Transakzioak oraindik dokumenturik ez du. Hasi ditzakezu beheko botoitik gehitzen.',
  documentsLimitReached: 'Dokumentuen muga iritsi duzu',
  addDocument: 'Dokumentua gehitu',
  requirementsAddDocument: 'Dokumentuek honako baldintzak bete behar dituztela kontuan hartu beharko zenuke:',
  addDocumentError: 'Dokumentua gehitzea ez da posible izan',
  documentNameRepeated: 'Gehitu nahi duzun dokumentuaren izena transakzioan dagoeneko existitzen da.',
  documentDetail: 'Dokumentuaren xehetasunak',
  documentActions: 'Ekintzak',
  withoutActions: 'Ekintzarik gabe',
  positionSignature: 'Sinadurak kokatu',
  deleteDocument: 'Ezabatu dokumentua',
  size: 'Tamaina',
  totalFileSizeExceeded: 'Igo dituzun fitxategien tamaina guztia {size&Unit} gainditu ezin da.',
  minCharacters: 'Gutxienez {min} karaktere',
  maxCharacters: 'Gehienez {max} karaktere',
  expirationDays: '0 baino handiagoa eta {max} egun baino txikiagoa izan behar da',
  expirationMustBeLongerThanReminders: 'Iraungitze-data oroigarriak baino luzeagoa izan behar da',
  reminderRuleAvailableDaysInput: '0 baino handiagoa eta {max} egun baino txikiagoa izan behar da',
  remoteDigitalSignature: 'Sinadura elektroniko urruna',
  presentialSignature: 'Sinadura presentziala',
  none: 'Ezertan',
  noneMasc: 'Inor',
  certificateDigital: 'Ziurtagiria',
  alertAbandonProcess: 'Orain prozesua utzi baduzu, sartutako datuak galduko dituzu. Seguru zaude prozesutik irten nahi duzula?',
  amaPT: 'Ama Portugal',
  graphometricSignature: 'Sinadura grafometrikoa',
  signature: 'Sinadura',
  documentSignatures: 'Dokumentuaren sinadurak',
  closeDocument: 'Itxi dokumentua',
  selectSignersActions: 'Hautatu sinatzailea dokumentuari buruzko ekintzak ezagutzeko',
  sendSignedDocuments: 'Sinatutako dokumentuak bidali',
  notSendSignedDocuments: 'Ez bidali sinatutako dokumentuak',
  documentLimitSizeAlert: 'Dokumentuaren tamaina 12 MB-tik gorakoa ez da izan behar',
  documentFormatAlert: 'Dokumentuak PDF formatuan izan behar dira eta ez dira enkriptatuak edo kaltetuta egon behar',
  documentLimitCountAlert: 'Transakzioarentzako dokumentuen muga da ',
  signersAdded: 'Sinatzaileak',
  sendTransaction: 'Sinadura ekitaldia bidali',
  sendTransactionInfo: 'Gailurrean, laburpen gisa, konfigurazioan sartutako datuak dituzu. Aztertu ondoren, beheko botoian klik eginez bidali dezakezu',
  transactionOkMessage: 'Transakzioa arrakastaz prozesatu da; hurrengo minutuetan, "Transakzioaren Xehetasunak" ikuspegiaren bidez erlazionatutako ekintzak ikusi ahal izango dituzu. Beheko botoian klik eginez sar zaitezke',
  transactionsOkMessage: 'Transakzioak arrakastaz prozesatu dira; hurrengo minutuetan "Transakzioak" ikuspegiaren bidez ikusi ahal izango dira. Beheko botoian klik eginez sar zaitezke',
  goToPresentialCeremony: 'Sinadura presentzialaren ekitaldia hasi',
  interactionType: 'Interakzio mota',
  signDocuments: 'Sinatu dokumentuak',
  acceptDocuments: 'Onartu dokumentuak',
  requireCompleteReading: 'Dokumentuaren irakurketa osoa',
  availableActions: 'Eguneratu ekintzak',
  signerAcceptDocument: 'Sinatzaileak dokumentu hau onartu behar du',
  signaturesNotAvailable: 'Sinadurak ez daude eskuragarri sinatzailea "Dokumentuen onartzea" aktibatuta badu',
  selectSigners: 'Hautatu sinatzaileak',
  selectSignersDescription: 'Hautatu transakzioan dokumentuan parte hartu behar duten sinatzaileak',
  action: 'ekintza',
  singleClickSignatureInfoText: 'Dokumentuaren onarpena aktibatuta dago, beraz, klik bakarrean sinatuko da.',
  requireScrollToBottomInfoText: 'Dokumentuaren irakurketa osoa aktibatuta dago',
  page: 'Orri',
  interveners: 'Intervinenteak',
  "face-to-face": 'Presentzial',
  expiration: 'Irakurketa',
  signatureStatus: 'Sinaduren egoera',
  remaining: 'Gelditzen dena',
  completed: 'Osatu',
  contact: 'Kontaktua',
  events: 'gertaera/k',
  notSpecified: 'Zehaztu gabe',
  intervener: 'Esku-hartzaile',
  reference: 'Erreferentzia',
  expired: 'Iraungita',
  documentViewerDialogSubtitle: 'Hautatu dokumentuarekin elkarreragingo duten sinatzaileak, baita egin beharreko ekintzak ere',
  front_renderer_builder_intervener_identification_success: 'Interventorea behar bezala identifikatu da sinadura ekitaldian',
  front_renderer_builder_signature_otp_generated: 'Transakziorako sarbide kodea sortu da',
  front_renderer_builder_signature_otp_sent: 'Transakziorako sarbide kodea interventorearen gailura bidali da',
  front_renderer_builder_transaction_expired: 'Transakzioa ez dago eskuragarri iraungi delako',
  batches: 'Batch bidalketak',
  view_batches: 'Batch bidalketak',
  open: 'Ireki',
  selectProcess: 'Hautatu prozesu bat',
  transactionsSearchPlaceHolder: 'Bilaketa transakzio ID edo parte-hartzailearen arabera',
  batchesDescription: 'Atal honetan plataforman egindako batch bidalketak erakusten dira',
  scheduleTime: 'Bidalketa data',
  schedule: 'Egoera/Bidalketa',
  totalRecords: 'Erregistroak',
  showTransactions: 'Ikusi transakzioak',
  cancelBatch: 'Ezeztatu bidalketak',
  cancelBatchConfirmText: 'Ziur al zaude bidalketa eta lotutako transakzio guztiak ezeztatu nahi dituzula?',
  selectDateFilter: 'Data iragazkia',
  stateFilterPlaceholder: 'Egoera iragazkia',
  processFilterPlaceholder: 'Prozesuaren iragazkia',
  batchSearchTooltip: 'Bilaketarako eskuragarri dauden eremuak hauek dira',
  batchSearchTooltipFirstItem: 'Batch bidalketaren IDa',
  batchSearchTooltipSecondItem: 'Igorlearen erabiltzailearen emaila',
  batchSearchTooltipThirdItem: 'Bidalketa fitxategiaren izena',
  processed: 'Prozesatua',
  batchCancelled: 'Ezeztatua',
  batchDetail: 'Batch bidalketaren xehetasunak',
  batchInfo: 'Bidalketaren informazioa',
  batchInfoSectionIntroductionText: 'Nahi izanez gero, prozesu osoan erabiliko den izen identifikatzaile bat hauta dezakezu. Eremu hau hutsik uzten baduzu, prozesuaren izena erabiliko da.',
  batchNamePlaceholder: 'Lehenetsitako izena (prozesuaren izena)',
  shipmentScheduling: 'Bidalketa programazioa',
  scheduleShipmentIntroduction: 'Bidalketa data eta ordu zehatz baterako programatu dezakezu. Datarik hautatzen ez baduzu, bidalketa berehala egingo da.',
  shippingDate: 'Bidalketa data',
  time: 'Ordua',
  selectDate: 'Data',
  selectTime: 'Ordua',
  scheduledShipmentIncompleteDate: 'Bidalketa programatzeko, data eta ordua hautatu behar dituzu',
  scheduleShipment: 'Bidalketa programatu',
  edit: 'Editatu',
  digitalSignatureEmptyStepMessage: 'Ez dago editatzeko erabilgarri dagoen eremurik. Jarraitzeko <b>Jarraitu</b> botoia sakatu.',
  shipmentScheduled: 'Bidalketa programatua',
  scheduled: 'Programatua',
  sentTransactions: 'Bidalketa egindako transakzioak',
  sent: 'Bidaliak',
  pendingTransactions: 'Programatutako transakzioak',
  see: 'Ikusi',
  backToTransactions: 'Itzuli bidalitako transakzioetara',
  shipmentDate: 'Bidalketa data',
  selectProcessFirst: 'Egoera erabilgarriak ikusteko, hautatu lehenik prozesu bat'
}

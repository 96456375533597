<template>
  <v-select
      id="header-language-selector"
      v-if="compact_mode"
      v-model="globalLanguage"
      class="cts-lang--selector"
      :items="filteredLocations"
      @change="changeGlobalLanguage(globalLanguage)"
      dense
      item-text="name"
      item-value="code"
      return-object
      :disabled="$route.name === PROCESS_VIEW.NAME"
      :menu-props="{ contentClass: 'cts-menu-lang--selector', bottom: true, left: true  }"
      aria-label="language-selector"
  >
    <template v-slot:selection="{ item }">
      <p :id="`header-language-selector${item.code}`" class="pt-1">{{ item.code.toUpperCase() }}</p>
    </template>
  </v-select>
  <v-select
      single-line outlined dense
      id="header-language-selectors"
      v-else
      v-model="globalLanguage"
      class="ma-0 cts-select-box cts-box-filter-tooltip"
      :items="filteredLocations"
      @change="changeGlobalLanguage(globalLanguage)"
      item-text="name"
      item-value="code"
      return-object
      :disabled="$route.name === PROCESS_VIEW.NAME"
      :menu-props="{ contentClass: 'cts-menu-lang--selector', bottom: true, left: true  }"
  >
  </v-select>
</template>

<script>
import { mapGetters } from "vuex";
import { saveUserLanguage } from "@/services/userServices"
import { NAMESPACE as AUTHENTICATION_NAMESPACE, STATE as AUTHENTICATION_STATE } from "@/constants/vuex/authentication"
import { NAMESPACE as LOCATION_NAMESPACE, GETTERS as LOCATION_GETTERS } from "@/constants/vuex/location"
import { changeSiteLang } from "@/util/store_utils"
import { PROCESS_VIEW } from "@/constants/router/routes"
import locations from "@/constants/locations"
import { NAMESPACE as GLOBAL_NAMESPACE, GETTERS as GLOBAL_GETTERS } from "@/constants/vuex/global"

export default {
  name: "LangSelector",

  props: {
    compact_mode: Boolean,
    user_details: Boolean,
    attrs: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      globalLanguage: {code: this.$vuetify.lang.current, name: ""},
      locations,
      PROCESS_VIEW
    }
  },
  computed: {
    ...mapGetters({
      location: `${LOCATION_NAMESPACE}/${LOCATION_GETTERS.G_CURRENT_LOCATION}`,
      subdomainConfig: `${GLOBAL_NAMESPACE}/${GLOBAL_GETTERS.GET_SUBDOMAIN_CONFIG}`
    }),

    filteredLocations() {
      if (Array.isArray(this.subdomainConfig.available_languages)) {
        return this.locations.filter(x => this.subdomainConfig.available_languages.includes(x.code))
      }

      return []
    }
  },

  created() {
    const userLangIso = this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_LANGUAGE]
    const found_lang = locations.find(l => l.iso === userLangIso)

    if (this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_USER_LOGGED] && this.globalLanguage.code !== found_lang.code) {
      changeSiteLang(found_lang.iso)
      this.globalLanguage = found_lang.code     
    }
  },
  methods: {
    async changeGlobalLanguage(language) {
      if (this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_USER_LOGGED]) {
        const response = await saveUserLanguage(language.iso)
        if (response.status === 200) {
          this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_LANGUAGE] = language.iso
          await changeSiteLang(language.iso)
          this.globalLanguage = language
          location.reload()
        }

      } else {
        await changeSiteLang(language.iso)
        location.reload()
      }
    }
  }
}
</script>

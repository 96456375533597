import * as helpers from "@/util/utils";
import Axios from "@/plugins/axios"

export async function find(url, fields, filters, options) {

  filters = !helpers.nullOrEmpty(filters) ? filters : null
  options = !helpers.nullOrEmpty(options) ? options : null
  if (options !== null && options.sortBy !== null && options.sortDesc != null) {
    options.order = {
      field: options.sortBy[0],
      direction: options.sortDesc[0] ? 'DESC' : 'ASC'
    }

  }
  return Axios.get(
    process.env.VUE_APP_ENDPOINT + url,
    {
      params: {
        fields: fields,
        filters: JSON.stringify(filters),
        options: JSON.stringify(options)
      }
    }
  ).then((response) => {
    return response.data
  })

}
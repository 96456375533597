export default Object.freeze({

  SECTION_PROCESSES: "section_processes",
  SECTION_PROCESS: "section_process",
  SECTION_TRANSACTIONS: "section_transactions",
  SECTION_TRANSACTION: "section_transaction",
  SECTION_STATISTICS: "section_statistics",
  SECTION_ADMINISTRATION: "section_administration",
  SECTION_USERS: "section_users",
  SECTION_GROUPS: "section_groups",
  GROUP_CREATE: "group_create",
  SECTION_GROUP: "section_group",
  GROUP_DELETE: "group_delete",
  USER_CREATE: "user_create",
  USER_EDIT: "user_edit",
  USER_DELETE: "user_delete",

  CATEGORY_HIDDEN_NAME: "Master",
  CERTYSIGN_DEVELOPER: "certysign_developer",
  CATEGORY_SECTION_PROCESSES: "cat_section_processes",
  CATEGORY_SECTION_TRANSACTIONS: "cat_section_transactions",
  CATEGORY_SECTION_STATISTICS: "cat_section_statistics",
  CATEGORY_SECTION_ADMINISTRATION: "cat_section_administration",
  CATEGORY_SECTION_ACCOUNT: "cat_section_account",

  DISPLAY_EVENT_EVIDENCES: "display_event_evidences",
  TRANSACTION_DOWNLOAD_CERTIFICATE: "transaction_download_certificate",
  TRANSACTION_SHOW_EVENTS: "transaction_show_events",
  STATISTICS_EXPORT_DATA: "statistics_export_data",
  SECTION_ACCOUNT: "section_account",
  ACCOUNT_CHANGE_PASSWORD: "account_change_password",
  ACCOUNT_CHANGE_COST_CENTER: "account_change_cost_center",
  GROUP_EDIT_INFO: "group_edit_info",
  GROUP_EDIT_USERS: "group_edit_users",
  GROUP_EDIT_PERMISSIONS: "group_edit_permissions",
  GROUP_EDIT_LINKED_GROUPS: "group_edit_linked_groups",
  GROUP_EDIT_PROCESSES: "group_edit_processes",
  SECTION_USER: "section_user",
  SECTION_WITHOUT_SUBACCOUNTS: "section_withoutsubaccounts",
  SECTION_FORBIDDEN: "section_forbidden",
  SECTION_ERROR_500: "section_error500",

  ACCOUNT_EDIT_NOTIFICATIONS: "account_edit_notifications",
  SCHEDULE_SHIPMENT: "process_schedule_transaction",
})

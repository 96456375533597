<template>
  <v-menu
    v-model="menuVisible"
    :close-on-content-click="false"
    :min-width="300"
    :nudge-width="200"
    offset-y
  >
    <!-- Activator -->
    <template v-slot:activator="{ on }">
      <v-text-field
        :placeholder="formattedDateRange || $vuetify.lang.t('$vuetify.selectDateFilter')"
        :style="$vuetify.breakpoint.smAndDown ? 'min-width: 98% !important' : ''"
        :value="formattedDateRange"
        aria-label="Date-range"
        class="ma-0 cts-filter-text cts-filter-date"
        clearable
        dense
        hide-details
        prepend-inner-icon="event"
        readonly
        single-line
        solo
        v-on="on"
        @click:clear="clearDates"
      >
        <v-tooltip activator="parent" location="start">Tooltip</v-tooltip>
      </v-text-field>
    </template>

    <!-- Menu content -->
    <v-card>
      <v-row class="pa-0 ma-0" dense>
        <v-col class="ma-2">
          <v-date-picker
            v-model="internalValue"
            :allowed-dates="allowedDates"
            :header-date-format="getMonthFormat"
            class="mx-auto d-block"
            first-day-of-week="1"
            no-title
            range
            width="300px"
            @change="updateValue"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
import dayjs from "dayjs";
import {allowed_dates_calendar} from "@/util/utils";

export default {
  name: "DateFilter",
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      menuVisible: false,
      internalValue: this.modelValue || [],
    };
  },
  computed: {
    formattedDateRange() {
      if (this.internalValue.length > 0) {
        const formattedDates = this.internalValue.map((date) =>
          dayjs(date).format("DD/MM")
        );
        return `${this.$vuetify.lang.t("$vuetify.date")}: ${formattedDates.join(
          " ~ "
        )}`;
      }
      return null;
    },
  },
  methods: {
    updateValue() {
      this.$emit("change", this.internalValue);
    },
    clearDates() {
      this.internalValue = [];
      this.updateValue();
    },
    allowedDates(val) {
      return allowed_dates_calendar(this.internalValue, val);
    },
    getMonthFormat(dateString) {
      const date = new Date(dateString.split("-")[0], dateString.split("-")[1] - 1, 5);
      const formatted = dayjs(date).format("MMMM YYYY").toLowerCase();
      return formatted === "invalid date" ? "" : formatted;
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        this.internalValue = newValue || [];
      },
    },
  },
};
</script>

<style scoped>
</style>

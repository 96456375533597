<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    :max-width="options.width"
    @keydown.esc="cancel"
  >
    <v-card width="100%">

      <DialogTitle
        v-if="title"
        :title="title"
      />
      <v-card-text
        class="pa-8 black--text py-8"
      >
        <p
          class="text-center"
          v-html="message"
        />
        <v-row class="mt-8" dense justify="space-around">
          <v-col cols="5">
            <v-btn
              id="processview-dialog-button-back"
              block
              class="cts-button-secondary float-left"
              color="primary"
              small
              style="width: 100% !important;"
              @click="cancel()"
            >
              {{cancelText}}
            </v-btn>
          </v-col>
          <v-col cols="5">
            <v-btn
              id="processview-dialog-button-confirm"
              block
              class="cts-button-standard float-left"
              small
              style="width: 100% !important;"
              @click="agree()"
            >
              {{confirmText}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>


import DialogTitle from "@/components/structures/dialogTitle.vue";

export default {
  name: 'ConfirmDialog',
  components: {DialogTitle},
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      confirmText: "Confirmar",
      cancelText: "Cancelar",
      options: {
        width: 600,
        zIndex: 200
      },
      password: ''
    }
  },
  computed: {},
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.confirmText = options.confirmText
      this.cancelText = options.cancelText
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false

    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
  },
}
</script>
<template>
  <v-row dense>
    <v-col class="ma-0 pa-0 pl-2 pr-1">
      <v-form v-model="value.__valid">
        <v-row
          :class="{'pl-2': $vuetify.breakpoint.smAndDown, 'ma-0 pl-2': $vuetify.breakpoint.mdAndUp}"
          dense
        >
          <v-btn
            aria-label="warning"
            :class="index === 0 ? 'mt-6' : ''"
            class="b2b-recipient-index pt-0 hidden-xs-only"
            outlined
          >
            <v-icon
              :id="`recipients-form-${index}-status`"
              :class="value.__valid ? 'b2b-recipient-index__success':'b2b-recipient-index__alert'"
              class="py-0"
              small
            >
              {{ value.__valid ? 'how_to_reg' : 'warning' }}
            </v-icon>
          </v-btn>

          <v-col
            align-self="center"
            class="pa-0"
          >
            <v-row
              class="ma-0"
              dense
            >
              <v-col
                class="py-0"
                cols="12"
                lg="3"
              >
                <span
                  id="recipients-form-idLabel"
                  :class="!mobile && index !== 0 ? 'd-none' : ''"
                  :title="$vuetify.lang.t('$vuetify.requiredField')"
                  class="cts-font-size-4 cts-color-black_soft_text"
                >
                  {{ $vuetify.lang.t('$vuetify.id') }}
                  <span class="cts-color-primary-alternative font-weight-medium">*</span>
                </span>

                <TextInput
                  v-model="value[const_global.CONTACT_ID]"
                  :placeholder="$vuetify.lang.t('$vuetify.id')"
                  :required="true"
                  :maxLength="100"
                  :requiredText="$vuetify.lang.t('$vuetify.requiredField')"
                  customid="recipients-form-input-id"
                />
              </v-col>

              <v-col
                class="py-0"
                cols="6"
                lg="2"
              >
                <span
                  id="recipient-row-nameLabel"
                  :class="!mobile && index !== 0 ? 'd-none' : ''"
                  :title="$vuetify.lang.t('$vuetify.requiredField')"
                  class="cts-font-size-4 cts-color-black_soft_text"
                >
                  {{ $vuetify.lang.t('$vuetify.name') }}
                  <span class="cts-color-primary-alternative font-weight-medium">*</span>
                </span>
                <TextInput
                  v-model="value[const_global.CONTACT_NAME]"
                  :placeholder="$vuetify.lang.t('$vuetify.name')"
                  :required="true"
                  :requiredText="$vuetify.lang.t('$vuetify.requiredName')"
                  :rules="[checkNameAndSurnameLength]"
                  customid="recipients-form-input-name"
                />
              </v-col>

              <v-col
                class="py-0"
                cols="6"
                lg="2"
              >
                <span
                  id="recipient-row-surnameLabel"
                  :class="!mobile && index !== 0 ? 'd-none' : ''"
                  :title="$vuetify.lang.t('$vuetify.requiredField')"
                  class="cts-font-size-4 cts-color-black_soft_text"
                >
                  {{ $vuetify.lang.t('$vuetify.lastName') }}
                  <span class="cts-color-primary-alternative font-weight-medium">*</span>
                </span>
                <TextInput
                    v-model="value[const_global.CONTACT_SURNAME]"
                    :placeholder="$vuetify.lang.t('$vuetify.lastName')"
                    :required="true"
                    :requiredText="$vuetify.lang.t('$vuetify.requiredLastName')"
                    :rules="[checkNameAndSurnameLength]"
                    customid="recipients-form-input-lastName"
                />
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                lg="2"
              >
                <span
                  id="recipient-row-emailLabel"
                  :class="!mobile && index !== 0 ? 'd-none' : ''"
                  class="cts-font-size-4 cts-color-black_soft_text"
                >
                  {{ $vuetify.lang.t('$vuetify.email') }}
                  <span
                    v-if="emailRequired"
                    class="cts-color-primary-alternative font-weight-medium"
                  >*</span>
                  <span
                    v-else
                    class="cts-color-subtitle-a cts-font-size-5 ml-1"
                  >
                    ({{ $vuetify.lang.t('$vuetify.optional') }})
                  </span>
                </span>

                <TextInput
                  v-model="value[const_global.CONTACT_EMAIL]"
                  :enable-validation="true"
                  :placeholder="$vuetify.lang.t('$vuetify.email')"
                  :required="emailRequired"
                  :requiredText="$vuetify.lang.t('$vuetify.requiredEmail')"
                  customid="recipients-form-input-email"
                  type="email"
                />
              </v-col>

              <v-col
                class="py-0 pr-0"
                cols="12"
                lg="3"
              >
                <span
                  id="recipient-row-phoneLabel"
                  :class="!mobile && index !== 0 ? 'd-none' : ''"
                  class="cts-font-size-4 cts-color-black_soft_text"
                >
                  {{ $vuetify.lang.t('$vuetify.telephone') }}
                  <span
                    v-if="phoneRequired"
                    class="cts-color-primary-alternative font-weight-medium"
                  >*</span>
                  <span
                    v-else
                    class="cts-color-subtitle-a cts-font-size-5 ml-1"
                  >
                  ({{ $vuetify.lang.t('$vuetify.optional') }})</span>
                </span>

                <v-row
                  class="ma-0"
                  dense
                >
                  <vue-country-code
                    id="recipients-form-input-country"
                    v-model="value[const_global.CONTACT_COUNTRY_CODE]"
                    :preferredCountries="['es', 'pt', 'gb']"
                    class="recipient-phone-country"
                    defaultCountry="es"
                    @onSelect="changeCountryCode($event)"
                  >
                  </vue-country-code>
                  <TextInput
                    v-model="value[const_global.CONTACT_PHONE]"
                    class="input-country"
                    :autocomplete="false"
                    :placeholder="$vuetify.lang.t('$vuetify.telephone')"
                    :required="phoneRequired"
                    :requiredText="$vuetify.lang.t('$vuetify.requiredPhone')"
                    customid="recipients-form-input-phone"
                    style="margin-left: -1px"
                    type="international_phone"
                  />
                  <v-btn
                    v-if="!mobile"
                    id="recipient-row-remove_mobile"
                    :class="!mobile && index === 0 ? 'b2b-recipient-index-hidden': ''"
                    :title="$vuetify.lang.t('$vuetify.delete')"
                    class="ma-0 b2b-recipient-index"
                    icon
                    style="pointer-events: auto !important;"
                    @click="$emit('deleteRecipient')"
                  >
                    <v-icon
                      color="error"
                      size="18"
                    >clear
                    </v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>

</template>

<script>
import const_global from '@/constants/global'
import TextInput from '@/components/structures/inputs/textInput'
import vModelMixin from '@/mixins/v-model-mixin'

export default {
  name: 'RecipientRow',
  mixins: [vModelMixin],

  components: {
    TextInput
  },

  props: {
    emailRequired: Boolean,
    phoneRequired: Boolean,
    index: Number,
    mobile: Boolean,
    customValidations: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      const_global
    }
  },
  computed: {

    checkNameAndSurnameLength() {

      if (!this.customValidations.includes('postalRecipientLength')) {
        return true
      }
      let name = this.value[const_global.CONTACT_NAME]
      let surname = this.value[const_global.CONTACT_SURNAME]
      if (name && name.length >= 99) {
        return this.$vuetify.lang.t('$vuetify.nameAndSurnameLength')
      }
      if (surname && surname.length >= 99) {
        return this.$vuetify.lang.t('$vuetify.nameAndSurnameLength')
      }
      return name && surname && (name.length + surname.length >= 100) ? this.$vuetify.lang.t('$vuetify.nameAndSurnameLength') : true
    },
  },
  methods: {
    changeCountryCode({ dialCode }) {
      this.value[const_global.CONTACT_COUNTRY_CODE] = dialCode
    }
  }

}
</script>

<style
  lang="scss"
  scoped
>
.b2b-recipient-index {
  // margin-top: 24px;
  //margin-right: 4px !important;
  height: 28px !important;
  width: 28px !important;
  min-width: 28px !important;
  max-width: 28px !important;
  font-weight: bolder;
  color: var(--primary-alternative) !important;
  pointer-events: none !important;
  border: 1px solid var(--input-icons);
  margin-left: 8px !important;


  &-hidden {
    visibility: hidden;
  }

  &__success {
    color: var(--primary) !important;
  }

  &__alert {
    color: var(--error) !important;
  }

  &__remove {

  }


}

.b2b-recipient-remove {
  border: 1px solid var(--primary-alternative)
}

.recipient-phone-country {
  height: 28px !important;
}

.recipient-phone {
  width: 60% !important;
  max-width: 75% !important;
}

.input-country {
  border-radius: 0 4px 4px 0;
}

//.v-input__slot fieldset{
//  border-radius: 0 4px 4px 0 !important;
//}
</style>
<template>
  <div :class="{ 'disabled-container': disabled }" class="switch-container">
    <label class="switch">
      <input :disabled="disabled" :checked="value" type="checkbox" @change="onToggle">
      <span :class="value ? 'value-active-border' : 'value-disable-border'" class="slider">
        <span
          :class="value ? 'value-active' : 'value-disable'"
          class="icon-container d-flex justify-center align-center"
        >
          <v-icon v-if="value" alt="check icon" color="white" size="20">check</v-icon>
          <v-icon v-else alt="close icon" color="white" size="20">close</v-icon>
        </span>
        <span
          :class="value ? 'status-active ml-2' : 'status-desactive mr-2'"
          class="status"
        >
          {{ value ? $vuetify.lang.t('$vuetify.activate') : $vuetify.lang.t('$vuetify.deactivate') }}
        </span>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "SwitchWithLabel",

  props: {
    value: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {};
  },

  methods: {
    onToggle(event) {
        this.$emit('input', event.target.checked);
    }
  }
};
</script>

<style scoped>
.switch-container {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 36px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  transition: .4s;
  border-radius: 4px;
  user-select: none;
}

.icon-container {
  position: absolute;
  width: 40px;
  height: 35px;
  top: 0;
  left: 0;
  transition: .4s;
  border-radius: 2px 0 0 4px;
  padding-bottom: 1px;
  padding-right: 1px;
}

input:checked + .slider .icon-container {
  transform: translateX(159px);
  border-radius: 0 4px 4px 0;
}

.status {
  position: absolute;
  color: var(--darken);
  font-size: 0.9rem;
  pointer-events: none;
  top: 7px;
  font-weight: 500;
  text-transform: uppercase;
}

.status-active {
  left: 25px;
  color: var(--primary-alternative);
}

.status-desactive {
  right: 25px;
  color: var(--primary);
}

.value-active {
  background-color: var(--primary-alternative);
}

.value-disable {
  background-color: var(--primary);
}

.value-active-border {
  border: 1px solid var(--primary-alternative);
}

.value-disable-border {
  border: 1px solid var(--primary);
}

.disabled-container {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

</style>
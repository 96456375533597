export const ROOT = {
  PATH: "/",
  NAME: "root",
}
export const LOGIN = {
  PATH: "/login",
  NAME: "login",
}
export const REMEMBER_PASSWORD = {
  PATH: "/rememberPassword",
  NAME: "remember_password",
}
export const CHANGE_PASSWORD = {
  PATH: "/changePassword/",
  NAME: "change_password",
}
export const ACTIVATE_USER = {
  PATH: "/activateUser",
  NAME: "activate_user",
}
export const NEW_PASSWORD = {
  PATH: "/newPassword",
  NAME: "new_password",
}
export const PROCESSES = {
  PATH: "/processes",
  NAME: "processes",
}
export const PROCESS_VIEW = {
  PATH: "/processes/newtransaction",
  NAME: "process",
}
export const TRANSACTIONS = {
  PATH: "/transactions",
  NAME: "transactions",
}
export const TRANSACTION_VIEW = {
  PATH: "/transactions/details",
  NAME: "transaction",
}
export const BATCHES = {
  PATH: "/batches",
  NAME: "batches",
}
export const STATISTICS = {
  PATH: "/statistics",
  NAME: "statistics",
}
export const SUPPORT = {
  PATH: "/support",
  NAME: "support",
}
export const CONTACTS = {
  PATH: "/contacts",
  NAME: "contacts",
}
export const ADMINISTRATION = {
  PATH: "/administration",
  NAME: "administration",
}
export const ACCOUNT = {
  PATH: "/account",
  NAME: "account",
}
export const GROUPS = {
  PATH: "/groups",
  NAME: "groups",
}
export const GROUP_VIEW = {
  PATH: "/groups/details",
  NAME: "group",
}
export const USERS = {
  PATH: "/users",
  NAME: "users",
}
export const USER_VIEW = {
  PATH: "/users/details",
  NAME: "user",
}
export const FORBIDDEN = {
  PATH: "/forbidden",
  NAME: "forbidden",
}
export const WITHOUT_SUBACCOUNTS = {
  PATH: "/withoutsubaccounts",
  NAME: "withoutsubaccounts",
}
export const ERROR_500 = {
  PATH: "/error",
  NAME: "error500",
}

export default {
  ROOT,
  LOGIN,
  REMEMBER_PASSWORD,
  CHANGE_PASSWORD,
  ACTIVATE_USER,
  NEW_PASSWORD,
  PROCESSES,
  PROCESS_VIEW,
  TRANSACTIONS,
  TRANSACTION_VIEW,
  STATISTICS,
  SUPPORT,
  CONTACTS,
  ADMINISTRATION,
  ACCOUNT,
  GROUPS,
  GROUP_VIEW,
  USERS,
  USER_VIEW,
  FORBIDDEN,
  WITHOUT_SUBACCOUNTS,
  ERROR_500,
  BATCHES
}

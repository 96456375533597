<template>
  <div :class="{ 'disabled-container': disabled }" class="switch-container">
    <label class="switch">
      <input :disabled="disabled" :checked="value" type="checkbox" @change="onToggle" :aria-label="ariaLabel">
      <span
        :class="value? 'value-active' : 'value-disable'" class="slider"
      >
        <span
          :class="value? 'value-active value-active-border' : 'value-disable value-disable-border'"
          class="icon-container d-flex justify-center align-center cts-bgcolor-lighten"
        >
          <v-icon v-if="value" alt="cross icon" color="var(--primary-alternative)" size="18">check</v-icon>
          <v-icon v-else alt="cross icon" color="var(--primary)" size="18">close</v-icon>
        </span>
      </span>
    </label>
  </div>
</template>

<script>

export default {
  name: "SwitchWithoutLabel",

  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    ariaLabel: {
      type: String,
      default: ''
    }
  },

  data() {
    return {};
  },

  methods: {
    onToggle(event) {
      this.$emit('input', event.target.checked);
      this.$emit('change', event.target.checked);
    }
  }
};
</script>

<style scoped>
.switch-container {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: .2s;
  border-radius: 4px;
}

.value-active {
  background-color: var(--primary-alternative);
}

.value-disable {
  background-color: var(--primary);
}

.value-active-border {
  border: 1px solid var(--primary-alternative)
}

.value-disable-border {
  border: 1px solid var(--primary)
}

.icon-container {
  position: absolute;
  width: 25px;
  height: 26px;
  top: 1px;
  left: 1px;
  transition: .2s;
  border-radius: 4px 0 0 4px;
}

input:checked + .slider .icon-container {
  transform: translateX(21px);
  border-radius: 0 4px 4px 0;
}

.disabled-container {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

</style>
export default {
  badge: 'Scheda',
  close: 'Chiudi',
  dataIterator: {
    noResultsText: 'La ricerca non ha prodotto risultati',
    loadingText: 'Caricamento in corso'
  },
  dataTable: {
    itemsPerPageText: 'File per pagina:',
    ariaLabel: {
      sortDescending: ': Ordine discendente Premi per rimuovere il filtro',
      sortAscending: ': Ordine ascendente. Premi per ordinare in modo discendente.',
      sortNone: ': Senza filtro. Premi per ordinare in modo ascendente.',
      activateAscending: 'Attiva ordine ascendente',
      activateDescending: 'Attiva ordine discendente',
      activateNone: 'Disattiva tutti i filtri'
    },
    sortBy: 'Ordinato in base a'
  },
  dataFooter: {
    itemsPerPageText: 'Elemento per pagina:',
    itemsPerPageAll: 'Tutti',
    nextPage: 'Pagina successiva',
    prevPage: 'Pagina precedente',
    firstPage: 'Prima pagina',
    lastPage: 'Ultima pagina',
    pageText: '{0}-{1} su {2}'
  },
  datePicker: {
    itemsSelected: '{0} selezionati',
    prevMonthAriaLabel: 'Mese precedente',
    nextMonthAriaLabel: 'Mese successivo'
  },
  noDataText: 'Non ci sono dati disponibili',
  carousel: {
    prev: 'Schermata anteriore',
    next: 'Schermata successiva',
    ariaLabel: {
      delimiter: 'Carousel slide {0} of {1}'
    }
  },
  calendar: {
    moreEvents: '{0} più'
  },
  fileInput: {
    counter: '{0} file',
    counterSize: '{0} file ({1} in totale)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  home: 'Home',
  templates: 'Flussi',
  accounts: 'Account',
  processes: 'Processi',
  transactions: 'Transazioni',
  statistics: 'Statistiche',
  support: 'Assistenza',
  contacts: 'Contatti',
  administration: 'Amministrazione',
  search: 'Ricerca',
  appearance: 'Stili',
  users: 'Utenti',
  alastria: 'Alastria',
  billing: 'Fatturazione',
  account: 'Gestisci il mio account',
  admin: 'Amministrazione',
  demos: 'Demo',
  selectedSubaccount: 'Account secondario selezionato',
  withoutSubaccount: 'Non ci sono account secondari',
  groups: 'gruppi',
  filterDate: 'Filtro data',
  subaccount: 'Account secondario',
  subaccounts: 'Account secondari',
  costCenters: 'Centro dei costi',
  subaccountsAssigned: 'Account secondari assegnati',
  defaultCostCenter: 'Centro dei costi predefinito',
  selectedCostCenter: 'Centro dei costi selezionato',
  availableCostCenters: 'Centri dei costi disponibili',
  changeDefaultCostCenter: 'Cambia centro dei costi predefinito',
  costCenterDialogText: 'Se lo desidera, può cambiare il centro dei costi selezionato per la transazione. Ciò non comporterà la modifica del centro dei costi predefinito, ma verrà usato specificamente per questa transazione',
  costCenterDialogTextAccount: 'Se lo desidera, può cambiare il centro dei costi predefinito. Con questa azione il centro dei costi selezionato diventerà il centro predeterminato.',
  costCenterSelectedTitle: 'Centro dei costi selezionato',
  activeSubaccount: 'Account secondario attivo',
  user: 'Utente',
  userData: 'Dati dell’utente',
  userCECOS: 'Centro dei costi',
  userCECO: 'Centro dei costi',
  userCodeCECO: 'Codice',
  userCECOPrimary: 'Predefinito',
  userCECOCodeTitle: 'Codice del centro dei costi',
  password: 'Password',
  passwordchange: 'Cambia password',
  oldpassword: 'Password attuale',
  newpassword: 'Nuova password',
  updatePassword: 'Aggiorna password',
  repeatpassword: 'Ripeti password',
  passwordrequirements: 'La password deve includere almeno 8 caratteri e rispettare almeno 3 delle regole aggiuntive:',
  passwordMinOneLowercase: 'Minimo 1 lettera minuscola.',
  passwordMinOneUppercase: 'Minimo 1 lettera maiuscola.',
  passwordMinOneNumber: 'Minimo 1 numero.',
  passwordMinOneSpecialChar: 'Minimo 1 carattere speciale.',
  passwordMinEightChars: 'Minimo 8 caratteri.',
  passwordCompares: 'È necessario confrontare le password.',
  passwordFieldError: 'Errore nel campo. Consultare le regole.',
  passwordrequirementsMoreThanEightChars: 'La password deve includere almeno 8 caratteri',
  notequalpasswords: 'Le password non sono uguali',
  samepasswordthanolder: 'La nuova password è uguale a quella precedente',
  emptyOldPassword: 'Non hai inserito la password precedente',
  role: 'Ruolo',
  lastConnection: 'Ultimo collegamento',
  languageSelector: 'Seleziona una lingua',
  logout: 'Log out',
  others: 'Altro',
  continue: 'Continua',
  continueWSend: 'Continua con l’invio',
  sendRecoveryForm: 'Invia richiesta',
  restartFilters: 'Elimina i filtri',
  back: 'Indietro',
  backToMethods: 'Metodi di recupero',
  moreInfo: 'Maggiori informazioni',
  activateuser: 'Attiva utente',
  exit: 'Esci',
  platformVersion: 'Versione della piattaforma',
  termsAndConditionsCompliance: 'Termini e condizioni',
  privacyPoliciesCompliance: 'Politica sulla protezione dei dati',
  cookiesPoliciesCompliance: 'Politica dei cookie',
  cookiesPoliciesCertySign: 'Cookie in CertySign',
  alertSizeFiles: 'Tutti i file devono pesare al massimo 8Mb',
  login: 'Log in',
  noSubaccountsMessage: 'Non ci sono account secondari assegnati a lei',
  telephone: 'Numero di telefono',
  name: 'Nome',
  lastName: 'Cognome',
  email: 'E-mail',
  omit: 'Nascondi',
  id: 'Riferimento cliente',
  clear: 'Cancella',
  add: 'Aggiungi',
  recipients: 'Destinatari',
  shipmentData: 'Dati dell’invio',
  prevAndSend: 'Anteprima e invio',
  previewFileSize: 'Il file deve pesare al massimo 15Mb',
  rulesFilesSize: 'Tutti i file devono pesare al massimo 15Mb',
  sectionDataFieldsDescription: 'Nella parte inferiore sono disponibili i diversi campi da compilare per inviare la transazione ai destinatari. I campi del contatto verranno compilati automaticamente, accedendo ai dati del destinatario.',
  sectionDataFieldsNotAditionalFields: 'Questo processo non prevede campi aggiuntivi. Può completare l’invio della sua transazione da Anteprima.',
  sectionPreviewSubtitle: 'Nella parte inferiore potrà navigare tra i destinatari per verificare individualmente ogni invio.',
  manual_version: 'Visualizzazione dell’ultima versione del manuale per l’utente in corso.',
  view_login: 'Log in',
  view_transactions: 'Transazioni',
  view_transaction: 'Dettagli della transazione',
  view_process: 'Avviare un processo',
  view_processes: 'Processi',
  view_statistics: 'Statistiche',
  view_support: 'Assistenza e Aiuto',
  view_administration: 'Amministrazione',
  view_groups: 'Gruppi',
  view_group: 'Dettagli del gruppo',
  view_users: 'Utenti',
  view_account: 'Il mio account',
  view_remember_password: 'Ricorda password',
  view_change_password: 'Cambia password',
  view_activate_user: 'Attiva utente',
  view_new_password: 'Nuova password',
  view_contacts: 'Contatti',
  view_user: 'Dettagli dell’utente',
  view_withoutsubaccounts: '',
  view_forbidden: '',
  view_root: '',
  view_error500: 'Errore',
  searchAgain: 'Cerca di nuovo',
  recipient: 'Destinatario',
  sender: 'Mittente',
  namesender: 'Nome del mittente',
  subject: 'Oggetto',
  emailbody: 'Testo dell’e-mail',
  resourcespreview: 'Anteprima delle risorse',
  attach: 'Allega',
  actions: 'Azioni',
  changePassword: 'Aggiorna password',
  sessionClosed: 'Il log out è avvenuto con successo',
  noPermissionMessage: 'Non dispone dei permessi per accedere a questa sezione',
  download: 'Scarica',
  viewDocument: 'Vedi documento',
  accept: 'Accetta',
  reject: 'Rifiuta',
  change: 'Cambia',
  addVariables: 'Aggiungi variabile',
  resources: 'Risorse',
  launchDataKo: 'I dati forniti non sono validi per realizzare l’invio',
  launchDataOk: 'I dati sono corretti. Può procedere all’invio',
  addNewRecipient: 'Aggiungi nuovo destinatario',
  transactionRecipients: 'Destinatari della transazione',
  noRecipientsSelected: 'Nessun destinatario selezionato',
  recipientsSelected: 'Sono stati selezionati',
  recipientSelected: 'È stato selezionato',
  preview: 'Anteprima',
  summary: 'Riepilogo',
  transaction_summary: 'Riepilogo dell’invio',
  PDFpreview: 'Anteprima del PDF',
  previewing: 'Anteprima in corso:',
  notAvailable: 'Non disponibile',
  hide: 'Nascondi',
  send: 'Invia',
  showFile: 'Vedi file',
  error500Message: 'Si è verificato un errore. Se il problema persiste, può contattare l\'assistenza tramite',
  thisForm: 'questo modulo',
  error401Message: 'La sessione è scaduta',
  withoutData: 'Non ci sono dati',
  notInformed: 'Non ci sono informazioni',
  loginTitleText: 'Log in',
  changePasswordTitleText: 'Modifica della password',
  activateUserTitleText: 'Attivazione dell’utente',
  homeWelcomeMessageTitle: 'Le diamo il benvenuto su CertySign',
  homeWelcomeMessageSubtitle: 'Ambiente di gestione delle transazioni e flussi di consegna certificata',
  changePasswordUserTitleText: 'Imposta una nuova password',
  activateUserSubtitleText: 'Per attivare l’utente è necessario inserire una nuova password',
  changePasswordUserSubtitleText: 'Per impostare una nuova password completi i campi',
  activateUserTitleTextWithoutHash: 'URL non valido',
  activateUserSubtitleTextWithoutHash: 'L’URL che sta utilizzando non è valido. Se lo desidera, può richiedere un nuovo URL dall’opzione "Ho dimenticato la password" dalla schermata del log in.',
  goToLogin: 'Log in',
  activeFilters: 'Filtri attivi',
  rememberPassword: 'Ha dimenticato la sua password?',
  rememberPasswordTitleText: 'Ripristinare la password',
  supportContact: 'Contatta l’assistenza',
  rememberPasswordSubTitleText: 'Selezioni un metodo per ripristinare la password',
  rememberPasswordEmailResponse: 'Se l’utente è registrato, riceverà un’e-mail con le istruzioni per modificare la password',
  rememberPasswordWebResponse: 'Se i dati inseriti sono corretti, i nostri agenti del team di assistenza tecnica la contatteranno via e-mail e le forniranno le istruzioni per modificare la password',
  rememberPasswordPhoneResponse: 'Se i dati inseriti sono corretti, i nostri agenti del dipartimento di assistenza tecnica la contatteranno telefonicamente e le forniranno le istruzioni per modificare la password',
  expiredPasswordResponse: 'La sua password è scaduta. Tra pochi istanti riceverà un’e-mail con le istruzioni per impostare una nuova password',
  rememberPasswordSupportEmail: 'E-mail di ripristino',
  rememberPasswordSupportEmailSubtitle: 'Le invieremo un’e-mail da cui potrà recuperare la sua password',
  rememberPasswordSupportEmailText: 'Compili il modulo con l\'e-mail che ha utilizzato per registrarsi su CertySign',
  rememberPasswordSupportWeb: 'Assistenza tramite sito web',
  rememberPasswordSupportWebSubtitle: 'Invia un’e-mail al team di assistenza tecnica di CertySign',
  rememberPasswordSupportWebText: 'Compili i dati e i nostri agenti del dipartimento di assistenza tecnica le risponderanno il prima possibile.',
  emailSupportServiceHours: 'Orari di assistenza:',
  rememberPasswordSupportPhone: 'Assistenza telefonica',
  rememberPasswordSupportPhoneSubtitle: 'Chiamata telefonica al team di assistenza tecnica di CertySign',
  rememberPasswordSupportPhoneText: 'Compili i dati e i nostri agenti del team di assistenza tecnica la chiameranno il prima possibile.',
  phoneSupportServiceHours: 'Orario di assistenza telefonica:',
  rememberPasswordNotSupportBySchedule: 'Opzione disabilitata poiché fuori dall’orario di apertura',
  rememberPasswordMondayToFriday: 'Dal lunedì al venerdì:',
  rememberPasswordSaturdaySundayAndOthers: 'Il sabato, la domenica e i festivi:',
  rememberPasswordNotIncludedDays: '(festivi non inclusi)',
  supportFinalSendMessage: 'La sua richiesta è stata inviata al nostro dipartimento di Assistenza tecnica',
  goBackToMethods: 'Seleziona un altro metodo',
  rememberPasswordContactName: 'Nome e cognome',
  goToC2C: 'Richiedi la chiamata',
  messageDescription: 'Messaggio',
  company: 'Azienda',
  legalCheckboxText: 'Accetto le Condizioni d’uso della pagina e la politica sulla protezione dei dati',
  selectHelps: 'Come possiamo aiutarla?',
  selectHelpsTooltip: 'Assistenza e Aiuto',
  helpManual: 'Vedi manuale',
  helpGuide: 'Vedi guida rapida',
  helpSupport: 'Contatta l\'assistenza tecnica',
  helpSupportPhonePreffered: 'Contatta preferibilmente per telefono',
  helpSupportPhoneSchedule: 'Orari di assistenza telefonica nei giorni lavorativi',
  requiredEmail: 'È necessario inserire l’e-mail',
  requiredPhone: 'È necessario inserire il numero di telefono',
  requiredName: 'È necessario inserire il nome',
  requiredEmailFormat: 'Il formato dell’e-mail è errato',
  requiredPhoneFormat: 'Il formato del numero di telefono è errato',
  requiredTextarea: 'È necessario inserire un messaggio',
  tag_signature: 'Firma elettronica',
  tag_email: 'E-mail',
  tag_sms: 'Sms',
  tag_cert_email: 'Posta elettronica certificata',
  tag_landing: 'Sito web',
  tag_biometric_signature: 'Firma biometrica',
  tag_postal: 'Postale',
  processesViewListTitle: 'Processi',
  processesViewListDescription: 'Elenco di tutti i processi disponibili su CertySign',
  tags: 'Etichette',
  searchs: 'Cerca',
  processInfo: 'Dati del processo',
  processesCategoriesFilter: 'Filtro per categorie',
  processesTagFilter: 'Filtro per etichette',
  processesTagOthers: 'altri',
  processesSearch: 'Cerca processi',
  searchTransactions: 'Consulta transazioni',
  searchTransaction: 'Consulta la transazione',
  deleteFilters: 'Annulla filtri',
  withoutResults: 'Non ci sono risultati',
  withoutProcessResults: 'In base ai suoi permessi o ai criteri di ricerca impostati, non ci sono processi da avviare',
  allProcesses: 'Tutti i processi',
  emailNotValid: 'L’e-mail non è valida',
  phoneNotValid: 'Il numero di telefono non è valido',
  phoneAtLeastNineChars: 'Il numero di telefono deve includere almeno 9 cifre',
  doesntExist: 'Non esiste',
  dontExist: 'Non esistono',
  requiredField: 'Campo obbligatorio',
  errorField: 'Campo errato',
  numberField: 'Campo numerico',
  fiveCharsField: 'Questo campo deve includere 5 caratteri',
  CPvalidField: 'Il CAP non corrisponde alla provincia',
  delete: 'Elimina',
  save: 'Salva',
  file: 'File',
  loading: 'Caricamento in corso',
  countrySelector: 'Seleziona un paese',
  provinceSelector: 'Seleziona una provincia',
  andNumberMore: 'E {count} oltre',
  selectFile: 'Seleziona un file',
  selectFileHtml: 'Seleziona un file html',
  selectFiles: 'Seleziona file',
  allTransactions: 'Tutte le transazioni',
  transactionsPerPage: 'Transazioni per pagina',
  transactionsSend: 'Sono state inviate',
  transactionslower: 'transazioni',
  transactionWithoutId: 'ID in attesa di assegnazione',
  withoutCertificated: 'Certificato non disponibile',
  filter: 'Filtra',
  transactionsProcessFilter: 'Filtro per processo',
  processDataPreviewText: 'Di seguito può trovare il riepilogo della nuova transazione, che comprende il numero di spedizioni necessarie e il centro dei costi associato',
  processDataPreviewTextWithoutCC: 'Di seguito può trovare il riepilogo della nuova transazione, con il numero di spedizioni necessarie',
  transactionsLinkedGroupsFilter: 'Filtro per gruppo',
  transactionsDateFilter: 'Filtro per data',
  numberOfTransactions: 'Numero di spedizioni',
  associatedCECO: 'Centro dei costi associato',
  transactionsCategoryFilter: 'Filtro per categoria',
  transactionsStatusFilter: 'Filtro per stato',
  transactionState: 'Stato della transazione',
  transactionSummary: 'Riepilogo della transazione',
  transactionGeneralData: 'Informazioni sulla transazione',
  transactionData: 'Dati della transazione',
  files: 'File',
  transactionContact: 'Dati del contatto',
  transaction: 'Transazione',
  transactionEvents: 'Eventi della transazione di cui è possibile emettere un certificato',
  transactionWithoutEvents: 'Non ci sono eventi registrati per la transazione',
  transactionProgress: 'Progresso della transazione',
  transactionSubtitle: 'Informazioni sullo stato della transazione',
  transactionDetails: 'Dettagli della transazione',
  transactionEventInfo: 'Informazioni sull’evento',
  eventMeaningPrepend: 'Cosa significa l\'evento',
  eventMeaningAppend: '?',
  eventMeaning: 'Cosa significa questo evento?',
  transactionLaunchedDate: 'Data della creazione',
  transactionLastEvent: 'Ultimo evento ricevuto',
  transactionLastEventDate: 'Data dell’ultimo evento',
  transactionContactName: 'Nome e cognome',
  transactionContactMobilephone: 'Telefono cellulare',
  transactionContactEmail: 'E-mail',
  transactionContactID: 'ID',
  advancedFilters: 'Filtri avanzati',
  group: 'Gruppo',
  date: 'Data',
  hideFilters: 'Nascondi filtri',
  processName: 'Nome del processo',
  usernameTransactionDetail: 'Utente mittente',
  recipientsNumber: 'Numero di destinatari',
  invalidTransaction: 'Dati non validi',
  dataTableShowRecipients: 'Visualizzazione dell’invio <span class=\'font-weight-medium\'>:page:</span> di in corso <span class=\'font-weight-medium\'>{total}</span>',
  transactionRedirectToProcesses: 'Vai ai processi',
  transactionRedirectToTransactions: 'Vai alle transazioni',
  transactionRedirectToNewSend: 'Nuovo invio',
  transactionProcessed: 'Transazione elaborata con successo',
  transactionsProcessed: 'Transazioni elaborate con successo',
  transactionError: 'Transazione elaborata con non validi',
  transactionsError: 'Transazioni elaborate con non validi',
  transactionProcessing: 'Elaborazione della transazione in corso...',
  transactionLaunchedMessage: 'La transazione è stata elaborata con successo. Man mano che vengono processati gli invii, questi saranno visibili nella sezione di',
  transactionsLaunchedMessage: 'Le transazioni sono state elaborate con successo. Man mano che vengono processati gli invii, saranno visibili nella sezione di',
  transactionDownloadFile: 'Vedi certificato',
  transactionProcessingMessage: 'Il sistema sta elaborando la transazione. La preghiamo di attendere qualche istante',
  transactionsProcessingMessage: 'Il sistema sta elaborando le transazioni. La preghiamo di attendere qualche istante',
  transactionsErrorMessage: 'Durante l\'elaborazione delle transazioni dei diversi destinatari sono stati rilevati dati non validi in almeno una transazione. Di seguito potrà vedere l’elenco completo delle transazioni dell’invio',
  transactionErrorMessage: 'Durante l’elaborazione della transazione sono stati rilevati dati non validi',
  recoverProcessQuestion: 'Se inizia un nuovo processo, perderà i passaggi completati finora',
  recoverProcessQuestionAppend: 'Desidera iniziare un nuovo processo?',
  statisticsTransactionsMultipleTitle: 'Transazioni realizzate nei giorni <span class="font-weight-bold">{start_day} di {start_month}</span> e <span class="font-weight-bold">{end_day} di {end_month}</span>',
  statisticsTransactionsOneTitle: 'Transazioni realizzate il {start_day} di {start_month}',
  statisticsTotalTransactionsTitle: 'Totali per tipo di transazione',
  statisticsNoResults: 'La ricerca con i filtri indicati non ha prodotto risultati',
  statisticsProcessTitle: 'Processo:',
  total: 'Totale',
  totalTransactions: 'Transazioni totali',
  totalTransactionsByProcesses: 'Dettaglio delle transazioni per processo',
  accountDescription: 'Configurazione dell’account',
  accountTitle: 'Account',
  contactsDescription: 'Elenco e gestione dei contatti',
  contactsTitle: 'Contatti',
  transactionsDescription: 'Elenco e dettagli delle transazioni',
  transactionsTitle: 'Transazioni',
  statisticsDescription: 'Analisi grafica delle sue transazioni',
  statisticsTitle: 'Statistiche',
  supportDescription: 'In questa sezione potrà ricevere assistenza tecnica oppure accedere al manuale dell’utente',
  supportManualDescription: 'Qui può consultare la documentazione relativa all’uso della piattaforma',
  supportManualTitle: 'Consulta manuale e guida',
  supportManualTitleOnlyManual: 'Consulta manuale',
  supportManualTitleOnlyGuide: 'Consulta guida',
  administrationDescription: 'Amministrazione degli account della piattaforma',
  administrationTitle: 'Amministrazione',
  userActive: 'Utente attivo',
  userInactive: 'Utente disattivato',
  userStateTitle: 'Stato dell’utente',
  groupsTitle: 'Gruppi di permessi',
  groupsDescription: 'Gestione dei gruppi dell’account. Configurazione dei parametri e dei permessi di ogni gruppo.',
  groupsAssignation: 'Gruppo assegnato',
  groupInheritFrom: 'Ereditare permessi da',
  groupDataTitle: 'Informazioni del Gruppo',
  groupUsersPermissionsTitle: 'Permessi, utenti e processi',
  groupPermissionsTabsTitle: 'Permessi',
  groupProcessesAndSubaccounts: 'Account secondari e processi',
  groupLinked: 'Gruppi associati',
  groupPermissionsOwnerText: 'Questo gruppo è il proprietario dell’account e gli sono assegnati tutti i permessi.',
  groupPermissionsOwnerSubaccountProcessesText: 'Questo gruppo è il proprietario dell’account e gli sono assegnati tutti i processi e tutti gli account secondari.',
  groupAssociatedProcesses: 'Processi associati',
  groupAssociatedSubaccounts: 'Account secondari associati',
  newGroup: 'Nuovo Gruppo',
  groupName: 'Nome del gruppo',
  editGroup: 'Modifica del gruppo in corso',
  groupDescription: 'Descrizione',
  groupMinDaysToChange: 'Validità minima della password in giorni',
  groupTabUsersNameTitle: 'Nome e cognome',
  groupTabPermissionSectionTitle: 'Sezioni',
  groupTabPermissionGroupTitle: 'Gruppi',
  groupTabPermissionUsersTitle: 'Utenti',
  usersSearch: 'Cerca Utenti',
  subaccountWithoutProcesses: 'Account secondario senza processi associati',
  selectCostCenter: 'Seleziona un altro centro dei costi',
  adminGroupInfoDescription: 'Da questa sezione può modificare la descrizione, le politiche della sessione e le password della piattaforma.',
  adminGroupInfoUsers: 'Da questa sezione può gestire gli utenti associati a questo gruppo.',
  adminGroupInfoPerms: 'Da questa sezione può gestire i permessi delle funzioni che l’utente può svolgere nella piattaforma.',
  adminGroupInfoProcesses: 'Da questa sezione può gestire l’accesso dell’utente ai processi e agli account secondari. Questo accesso consentirà di avviare, visualizzare le transazioni e le statistiche dei processi assegnati.',
  adminGroupInfoLinked: 'Da questa sezione può gestire la supervisione degli altri gruppi. I gruppi abilitati condivideranno con il gruppo attuale le informazioni sulle transazioni avviate e le statistiche.',
  passwordsQuantity: 'Numero di password conservate',
  passwordExpiration: 'Giorni mancanti alla scadenza della password',
  loginRetriesQuantity: 'Numero di tentativi del log in',
  loginRetriesTime: 'Minuti per riprovare il log in',
  sessionExpiration: 'Minuti mancanti alla scadenza della sessione',
  processAlertExpirationQuestion: 'Desidera continuare?',
  processAlertExpirationPhrase: 'Per ragioni di sicurezza, la sessione scadrà tra pochi istanti',
  processAlertExpiration: 'Avviso di scadenza',
  continueSession: 'Continua',
  usersTitle: 'Utenti',
  activate: 'Attivato',
  deactivate: 'Disattivato',
  usersPerPage: 'Utenti per pagina',
  usersDescription: 'Gestione degli utenti dell’account',
  userInfoTitle: 'Informazioni dell’utente',
  userPermissionTitle: 'Tipo di permesso assegnato',
  newUser: 'Nuovo utente',
  editUser: 'Modifica dell’utente in corso',
  groupTitle: 'Nome del gruppo',
  administrator: 'Amministratore',
  groupLinkedHeader: 'Associato',
  firmaElectronica: 'Firma elettronica',
  comunicacionCertificada: 'Comunicazione certificata',
  publicacionWeb: 'Pubblicazione web',
  groupVisibility: 'Visibilità del gruppo',
  passwordPolicy: 'Politica delle password',
  frontalPolicy: 'Politica della sessione',
  groupData: 'Dati del gruppo',
  groupsPerPage: 'Gruppi per pagina',
  groupsFound: 'Sono stati trovati',
  cancel: 'Annulla',
  description: 'Descrizione',
  subaccountChanged: 'L’account secondario è stato cambiato',
  processContinueTitle: 'Ha un processo in attesa di essere inviato',
  processContinueSubtitle: 'Desidera recuperare il processo o avviarne uno nuovo?',
  newProcess: 'Nuovo processo',
  recover: 'Recupera',
  newShipping: 'Nuovo invio',
  toContinueFillFields: 'Per continuare, compili correttamente i campi',
  passwordRequired: 'È necessario inserire la password',
  userRequired: 'È necessario inserire l’utente',
  fieldAlert: 'Il campo %%fields%% verrà compilato automaticamente con i dati dei destinatari già registrati',
  fieldAlertPlural: 'I campi %%fields%% verranno compilati automaticamente con i dati dei destinatari già registrati',
  fieldNumberPhone: 'telefono',
  fieldCountryCode: 'codice del paese',
  fieldEmail: 'E-mail',
  fieldName: 'Nome',
  fieldSurname: 'Cognome',
  and: 'e',
  certificate: 'Certificato',
  country: 'Paese',
  continueEditing: 'Modifica i dati dell’invio',
  second: 'secondo',
  seconds: 'secondi',
  nextRefreshAvailableAt: 'Prossimo aggiornamento disponibile tra',
  fastSearch: 'Ricerca veloce',
  update: 'Aggiorna',
  process: 'Processo',
  transactionId: 'ID della transazione',
  transactionClientId: 'Riferimento del cliente',
  createdDate: 'creato',
  updatedAt: 'Aggiornato',
  state: 'Stato',
  noGroupAssigned: 'Senza gruppo selezionato',
  contact_country_code: 'Codice del Paese',
  contact_email: 'E-mail',
  contact_id: 'Riferimento cliente',
  contact_name: 'Nome',
  contact_phone: 'Numero di telefono',
  contact_surname: 'Cognome',
  contact_complete_name: 'Nome completo',
  certificateValidDownload: 'Il certificato è stato scaricato correttamente',
  certificateFailDownload: 'Non è stato possibile scaricare il certificato',
  graphicBarValidDownload: 'La grafica è stata scaricata correttamente',
  graphicBarFailDownload: 'Non è stato possibile scaricare la grafica',
  //events
  queued: 'La transazione è in attesa di essere elaborata.',
  new_communication: 'La transazione è stata creata con successo.',
  orchestrator_new_communications_communication_received: 'La transazione è stata elaborata con successo.',
  commstracker_generate_url_generated: 'È stato creato un link dell’URL accorciato.',
  commstracker_generate_url_click: 'È stato aperto il link della transazione.',
  communication_send_sms_sent: 'È stato depositato il messaggio di testo nel centro del servizio di spedizione del fornitore. Questi, a sua volta, gestirà l’invio verso il destinatario finale.',
  communication_send_sms_not_sent: 'Non è stato possibile depositare il messaggio di testo nel centro del servizio di spedizione del fornitore.',
  communication_send_sms_delivered: 'Il messaggio di testo è stato consegnato al destinatario.',
  communication_send_sms_not_delivered: 'Non è stato possibile consegnare il messaggio di testo al destinatario.',
  generate_otp_code_otp_generated: 'È stata creata una password monouso (OTP).',
  generate_certificate_generated: 'È stato creato il certificato di completamento della transazione, che raccoglie il contenuto della comunicazione e le prove associate agli eventi che si sono verificati durante il processo.',
  communication_send_email_delivered: 'Il messaggio è stato consegnato al fornitore del servizio di posta elettronica (ESP) del destinatario.',
  communication_send_email_complaint: 'Il destinatario del messaggio ha segnato l’e-mail ricevuta come non desiderata.',
  communication_send_email_queued: 'Il messaggio di posta elettronica è stato messo in coda.',
  communication_send_email_deferred: 'Il messaggio di posta elettronica è stato messo in coda e sarà consegnato appena possibile.',
  communication_send_email_bounce: 'Il messaggio di posta elettronica è stato respinto.',
  communication_send_email_sent: 'Il messaggio di testo è stato depositato nel servizio di spedizione del fornitore. Questi, a sua volta, gestirà l’invio verso il destinatario finale.',
  communication_send_email_sender_not_allowed: 'Non è stato possibile inviare il messaggio di posta elettronica poiché il mittente non è autorizzato a farlo.',
  communication_send_email_open: 'Il messaggio di posta elettronica è stato aperto dal destinatario.',
  communication_send_email_click: 'Il destinatario del messaggio di posta elettronica ha cliccato sul link.',
  communication_send_email_block: 'L’indirizzo di posta elettronica del destinatario fa parte dell\'elenco degli indirizzi esclusi, pertanto il messaggio non è stato inviato.',
  communication_send_email_unsubscribe: 'Il destinatario del messaggio di posta elettronica desidera annullare la sua iscrizione alla lista di distribuzione.',
  communication_send_email_spam: 'Il messaggio di posta elettronica è stato identificato come non desiderato.',
  communication_send_email_hard_blacklisted: 'L’indirizzo di posta elettronica del destinatario fa parte dell\'elenco degli indirizzi esclusi, pertanto il messaggio non è stato inviato.',
  communication_send_email_complaint_blacklisted: 'L’indirizzo di posta elettronica del destinatario fa parte dell\'elenco dei reclami, pertanto il messaggio non è stato inviato.',
  document_upload_s3_uploaded: 'Il certificato di completamento della transazione è stato conservato in modo sicuro. Tale certificato raccoglie il contenuto della comunicazione e le prove associate agli eventi che si sono verificati durante il processo.',
  communication_send_telegram_delivered: 'Il messaggio di testo è stato consegnato al destinatario.',
  communication_send_telegram_not_delivered: 'Non è stato possibile consegnare il messaggio di testo',
  web_framework_generated: 'È stata creata un’interfaccia grafica web per consentire al destinatario di interagire.',
  web_framework_solicitud_otp: 'È stata richiesta una password monouso (OTP).',
  web_framework_signable_document_loaded: 'È stato caricato sull’interfaccia web un documento da firmare.',
  web_framework_otp_simple_signature_ok: 'La documentazione è stata firmata con la password monouso.',
  web_framework_commited: 'Il processo all’interno dell’interfaccia web è stato completato.',
  web_framework_document_signed: 'La documentazione è stata firmata.',
  web_framework_form_based_identification: 'Il passaggio di autenticazione tramite modulo è stato completato.',
  web_framework_certificate_based_identification: 'L’accesso tramite certificato personale è stato eseguito con successo.',
  web_framework_document_based_identification: 'È stato identificato correttamente nel modulo.',
  web_framework_certificate_based_identification_failed: 'Il processo di identificazione tramite certificato non è andato a buon fine.',
  web_framework_document_based_identification_failed: 'Il processo di identificazione tramite documento non è andato a buon fine.',
  web_framework_form_based_identification_failed: 'Il processo di identificazione tramite modulo non è andato a buon fine.',
  web_framework_web_framework_expired: 'La transazione è scaduta.',
  web_framework_documentation_downloaded: 'Il destinatario ha scaricato la documentazione.',
  web_framework_electronic_notificacion_rejected: 'Il destinatario ha rifiutato la notifica elettronica.',
  web_framework_gdpr_formulary_committed: 'Il destinatario ha compilato il modulo per il consenso.',
  web_framework_sepa_formulary_committed: 'Il destinatario ha compilato il modulo SEPA.',
  web_framework_requested_otp_code: 'È stata richiesta una password monouso (OTP).',
  web_framework_entered_wrong_otp_code: 'La password monouso (OTP) inserita dal destinatario è errata.',
  web_framework_entered_timedout_otp_code: 'La password monouso è scaduta.',
  web_framework_entered_correct_otp_code: 'La password monouso (OTP) inserita dal destinatario è corretta.',
  web_framework_electronic_notificacion_accepted: 'Il destinatario ha accettato la notifica elettronica.',
  web_framework_signature_rejected: 'Il destinatario ha rifiutato la richiesta di firma elettronica.',
  web_framework_request_otp_code_too_early: 'Il destinatario ha richiesto la password monouso (OTP) prima del tempo previsto.',
  web_framework_capture_data_commited_no_response: 'È stato rilevato un tentativo di invio di un modulo senza dati nelle risposte.',
  web_framework_capture_data_commited: 'L’invio dei dati è stato eseguito con successo',
  web_framework_document_previewed: 'Il destinatario ha visualizzato in anteprima la documentazione.',
  web_framework_document_simply_signed: 'La documentazione è stata firmata elettronicamente.',
  document_upload_alastria_uploaded: 'L’hash del certificato di completamento è stato caricato al nodo di CertySign nella Rete Blockchain di Alastria.',
  publish_downloadable_file_generated: 'La documentazione da pubblicare sull’interfaccia web è stata creata.',
  publish_downloadable_file_open: 'La documentazione da pubblicare sull’interfaccia web è stata aperta.',
  document_sign_signed: 'Il certificato è stato firmato',
  rpc_callback_published: 'Il ritorno della chiamata RPC è stato pubblicato',
  loop_add_added: 'La transazione è stata aggiunta al circolo di tentativi.',
  loop_add_tick: 'È stato realizzato un tentativo aggiuntivo',
  loop_add_timeout: 'È stato raggiunto il limite di tentativi',
  loop_cancel_canceled: 'Il circolo è stato annullato',
  identification_create_identification_generated: 'Il processo attraverso cui l’utente si identificherà durante una transazione è stato creato.',
  identification_create_identification_ok: 'L’identificazione è avvenuta con successo',
  test_load_processed: 'La prova di caricamento è stata elaborata',
  instance_delete_deleted: 'L’istanza equivalente alla transazione è stata annullata.',
  unzip_file_extracted: 'La cartella è stata decompressa.',
  communicalia_consent_accepted: 'Il destinatario della comunicazione ha accettato il consenso.',
  communicalia_consent_declined: 'Il destinatario della comunicazione ha rifiutato il consenso.',
  communicalia_consent_no_data: 'Il destinatario della comunicazione non ha risposto.',
  robinson_list_accepted: 'Il destinatario non si trova nell’elenco di Robinson',
  robinson_list_declined: 'Il destinatario è registrato nell’elenco Robinson',
  robinson_list_no_data: 'Il destinatario è registrato nell’elenco Robinson',
  publish_embeddable_file_generated: 'È stato creato il file incorporato per l’interfaccia web.',
  document_upload_sftp_uploaded: 'La documentazione è stata caricata tramite SFTP.',
  file_delete_deleted: 'Il file è stato cancellato.',
  disable_framework_web_framework_web_disabled: 'L’interfaccia associata alla transazione è stata disattivata.',
  generate_onespan_transaction_transaction_create: 'È stata creata la transazione della firma elettronica con OneSpan',
  generate_onespan_transaction_transaction_create_error: 'Si è verificato un errore durante la transazione della firma elettronica in OneSpan.',
  generate_onespan_transaction_transaction_complete: 'La transazione della firma elettronica in OneSpan è stata completata.',
  generate_onespan_transaction_transaction_expire: 'La transazione della firma elettronica in OneSpan è scaduta.',
  generate_onespan_transaction_transaction_decline: 'La transazione della firma elettronica in OneSpan è stata rifiutata.',
  generate_onespan_transaction_email_bounce: 'Il messaggio di posta elettronica che corrisponde alla transazione della firma elettronica di One Span è tornato indietro.',
  generate_onespan_transaction_transaction_opt_out: 'Il destinatario ha scelto di non firmare la transazione.',
  generate_onespan_transaction_transaction_delete: 'La transazione della firma elettronica in OneSpan è stata eliminata.',
  generate_onespan_transaction_transation_desactivate: 'La transazione della firma elettronica in OneSpan è stata disattivata.',
  generate_onespan_transaction_kba_failure: 'Per ragioni di sicurezza la transazione è stata bloccata durante il log in o il processo.',
  generate_onespan_transaction_transaction_sent: 'La transazione della firma elettronica in OneSpan è stata inviata.',
  generate_onespan_transaction_transaction_sent_error: 'L’invio della transazione della firma elettronica in OneSpan non è andato a buon fine.',
  generate_onespan_transaction_package_trash: 'La transazione è stata spostata sul cestino di OneSpan',
  get_onespan_files_files_onespan_generated: 'Sono state create le cartelle necessarie per OneSpan',
  communication_send_postal_generated: 'È stato creato l’invio della comunicazione postale.',
  communication_send_postal_sent_to_factory: 'L’invio è stato caricato sulla piattaforma di comunicazione postale.',
  communication_send_postal_printed: 'La documentazione associata all’invio postale è stata stampata.',
  communication_send_postal_pre_admission_requested: 'L’invio della comunicazione è in corso di pre-registrazione telematica sul sito web dell’operatore postale.',
  communication_send_postal_pre_admitted: 'L’invio della comunicazione è stato registrato telematicamente dall’operatore postale, prima del deposito.',
  communication_send_postal_sent_to_logistic_operator: 'La comunicazione è in corso di deposito presso l\'operatore postale.',
  communication_send_postal_on_the_way_to_logistic_operator: 'La comunicazione è in arrivo all’ufficio postale più vicino al destinatario della comunicazione.',
  communication_send_postal_received_by_logistic_operator: 'La comunicazione si trova nell’ufficio postale più vicino al destinatario della comunicazione.',
  communication_send_postal_not_received_by_logistic_operator: 'La comunicazione non ha raggiunto l’ufficio postale.',
  communication_send_postal_on_delivery: 'La comunicazione postale è in arrivo.',
  communication_send_postal_delivered_to_recipient: 'La comunicazione postale è stata consegnata al destinatario.',
  communication_send_postal_returned_to_postal_office: 'La comunicazione si trova nell’ufficio postale e l’avviso di giacenza si trova nella cassetta del destinatario della comunicazione',
  communication_send_postal_delivered_into_postal_office: 'La comunicazione è stata consegnata presso l’ufficio postale.',
  communication_send_postal_generic_delivered: 'La comunicazione postale è stata consegnata al destinatario.',
  communication_send_postal_returned: 'È stato impossibile effettuare la consegna della comunicazione postale. Pertanto è stata rispedita al mittente.',
  communication_send_postal_canceled: 'L’invio della comunicazione postale è stato annullato.',
  communication_send_postal_lost: 'La comunicazione postale è stata smarrita.',
  communication_send_postal_expired: 'La comunicazione postale è stata rispedita al mittente poiché non è stata ritirata dal destinatario entro i 15 giorni lavorativi successivi all\'avviso lasciato nella sua cassetta.',
  communication_send_postal_rejected: 'La comunicazione postale è stata rifiutata dal destinatario.',
  communication_send_postal_stolen: 'La comunicazione postale è stata rubata o sottratta.',
  communication_send_postal_outside_sicer: 'La comunicazione non è registrata presso l\'operatore postale.',
  communication_send_postal_bad_postal_address: 'L’indirizzo postale dell’invio non è corretto.',
  communication_send_postal_passed_away: 'Il destinatario dell\'invio della comunicazione è deceduto.',
  communication_send_postal_other: 'Altro.',
  communication_send_postal_no_information: 'Non ci sono informazioni relative all’invio della comunicazione presso l\'operatore postale.',
  communication_send_postal_received_pee: 'È stata ricevuta una prova elettronica di consegna (PEC) dell’invio.',
  communication_send_postal_certificate_returned: 'È stato creato il certificato di restituzione con la Prova dell’impossibilità di consegna dell’operatore postale.',
  communication_send_postal_unknown: 'Il destinatario della comunicazione è sconosciuto all’indirizzo postale indicato.',
  zip_files_path_zipped: 'Uno o più documenti sono stati compressi.',
  intromission_api_retries_muted: 'Sono stati disattivati i tentativi della transazione',
  intromission_api_retries_unmuted: 'Sono stati attivati i tentativi della transazione',
  intromission_api_instance_canceled: 'La transazione è stata annullata',
  antivirus_filtered: 'L’antivirus ha filtrato la transazione poiché sono state caricate risorse potenzialmente pericolose.',
  //API messages
  user_operation_error_creating_user: 'Si è verificato un errore durante la creazione dell’utente',
  user_operation_user_already_exists: 'L’utente esiste già',
  user_operation_user_created: 'Utente creato con successo',
  user_operation_user_updated: 'Utente aggiornato con successo',
  user_operation_no_exists: 'L’utente non esiste',
  user_operation_password_changed: 'La password è stata aggiornata',
  user_operation_pass_incorrect: 'La combinazione di utente e password è errata',
  user_operation_password_expired: 'La password è scaduta',
  user_operation_logout: 'Il log out è avvenuto con successo',
  user_operation_blocked_user: 'L’utente è bloccato',
  user_operation_wrong_subdomain: 'Lo username o la password non sono corretti',
  user_operation_max_pass_tries: 'Utente bloccato. È stato superato il limite di tentativi possibili',
  user_operation_deleted: 'Utente cancellato con successo',
  user_operation_error_deleted: 'Errore durante la cancellazione dell’utente',
  user_has_been_disabled: 'Utente disattivato',
  user_operation_error_user_not_found: 'Se l’indirizzo e-mail indicato esiste, a breve riceverà un’e-mail con le istruzioni per cambiare la sua password',
  user_operation_remember_password_email: 'Se l’indirizzo e-mail indicato esiste, a breve riceverà un’e-mail con le istruzioni per cambiare la sua password',
  email_sent_to_support: 'Richiesta inviata correttamente',
  user_operation_error_change_password_min_days: 'Non è possibile cambiare la password poiché non è trascorso il periodo minimo richiesto per questa operazione',
  user_operation_error_passwords_mismatch: 'Le password non sono uguali',
  user_operation_error_password_has_been_used_before: 'La password è stata usata in precedenza. Ne scelga una nuova',
  user_or_group_no_exists: 'L\'utente o il gruppo non esiste',
  user_error_already_active: 'L’utente è già attivo',
  activation_email_resend: 'È stata inviata una nuova e-mail di attivazione',
  group_operation_error_creating_group: 'Si è verificato un errore durante la creazione del gruppo',
  group_operation_group_already_exists: 'Il gruppo esiste già',
  group_operation_group_created: 'Gruppo creato con successo',
  group_operation_group_updated: 'Gruppo :group_name: aggiornato con successo',
  //Terms and conditions
  termsConditionsDPOTitle: 'Delegato della protezione dei dati',
  termsConditionsTitular: 'Titolare',
  termsConditionsNIF: 'Codice fiscale',
  termsConditionsDomicilioSocial: 'Indirizzo fiscale',
  termsConditionsDatosRegistrales: 'Dati registro',
  termsConditionsContacto: 'Contatto:',
  termsConditionsDPOEmailTitle: 'Email Customer Comms',
  termsConditionsDPOEmail: 'E-mail del Delegato della Protezione dei dati',
  termsConditions1: 'CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (Di seguito CCOMMS) in qualità di titolare e responsabile della presente APP, in conformità a quanto previsto dall’articolo 10 della Legge 34/2002 dell’11 luglio sui Servizi della società dell’informazione e del commercio elettronico, mette a disposizione degli utenti le seguenti informazioni generali di questa APP:',
  termsConditionsObjetoTitulo: 'OGGETTO',
  termsConditionsObjetoParrafo1: 'Le presenti Condizioni Generali d’uso (di seguito "CGU") regolano l’uso dell’Applicazione CertySign (di seguito, l’APP) messa a disposizione degli utenti da CCOMMS al fine di poter eseguire la richiesta inoltrata.',
  termsConditionsObjetoParrafo2: 'Pertanto, le presenti CGU si applicheranno all’attività promozionale, alle informazioni e ai servizi prestati attraverso l’App; regolano, quindi, sia la semplice navigazione all’interno dell\'APP che l’eventuale contrattazione di servizi in essa proposti, fermo restando che quest’ultima dovrà inoltre essere conforme sia alle CGU descritte di seguito che alle Condizioni Generali di Contrattazione applicabili e alle Condizioni Specifiche, se del caso.',
  termsConditionsUsuariosTitulo: 'UTENTI',
  termsConditionsUsuariosParrafo1: 'L’accesso e/o l’uso dell’APP attribuisce a chi lo effettua la condizione di utente che, da quel momento, accetta pienamente e senza riserve le presenti CGU, le Condizioni Specifiche che, se del caso, completano, modificano o sostituiscono le CGU in relazione a determinati servizi e contenuti dell’APP, e si impegna a rispettare le restrizioni d’uso dell’APP stabilite da CCOMMS.',
  termsConditionsAccesoAPPTitulo: 'ACCESSO ALL’APP',
  termsConditionsAccesoAPPParrafo1: 'L’accesso e l’uso dell’APP sono riservati. Per accedervi sarà necessaria la registrazione dell’utente.',
  termsConditionsUsoAPPTitulo: 'USO DELL’APP',
  termsConditionsUsoAPPParrafo1: 'L’utente si impegna a utilizzare l’APP, i suoi servizi e contenuti in buona fede,nel rispetto della legislazione vigente, dell’ordine pubblico e degli usi generalmente accettati. Analogamente, è severamente proibito l’uso dell’APP con finalità illecite o lesive nei confronti di CCOMMS o di terzi ovvero che possano in qualsiasi modo danneggiare o impedire il normale funzionamento dell’APP.',
  termsConditionsResposabilidadTitulo: 'RESPONSABILITÀ DELL’UTENTE PER EVENTUALI DANNI O PERDITE',
  termsConditionsResposabilidadParrafo1: 'L’utente è l’unico responsabile dell’uso dell’APP. Tale responsabilità si estenderà all’uso di qualsiasi password o codice assegnato per accedere all’APP o a uno qualsiasi dei servizi in essa inclusi da parte dell’utente o di terze parti.',
  termsConditionsResposabilidadParrafo2: 'Fermo restando quanto sopra, CCOMMS si riserva il diritto di negare, inabilitare o limitare in qualsiasi momento e senza previo avviso, l’accesso all’APP agli utenti che non rispettino le presenti CGU, le norme stabilite da CCOMMS o dai suoi collaboratori o che possano alterare il corretto funzionamento, l’immagine, la credibilità e/o il prestigio di CCOMMS o dei suoi collaboratori.',
  termsConditionsResposabilidadParrafo3: 'In tal senso, CCOMMS si riserva la facoltà di eliminare o limitare l\'accesso all’APP ad alcuni Utenti, qualora si osservassero comportamenti che CCOMMS consideri contrari alle presenti Condizioni d’uso, alla Legge, alle norme stabilite da CCOMMS o dai suoi collaboratori ovvero che possano alterare il corretto funzionamento, l’immagine, la credibilità e/o il prestigio di CCOMMS o dei suoi collaboratori. Inoltre, CCOMMS potrà esigere che gli Utenti siano fatti responsabili per eventuali danni causati da un cattivo uso dell’APP.',
  termsConditionsResposabilidadParrafo4: 'CCOMMS non garantisce la legittimità, affidabilità e utilità, nonché veridicità o esattezza dei contenuti trasmessi dagli utenti quando utilizzano l’APP.',
  termsConditionsResposabilidadParrafo5: 'CCOMMS non controlla l’utilizzo dell’APP da parte degli utenti, né garantisce che ne facciano un uso conforme alle presenti Condizioni Generali.',
  termsConditionsResposabilidadParrafo6: 'CCOMMS non controlla né garantisce l’assenza di virus informatici nei servizi prestati da terzi attraverso l’APP che possano produrre alterazioni nel sistema informatico (software e hardware) nonché nei documenti e nelle cartelle elettroniche salvati sul suo sistema informatico.',
  termsConditionsPropiedadIntelectualTitulo: 'PROPRIETÀ INTELLETTUALE',
  termsConditionsPropiedadIntelectualParrafo1: 'Qualsiasi uso o modifica del Materiale o dei Contenuti per finalità diverse da quelle autorizzate nelle Condizioni Generali sarà considerato una violazione delle leggi internazionali di "copyright" che proteggono i diritti d’autore.',
  termsConditionsPropiedadIntelectualParrafo2: 'Relativamente ai contenuti, ne è proibita:',
  termsConditionsPropiedadIntelectualListaELemento1: 'La riproduzione, copia, distribuzione, modifica o manipolazione, in qualsiasi modo e per qualsiasi finalità, salvo che sia legalmente concessa o che si disponga di autorizzazione preventiva e per iscritto da parte dei legittimi proprietari.',
  termsConditionsPropiedadIntelectualListaELemento2: 'Qualsiasi violazione dei diritti di CCOMMS o dei legittimi titolari.',
  termsConditionsPropiedadIntelectualListaELemento3: 'L’utilizzo dei contenuti per finalità commerciali o pubblicitarie diverse da quelle strettamente permesse.',
  termsConditionsPropiedadIntelectualParrafo3: 'CCOMMS garantisce che i contenuti, inclusi quelli di proprietà intellettuale, non sono di carattere illecito né violano la normativa vigente. Pertanto, i contenuti dell’APP non saranno di carattere xenofobo, pornografico, discriminatorio, razzista né fomenteranno in alcun modo la violenza.',
  termsConditionsPropiedadIntelectualParrafo4: 'Inoltre, CCOMMS adotterà le misure legali che ritiene convenienti per prevenire qualsiasi tipo di comportamento illegale o contrario alla Legge.',
  termsConditionsModificacionUnilateralTitulo: 'MODIFICA UNILATERALE',
  termsConditionsModificacionUnilateralParrafo1: 'Se lo ritiene opportuno CCOMMS potrà modificare unilateralmente e senza preavviso la struttura e il progetto dell’APP, nonché modificare o eliminare i servizi, i contenuti e le condizioni di accesso e/o uso dell’APP.',
  termsConditionsExclusionGarantiasTitulo: 'ESCLUSIONE DELLE GARANZIE E RESPONSABILITÀ',
  termsConditionsExclusionGarantiasParrafo1: 'CCOMMS non concede in nessun caso garanzie né si assume la responsabilità di eventuali danni di qualsivoglia natura che possano derivare in:',
  termsConditionsExclusionGarantiasListaELemento1: 'Violazione della Legge, della morale o della prassi e dell’ordine pubblico; uso negligente o incorretto dei prodotti e/o servizi offerti o con finalità illecite o contrarie a quanto previsto dalle presenti CGU o dalle condizioni applicabili, se del caso.',
  termsConditionsExclusionGarantiasListaELemento2: 'Si esime da ogni responsabilità inerente all’acquisto o richiesta di servizi, beni e prodotti che possano essere acquistati o richiesti da terzi attraverso l\'accesso all’APP di CCOMMS, soprattutto nei casi in cui il processo di acquisto o richiesta viene realizzato direttamente nell’APP di terzi e anche in presenza di marchi o "frame" con gli elementi grafici dell’app (Branding).',
  termsConditionsExclusionGarantiasListaELemento3: 'Mancata disponibilità, manutenzione ed effettivo funzionamento dell’APP e/o dei suoi servizi e contenuti.',
  termsConditionsExclusionGarantiasListaELemento4: 'La mancata utilità, adeguamento o validità dell’APP e/o dei suoi servizi o contenuti per soddisfare le esigenze, le attività o i risultati specifici e le aspettative degli utenti.',
  termsConditionsExclusionGarantiasListaELemento5: 'L’esistenza di virus, programmi maliziosi o lesivi.',
  termsConditionsExclusionGarantiasListaELemento6: 'La ricezione, l’ottenimento, la conservazione, diffusione o trasmissione dei contenuti da parte degli utenti.',
  termsConditionsExclusionGarantiasListaELemento7: 'L’illiceità o la mancata veracità, esattezza, affidabilità, pertinenza, aggiornamento e completezza dei contenuti e delle informazioni trasmesse o messe a disposizione degli utenti, inclusi i servizi e le informazioni forniti da terze parti o dagli utenti tramite l’APP.',
  termsConditionsExclusionGarantiasListaELemento8: 'La violazione degli obblighi e degli impegni da parte di terzi relativi ai servizi prestati agli utenti attraverso l’APP.',
  termsConditionsExclusionGarantiasListaELemento9: 'Dei contenuti, archivi, informazioni, pubblicità, opinioni, concetti e immagini che non dipendono dall’APP, né sono gestiti da CCOMMS. Altresì si solleva da ogni responsabilità sul cattivo uso dei contenuti sull’APP, essendo quest’ultimo una responsabilità esclusiva delle persone che vi accedono o li utilizzano.',
  termsConditionsExclusionGarantiasListaELemento10: 'L’uso scorretto delle password degli utenti per accedere ai contenuti del servizio dell’APP richiesto e le conseguenze di qualsiasi natura derivanti da un cattivo uso da parte degli utenti, la perdita o smarrimento o l’uso indebito da parte di terzi non autorizzati.',
  termsConditionsExclusionGarantiasListaELemento11: 'L’incapacità di qualsiasi utente o la sostituzione di identità di terze parti effettuata dall’utente.',
  termsConditionsExclusionGarantiasListaELemento12: 'Dei contenuti forniti dagli utenti.',
  termsConditionsProteccionDatosTitulo: 'PROTEZIONE DEI DATI',
  termsConditionsProteccionDatosParrafo1: 'È possibile consultare le informazioni dettagliate sulla Protezione dei Dati sul nostro sito web',
  termsConditionsLeyJurisdiccionTitulo: 'NORMATIVA APPLICABILE E GIURISDIZIONE',
  termsConditionsLeyJurisdiccionParrafo1: 'Tutte le Condizioni, Generali e Specifiche, contenute nella presente APP sono disciplinate dalla normativa spagnola. Eventuali conflitti, controversie o situazioni derivanti dalle Condizioni Generali e specifiche saranno di competenza dei Tribunali della città di Madrid, e le parti rinunciano a qualsiasi giurisdizione che possa competere loro.',
  termsConditionsProteccionDatosTitle: 'Maggiori informazioni sulla Protezione dei dati',
  termsConditionsProteccionDatos: 'Protezione dei dati',
  //Privacy Policies
  privacyPoliciesConditionsTitle: 'Protezione dei dati di MailTecK & Customer Communications Tecknalia',
  privacyPoliciesConditionsParrafo1: 'In conformità a quanto previsto dal Regolamento (UE) 2016/679 del Parlamento e del Consiglio Europeo del 27 aprile 2016 relativo alla protezione delle persone fisiche in merito al trattamento dei dati personali e alla libera circolazione degli stessi (RGPD), e nella Legge Organica 3/2018 del 5 dicembre sulla Protezione dei Dati personali e garanzia dei diritti digitali (LOPD-GDD), nonché nella normativa vigente sulla protezione dei dati, si informano gli utenti dell’APP che volontariamente e liberamente hanno espresso il proprio consenso chiaro e univoco per il trattamento dei dati personali accettando la casella "Politica di protezione dei dati", che i propri dati saranno trattati dall\'azienda che in cui si sono registrati, sia essa MAILTECK S.A. O CUSTOMER COMMUNICATIONS TECKNALIA S.L., che verranno denominate congiuntamente GRUPO MAILCOMMS.',
  privacyPoliciesConditionsResponsable1Title: 'Responsabile 1',
  privacyPoliciesConditionsResponsable2Title: 'Responsabile 2',
  privacyPoliciesConditionsFinalidadTitle: 'Finalità',
  privacyPoliciesConditionsLegitimacionTitle: 'Legittimità',
  privacyPoliciesConditionsDestinatariosTitle: 'Destinatari',
  privacyPoliciesConditionsDerechosTitle: 'Diritti',
  privacyPoliciesConditionsInformacionAdicionalTitle: 'Informazioni aggiuntive',
  privacyPoliciesConditionsPoliticaInformacion1Title: 'Politica informativa 1',
  privacyPoliciesConditionsPoliticaInformacion2Title: 'Politica informativa 2',
  privacyPoliciesConditionsIdentidadTitle: 'Identità',
  privacyPoliciesConditionsNIFTitle: 'Codice fiscale',
  privacyPoliciesConditionsDireccionTitle: 'Indirizzo',
  privacyPoliciesConditionsTelefonoTitle: 'Numero di telefono',
  privacyPoliciesConditionsCorreoTitle: 'E-mail',
  privacyPoliciesConditionsDPDTitle: 'Contatto con il DPD',
  privacyPoliciesConditionsDPDCustomerCommsTitle: 'Delegato della Protezione dei dati di Customer Comms',
  privacyPoliciesConditionsDPDMailteckTitle: 'Delegato della Protezione dei dati di MailTeck',
  privacyPoliciesConditionsFinalidadIntro: 'Gestire e inviare i servizi richiesti',
  privacyPoliciesConditionsFinalidadParrafo1: 'Il trattamento dei dati forniti fa parte dell’esecuzione del contratto firmato da lei o dalla sua azienda con GRUPO MAILCOMMS, al fine di gestire ed erogare i servizi accordati nello stesso, nonché per dare assistenza all’applicazione CertySign.',
  privacyPoliciesConditionsFinalidadParrafo2: 'GRUPO MAILCOMMS tratterà i dati forniti solo alle finalità su citate e non saranno trattati in maniera incompatibile con le suddette finalità. I dati personali forniti saranno conservati per tutta la durata del rapporto contrattuale.',
  privacyPoliciesConditionsLegitimacionIntro: 'Esecizione del contratto',
  privacyPoliciesConditionsLegitimacionParrafo1: 'I dati sono stati forniti da lei o dalla sua azienda tramite i canali stabili per poter darle accesso all’applicazione CertySign, come conseguenza del contratto di prestazione del servizio firmato.',
  privacyPoliciesConditionsDestinatariosIntro: 'Non vengono ceduti a terzi',
  privacyPoliciesConditionsDestinatariosParrafo1: 'Per esigenze legali se previsto da una norma.',
  privacyPoliciesConditionsDerechosIntro: 'Potrà esercitare i suoi diritti nelle modalità e nei termini previsti dalla legge.',
  privacyPoliciesConditionsDerechosElem1ListaTitulo: 'Conferma generale:',
  privacyPoliciesConditionsDerechosElem1ListaDescripcion: 'Qualsiasi persona ha diritto a ottenere conferma sul trattamento dei propri dati da parte del GRUPPO MAILCOMMS.',
  privacyPoliciesConditionsDerechosElem2ListaTitulo: 'Diritti ARCO:',
  privacyPoliciesConditionsDerechosElem2ListaDescripcion: 'Le persone interessate hanno diritto ad accedere ai propri dati personali, nonché richiedere la rettifica dei dati inesatti o, se del caso, richiederne l’eliminazione quando i dati non siano più necessari ad adempiere alle finalità per cui sono stati raccolti.',
  privacyPoliciesConditionsDerechosElem3ListaTitulo: 'Limiti del trattamento:',
  privacyPoliciesConditionsDerechosElem3ListaDescripcion: 'In determinate circostanze, gli interessati potranno richiedere la limitazione del trattamento dei propri dati, nel cui caso saranno conservati solo per l\'esercizio o la difesa di eventuali reclami.',
  privacyPoliciesConditionsDerechosElem4ListaTitulo: 'Opposizione al trattamento:',
  privacyPoliciesConditionsDerechosElem4ListaDescripcion: 'In determinate circostanze e per motivi relativi alla propria situazione personale, gli interessati potranno opporsi al trattamento dei propri dati. Pertanto, GRUPO MAILCOMMS interromperà il trattamento dei dati, salvo per motivi legittimi imperiosi e per l\'esercizio o la difesa di eventuali reclami.',
  privacyPoliciesConditionsDerechosElem5ListaTitulo: 'Portabilità dei dati:',
  privacyPoliciesConditionsDerechosElem5ListaDescripcion: 'In determinate circostanze e per motivi relativi alla propria situazione personale, gli interessati potranno richiedere di ricevere i dati personali forniti nel modulo di contatto in un formato strutturato, di uso comune e leggibile al computer, nonché di trasmettere i suddetti dati a un altro responsabile del trattamento, senza impedimento alcuno.',
  privacyPoliciesConditionsDerechosElem6ListaTitulo: 'Reclamo dinnanzi a un organismo pubblico:',
  privacyPoliciesConditionsDerechosElem6ListaDescripcion: 'Qualora considerasse che sia stato violato il suo diritto alla protezione dei dati personali, potrà presentare un reclamo all’Agenzia Spagnola della Protezione dei dati',
  privacyPoliciesConditionsDerechosAEPDTitle: 'AEPD',
  privacyPoliciesConditionsDerechosElem7ListaTitulo: 'Canale per esercitare i diritti.',
  privacyPoliciesConditionsDerechosElem7ListaDescripcion: 'Gli interessati potranno esercitare i propri diritti riconosciuti dalla normativa nei confronti di ciascuno dei responsabili rivolgendosi all’indirizzo abilitato come DPO nella sezione "Responsabile".',
  privacyPoliciesConditionsInformacionAdicionalIntro: 'Maggiori informazioni su Protezione dei dati.',
  privacyPoliciesConditionsInformacionAdicionalLista1: 'I dati personali ottenuti dai canali che le aziende del GRUPPO MAILCOMMS hanno preposto per la gestione e i servizi richiesti da lei o dalla sua azienda.',
  privacyPoliciesConditionsInformacionAdicionalLista2: 'Le categorie di dati trattati saranno i dati forniti per lo sviluppo di ciascun servizio richiesto.',
  privacyPoliciesConditionsInformacionAdicionalLista3: 'GRUPO MAILCOMMS non tratta i dati particolarmente protetti.',
  privacyPoliciesConditionsPoliticaInformacionIntro: 'MailTeck, S.A. Politica di Sicurezza e Riservatezza delle Informazioni basata sulla norma ISO 27001 -> Altro.',
  privacyPoliciesConditionsPoliticaInformacio2nIntro: 'Customer Communication Tecknalia, S.L. Politica di Sicurezza e Riservatezza delle Informazioni basata sulla norma ISO 27001 -> Altro.',
  privacyPoliciesConditionsPoliticaInformacionTituloH: 'ALLEGATO I: POLITICA DEL SISTEMA DI GESTIONE DELLA SICUREZZA E RISERVATEZZA DELLE INFORMAZIONI',
  privacyPoliciesConditionsPoliticaInformacionP1: 'La presente Politica di Sicurezza e Riservatezza delle Informazioni mostra l’impegno della Direzione nel dotare l’organizzazione di risorse, responsabilità e procedure che consentano di garantire il rispetto della normativa vigente, nonché l’integrità, la riservatezza e la disponibilità delle informazioni e dei sistemi, essendo cruciale per la sicurezza, la privacy e la continuità della nostra organizzazione e dei nostri clienti.',
  privacyPoliciesConditionsPoliticaInformacionP2: 'Gruppo MailTecK, consapevole del valore della sicurezza e della riservatezza delle informazioni dei nostri clienti, ha stabilito un Sistema di Gestione della Sicurezza e della Riservatezza delle informazioni in conformità ai requisiti delle norme ISO/IEC 27001:2013 e ISO/IEC 27701:2019 per garantire i livelli di Sicurezza richiesti, la Continuità dei servizi prestati e il Rispetto legale nel trattamento dei dati di carattere personale, minimizzando i rischi derivanti dalla suddetta gestione.',
  privacyPoliciesConditionsPoliticaInformacionCCTP2: 'Custommer Comms, consapevole del valore della sicurezza e della riservatezza delle informazioni dei nostri clienti, ha stabilito un Sistema di Gestione della Sicurezza e della Riservatezza delle informazioni in conformità ai requisiti delle norme ISO/IEC 27001:2013 e ISO/IEC 27701:2019 per garantire i livelli di Sicurezza richiesti, la Continuità dei servizi prestati e il Rispetto legale nel trattamento dei dati di carattere personale, minimizzando i rischi derivanti dalla suddetta gestione.',
  privacyPoliciesConditionsPoliticaInformacionP3: 'L’obiettivo della Politica di Sicurezza e Riservatezza è di stabilire l’ambito di azione necessario per proteggere le risorse informative in caso di minacce, interne ed esterne, deliberate o accidentali, al fine di garantire l’osservanza della riservatezza, integrità e disponibilità delle informazioni.',
  privacyPoliciesConditionsPoliticaInformacionP4: 'L’efficacia e l\'applicazione del Sistema di Gestione della Sicurezza e Riservatezza delle Informazioni è responsabilità diretta del Comitato per la Sicurezza e la Riservatezza delle informazioni, altresì responsabile dell’approvazione, diffusione e rispetto della presente Politica di Sicurezza e Riservatezza. Per suo conto e rappresentazione è stato nominato un Responsabile del Sistema di Gestione della Sicurezza e della Riservatezza delle informazioni con l’autorità necessaria per svolgere un ruolo attivo nel suddetto Sistema, supervisionandone l’applicazione, sviluppo e manutenzione.',
  privacyPoliciesConditionsPoliticaInformacionP5: 'Inoltre, e con l’obiettivo di osservare la normativa vigente di protezione dei dati, è stato nominato un Delegato della Protezione dei dati (DPO), al fine di garantire la sicurezza e la riservatezza dei dati di carattere personale, tenendo specialmente conto dell\'attività della società.',
  privacyPoliciesConditionsPoliticaInformacionP6: 'Il Comitato per la Sicurezza e la Riservatezza delle Informazioni procederà a sviluppare e approvare la metodologia di analisi dei rischi adotatta nel Sistema di Gestione della Sicurezza e della Riservatezza delle informazioni.',
  privacyPoliciesConditionsPoliticaInformacionP7: 'Qualunque persona la cui attività possa essere compromessa, direttamente o indirettamente, dai requisiti del Sistema di Gestione della Sicurezza e della Riservatezza delle informazioni, è tenuta allo stretto adempimento della Politica di Sicurezza e riservatezza.',
  privacyPoliciesConditionsPoliticaInformacionP8: 'All’interno del Gruppo MailTecK verranno adottate tutte le misure necessarie per adempiere alla normativa applicabile in materia di sicurezza e riservatezza in generale e sicurezza informatica, inerente alla politica informatica, alla sicurezza degli edifici e delle strutture e al comportamento dei dipendenti e di terzi associati al Gruppo MailTecK durante l’uso dei sistemi informatici e il trattamento dei dati personali. Le misure necessarie per garantire la sicurezza e la riservatezza delle informazioni tramite l’applicazione di norme, procedure e controlli dovranno consentire di garantire la riservatezza, l’integrità e la disponibilità delle informazioni, essenziali per:',
  privacyPoliciesConditionsPoliticaInformacionCCTP8: 'All’interno di Customer Comms verranno adottate tutte le misure necessarie per adempiere alla normativa applicabile in materia di sicurezza e riservatezza in generale e sicurezza informativa, inerente alla politica informatica, alla sicurezza degli edifici e delle strutture e al comportamento dei dipendenti e di terze persone associate al Gruppo MailTeck per l’uso di sistemi informatici e trattamento dei dati personali. Le misure necessarie per garantire la sicurezza e la riservatezza delle informazioni tramite l’applicazione di norme, procedure e controlli dovranno consentire di garantire la riservatezza, l’integrità e la disponibilità delle informazioni, essenziali per:',
  privacyPoliciesConditionsPoliticaInformacionLista1: 'Rispettare la legislazione, la regolamentazione e la normativa vigente in materia di riservatezza e dei sistemi di informazione e con tutti i requisiti contrattuali in materia di protezione dei dati, nonché di quelli che si considerino opportuni con l’obiettivo di migliorare continuamente l’organizzazione.',
  privacyPoliciesConditionsPoliticaInformacionLista2: 'Garantire la riservatezza dei dati gestiti dal Gruppo MailTecK.',
  privacyPoliciesConditionsPoliticaInformacionCCTLista2: 'Garantire la riservatezza dei dati gestiti da Customer Comms.',
  privacyPoliciesConditionsPoliticaInformacionLista3: 'Garantire la disponibilità dei sistemi di informazione, sia nei servizi offerti ai clienti che nella gestione interna.',
  privacyPoliciesConditionsPoliticaInformacionLista4: 'Garantire la pronta risposta in caso di emergenza, ripristinando il funzionamento dei servizi critici nel minor tempo possibile.',
  privacyPoliciesConditionsPoliticaInformacionLista5: 'Evitare alterazioni indebite delle informazioni.',
  privacyPoliciesConditionsPoliticaInformacionLista6: 'Promuovere la consapevolezza e la formazione in materia di sicurezza e riservatezza delle informazioni.',
  privacyPoliciesConditionsPoliticaInformacionLista7: 'Promuovere e partecipare al fine di migliorare il sistema di gestione della sicurezza e della riservatezza delle informazioni.',
  cookiesPoliciesIntroText: 'Questo sito web usa cookie per migliorare la sua esperienza durante la navigazione. È possibile cambiare queste impostazioni in qualsiasi momento. I cookie classificati come "necessari" verranno salvati sul suo browser e sono essenziali per il funzionamento delle funzionalità basilari del sito web. I cookie di terzi verranno salvati sul suo browser solo con il suo consenso e ci aiutano ad analizzare e comprendere come viene usato il sito web. Può sceglier edi non ricevere questi cookie. As ogni modo, l’esclusione volontaria di uno di questi cookie può comportare che alcune funzioni non siano più disponibili. Per ottenere maggiori informazioni su come eliminare i cookie, consulti la funzione di Aiuto del suo browser.',
  cookiesPoliciesNecessaryCookies: 'Cookie necessari',
  cookiesPoliciesNecessaryCookiesText: 'Si tratta di una tipologia di cookie essenziali per far funzionare correttamente il sito web. Questa categoria include solo i cookie che garantiscono le funzionalità basilari e le caratteristiche di sicurezza del sito web e non raccolgono né salvano alcuna informazione personale sugli utenti del sito web.',
  cookiesPoliciesUnnecessaryCookies: 'Cookie non necessari',
  cookiesPoliciesUnnecessaryCookiesText: 'Si definiscono cookie non necessari quelli che non sono particolarmente essenziali per il corretto funzionamento del sito web. Si usano in modo specifico per raccogliere dati personali dell’utente attraverso l\'analisi, gli annunci e altri contenuti integrati. Prima di eseguire questi cookie su un sito web è necessario ottenere il consenso dell’utente.',
  cookiesPoliciesGoogleAnalyticsCookies: 'Cookie di Google Analytics',
  cookiesPoliciesGoogleAnalyticsCookiesText: 'Per maggiori informazioni su questa tipologia di cookie, consulti il documento " Uso dei cookie di Google Analytics sui siti web"',
  cookiesPoliciesGoogleAnalyticsCheckboxText: 'Mantenere cookie attivi',
  cookiesNotificationBarText: 'Utilizziamo cookie, sia nostri che di terze parti, per misurare le visite al nostro sito web, garantirne la funzionalità e analizzare la navigazione al suo interno. Di seguito potrà accettare o rifiutare i cookie o configurarne l’uso. Può accedere alle informazioni sulla titolarità del sito web, sul responsabile del trattamento dei cookie e sui dati di contatto su',
  cookiesNotificationBarConfig: 'Imposta',
  cookiesPoliciesModalIntro: 'Introduzione',
  cookiesPoliciesModalIntroP1: 'Il nostro sito web usa cookie e/o tecnologie simili a quelle che conservano e recuperano informazioni durante la navigazione per migliorare e ottimizzare l’esperienza dell’utente.',
  cookiesPoliciesModalIntroP2: 'In generale, queste tecnologie compiono finalità molto diverse, tra cui il riconoscimento dell’utente, l’ottenimento di informazioni sulle abitudini di navigazione, oppure personalizzare il modo in cui viene mostrato il contenuto.',
  cookiesPoliciesModalIntroP3: 'In conformità al Decreto Regio Legge 13/2012 e alle modifiche nell\'articolo 22.2 della Legge sui Servizi della Società delle informazioni e del commercio elettronico, con la presente Politica le indichiamo quali sono i diversi cookie che CUSTOMER COMMUNICATIONS TECKNALIA, S.L. Raccoglie tramite i diversi canali di comunicazione, propri e di terze parti, esenti o meno dell’obbligo di informare, e i diversi mezzi attraverso i quali può rifiutarli, permetterli o bloccarli.',
  cookiesPoliciesModalIntroP4: 'Di seguito verranno fornite informazioni dettagliate su: cosa sono i "Cookie", quali tipologie di cookie sono usate sul nostro sito web, come possono essere disattivati in un browser e come bloccare in modo specifico l’installazione di Cookie di terze parti.',
  cookiesPoliciesModalResponsibility: 'Chi è il responsabile dell’uso dei cookie in questo sito web?',
  cookiesPoliciesModalResponsibilityP1: 'Il responsabile dell’uso dei cookie è CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (di seguito CCOMMS), P.IVA B-86414000, sede fiscale a Leganés (Madrid) in Avenida de la Recomba, 14, debitamente iscritto al Registro delle Imprese di Madrid, Volume 29.801, Foglio 98, Sezione 8ª, Pagina n. M-536210.',
  cookiesPoliciesModalResponsibilityP2: 'E-mail del Delegato della Protezione dei dati.',
  cookiesPoliciesModalWhatIsCookie: 'Cos’è un cookie?',
  cookiesPoliciesModalWhatIsCookieP1: 'Un cookie è un dato, un file o dispositivo di archiviazione e recupero dati (DARD) che un browser salva in modo automatico nel dispositivo (Smartphone, tablet o smart-tv) di un utente quando questi accede ai siti web, agli account dei canali social o quando riceve un’e-mail.',
  cookiesPoliciesModalWhatIsCookieP2: 'I cookie permettono di archiviare e recuperare in modo automatico i dati sull’uso effettuato nei suddetti canali digitali e sul computer o dispositivo da cui è stato effettuato l\'accesso.',
  cookiesPoliciesModalWhatIsCookieP3: 'Un cookie sono solo dati archiviati nel computer di un utente, pertanto non sono software né frammenti di codice, ma semplicemente dati. Pertanto, in linea di principio i cookie non possono trasmettere né eseguire virus, né installare malware come trojan o programmi di spionaggio.',
  cookiesPoliciesModalCookieTypes: 'Tipologie di cookie del sito web e finalità',
  cookiesPoliciesModalCookiesInstall: 'Quali sono i cookie che si possono installare sul sito web CertySign?',
  cookiesPoliciesModalCookiesInstallP1: 'L’accesso al sito web CertySign implica che si possono installare Cookie, propri e di terzi, dettagliati nel riquadro di seguito:',
  cookiesPoliciesModalBrowserConfigCookies: 'Come configurare i cookie nel browser',
  cookiesPoliciesModalBrowserConfigCookiesP1: 'Lei ha l’opzione di non ricevere cookie o di essere informato dei cookie impostati dalle impostazioni del suo browser, da cui è possibile gestirli nelle sezioni "Opzioni" o "preferenze", fatta eccezione per i cookie Flash.',
  cookiesPoliciesModalBrowserConfigCookiesP2: 'Può disattivarli tutti e in tal caso il browser non permetterà l’installazione di nessun cookie (bloccando alcune funzionalità del sito web) oppure solo quelli provenienti da specifici siti web. In tal caso potrà utilizzare tutte le funzionalità avanzate dei siti web che rispettano la privacy dei suoi dati ed escludere quelli che non lo fanno.',
  cookiesPoliciesModalBrowserConfigCookiesP3: 'Nei link di seguito le indichiamo gli accessi ai principali siti web di assistenza:',
  cookiesPoliciesModalBrowserConfigCookiesP4: 'Inoltre, l’Utente può opporsi all’uso di determinati cookie tramite i servizi indicati di seguito:',
  cookiesPoliciesModalBrowserConfigCookiesLI1: 'Blocco dei cookie su Chrome',
  cookiesPoliciesModalBrowserConfigCookiesLI2: 'Blocco dei cookie su Safari',
  cookiesPoliciesModalBrowserConfigCookiesLI3: 'Blocco dei cookie su Firefox',
  cookiesPoliciesModalBrowserConfigCookiesLI4: 'Blocco dei cookie su Internet Explorer',
  cookiesPoliciesModalBrowserConfigCookiesLI5: 'http://www.criteo.com/deactivate-criteo-banners/',
  cookiesPoliciesModalBrowserConfigCookiesLI6: 'http://youronlinechoices.eu/',
  cookiesPoliciesModalBrowserConfigCookiesLI7: 'http://www.networkadvertising.org/choices/',
  cookiesPoliciesModalUnavailableCookies: 'Cosa succede se i cookie vengono disabilitati?',
  cookiesPoliciesModalUnavailableCookiesP1: 'Rifiutando i cookie potrà continuare a navigare sul nostro sito web, anche se potrebbe essere limitato l’uso di alcuni servizi e pertanto la sua esperienza potrebbe essere meno soddisfacente.',
  cookiesPoliciesModalUnavailableCookiesP2: 'Il rifiuto, il blocco o la negazione dei permessi dei cookie descritti nella presente politica può influire sulla corretta navigazione per i contenuti riprodotti nel sito web.',
  cookiesPoliciesModalAcceptCookies: 'Accettazione della politica dei cookie',
  cookiesPoliciesModalAcceptCookiesP1: 'Facendo clic sul pulsante "ACCETTA COOKIE" accetta l’uso dei cookie.',
  cookiesPoliciesModalAcceptCookiesP2: 'Tuttavia, a ogni accesso le verrà mostrata e ricordata la nostra Politica dei cookie, nella parte inferiore di qualsiasi pagina del portale.',
  cookiesPoliciesModalUpgradeCookies: 'Aggiorniamo la nostra politica dei cookie?',
  cookiesPoliciesModalUpgradeCookiesP1: 'È possibile che le informazioni contenute nella presente Politica dei Cookie possa subire modifiche a causa di aggiornamenti o cambiamenti nella politica di trattamento dei cookie; inoltre, l’Agenzia Spagnola per la Protezione dei dati potrebbe dettare istruzioni e/ raccomandazioni che potrebbero modificare la suddetta politica.',
  cookiesPoliciesModalUpgradeCookiesP2: 'Pertanto, le consigliamo di controllare la presente politica ogni volta che accede al nostro Sito Web al fine di essere adeguatamente informato sulle modalità e le finalità dell’uso dei cookie.',
  cookiesPoliciesModalRecipientCookies: 'Chi sono i destinatari delle informazioni e in che modo l’uso dei cookie influisce sulla protezione dei dati personali?',
  cookiesPoliciesModalRecipientCookiesP1: 'Per ulteriori informazioni sul trattamento dei suoi dati personali può consultare la',
  cookiesPoliciesModalRecipientCookiesLinkCCOMMS: 'Politica sulla protezione dei dati',
  cookiesPoliciesModalContactInfoCookies: 'Informazioni di contatto',
  cookiesPoliciesModalContactInfoCookiesP1: 'Per qualsiasi dubbio, chiarimento e/o suggerimento riguardo i cookie, la preghiamo di contattarci tramite e-mail',
  cookiesPoliciesModalDatatableCookieType: 'Tipo di cookie',
  cookiesPoliciesModalDatatableTreatmentTerm: 'Durata del trattamento',
  cookiesPoliciesModalDatatableTypeAnalytics: 'Analisi',
  cookiesPoliciesModalDatatableTypeSelf: 'Proprie',
  cookiesPoliciesModalDatatableTypeAnalyticsD: 'Ogni volta che un Utente visita un Servizio, uno strumento di un fornitore esterno genera un cookie analitico nel computer dell’Utente. Tale Cookie, che si genera solamente nel momento della visita, servirà a CCOMMS nelle visite successive per identificare anonimamente il visitatore.',
  cookiesPoliciesModalDatatableTypeAnalyticsF: 'Permettono, inoltre, di identificare in modo anonimo gli Utenti naviganti (identificano browser e dispositivi, non persone) e pertanto di contabilizzare approssimativamente il numero di visitatori e la tendenza nel tempo. Identificano in modo anonimo i contenuti più visitati e interessanti per gli Utenti. Consentono di sapere se l’Utente che sta effettuando l\'accesso è nuovo o meno. Salvo che l’Utente decida di registrarsi su CCOMMS, il "Cookie" non andrà mai associato a nessun dato di carattere personale che possa identificarlo direttamente. I suddetti cookie verranno utilizzati solo a fini statistici che contribuiscano a ottimizzare e migliorare l\'esperienza degli Utenti nel sito web.',
  cookiesPoliciesModalDatatableTypeAnalyticsT: 'Persistenti',
  cookiesPoliciesModalDatatableTypeSelfD: 'Questo tipo di cookie permettono di ampliare le informazioni mostrate a ogni Utente anonimo nei Servizi di CCOMMS.',
  cookiesPoliciesModalDatatableTypeSelfF: 'Tra le altre funzioni, archiviano la durata e la frequenza della visualizzazione delle diverse sezioni, l’interazione con le stesse, gli schemi di navigazione e/o i comportamenti degli utenti.',
  cookiesPoliciesModalDatatableTypeSelfT: 'Persistenti',
  eventInfo: 'Informazioni sull’evento',
  manual: 'Manuale',
  guia: 'Guida',
  supportLoggedPhonePrefferedMessage: '(Assistenza utente log in effettuato - Preferenza chiamata telefonica)',
  supportLoggedNotPhoneMessage: 'Non indica il telefono',
  supportLoggedUserMessage: '(Assistenza per l’utente che ha già effettuato l\'accesso)',
  supportNotLoggedUserWebMessage: '(Log in - Assistenza via web) L’utente richiede assistenza per recuperare la password',
  supportNotLoggedUserNotAvailableMessage: 'Non disponibile',
  supportNotLoggedUserC2CMessage: '(Log in - Assistenza via C2C) L’utente richiede assistenza per recuperare la password',
  processA4NotSupported: 'I documenti devono essere in formato A4 e non devono superare le 389 pagine e i 15 mb',
  cookiesGAMessage: 'Misurazione interna con Google Analytics. Questi cookie archiviano un identificativo univoco per riconoscere l’utente nelle sue future visite al sito web.',
  cookiesGAFGenericMessage: 'Misurazione interna con Google Analytics.',
  cookiesCookieMessage: 'Cookie',
  cookiesNecessaryCookieDescription: 'Questo cookie è necessario per stabilire la preferenza dell’utente rispetto alla politica dei cookie.',
  documentHtmlRule: 'Tipo di file accettato - HTML',
  rulesFilesSizePostal: 'Il file deve pesare al massimo 15Mb',
  rulesFilesSizeEmail: 'Il file deve pesare al massimo 14Mb',
  rulesFilesSizeMultiple: 'La dimensione massima totale dei file è di 14Mb',
  rulesFilesSizeHtml: 'Il file deve pesare al massimo {fileSize}Mb',
  rulesFilesExtensionHtml: 'Il file deve essere in formato HTML',
  group_cannot_be_empty: 'Il gruppo :group_name: non può rimanere vuoto',
  language: 'Lingua',
  defaultLanguage: 'Lingua predefinita',
  transactionDownloadPee: 'Vedi PEC',
  transactionTracking: 'Monitoraggio dell’invio',
  documentOrder: 'Stabilisci ordine documenti',
  yes: 'Sì',
  no: 'No',
  notifications: 'Notifiche',
  activateNotifications: 'Attiva',
  deactivateNotifications: 'Disattiva',
  selectCategoryNotifications: 'Seleziona azione per notifiche',
  activateCategoryNotifications: 'Attiva tutte',
  deactivateCategoryNotifications: 'Disattiva tutte',
  customCategoryNotifications: 'Personalizza',
  enabledNotifications: 'Notifiche attivate',
  disabledNotifications: 'Notifiche disattivate',
  customNotifications: 'Notifiche personalizzate',
  selectNotifications: 'Seleziona configurazione notifiche',
  enabledNotificationsDescriptionText: 'Ogni evento realizzato genererà una notifica. Lei potrà scegliere di ricevere un’e-mail per ciascuna notifica oppure un rapporto giornaliero di riepilogo delle notifiche.',
  disabledNotificationsDescriptionText: 'Non riceverà alcuna notifica relativa allo stato degli invii effettuati.',
  customNotificationsDescriptionText: 'Potrà selezionare i processi per i quali desidera o non desidera ricevere notifiche relative allo stato.',
  enabledNotificationsConfigurationText: 'Configurazione',
  enabledNotificationsConfigurationRealTime: 'Notifiche in tempo reale',
  enabledNotificationsConfigurationGrouped: 'Riepilogo delle notifiche',
  selectEnabledNotifications: 'Seleziona tipo di notifiche',
  enabledNotificationsRealTimeDescriptionText: 'Riceverà un’e-mail istantanea per ogni evento realizzato. Scegliendo un orario specifico, riceverà anche un’e-mail con il rapporto di tutti gli eventi realizzati al di fuori dell’orario in questione.',
  enabledNotificationsGroupedDescriptionText: 'Riceverà un’e-mail all’ora selezionata con il rapporto di tutti gli eventi realizzati il giorno precedente.',
  enabledNotificationsScheduleRangeDescriptionText: 'Notifiche nella fascia oraria seguente.',
  enabledNotificationsScheduleDescriptionText: 'Notifiche solo all’ora seguente.',
  chooseNotificationsRange: 'Scelga come e quando ricevere le notifiche.',
  enabledNotificationsCustomText: 'Personalizzazione delle notifiche',
  realTimeNotificationsDescription: 'Le notifiche vengono inviate in tempo reale. Per ogni evento realizzato, verrà inviata un’e-mail indicante il relativo stato all’ora selezionata.',
  languageAndTimezone: 'Lingua e fascia oraria',
  defaultTimeZone: 'Fascia oraria predefinita',
  user_process_notifications_updated: 'Notifiche aggiornate',
  changeSectionConfirmation: 'Modifiche in attesa di salvataggio',
  changeSectionConfirmationText: 'Ha effettuato modifiche che devono essere ancora salvate. Per salvarle ora, faccia clic su',
  groupedNotificationsDescription: 'Le notifiche vengono inviate mediante una relazione allegata che contiene una lista di tutte le transazioni e l’ultimo evento registrato in ognuna di esse, nell’ora selezionata.',
  groupedNotificationsReportTime: 'A che ora vuole ricevere la relazione',
  discard: 'Rifiutare',
  cost_center_updated: 'Centro di costo aggiornato con esito',
  loginInfo: 'Informazioni di login',
  lastLoginSuccess: 'Ultimo login',
  lastLoginError: 'Ultimo tentativo di accesso fallito',
  noRecord: 'Non registrato',
  charLimitLength: 'Il campo può contenere un massimo di {limit} caratteri',
  requiredLastName: 'Cognomi richiesti',
  optional: 'Facoltativo',
  availableVariables: 'Variabili disponibili',
  twoFactorText: 'L’utente richiede l’autenticazione a due fattori. Per avviare la sessione, inserire il codice visibile nell’applicazione di gestione degli accessi con autenticazione multifattore.',
  mfa_configuration_required: 'L’utente richiede l’autenticazione a due fattori. Per configurarla, scansionare il codice QR dall’applicazione di gestione degli accesi con autenticazione multifattore. Successivamente inserire il codice nella casella inferiore',
  code: 'Codice',
  codeRequired: 'Il codice è necessario per avviare la sessione',
  mfa_code_invalid: 'Il codice è errato',
  communicationContent: 'Contenuto della comunicazione',
  uploadFiles: 'Includere documenti',
  modifyFiles: 'Modificare documenti',
  fileResume: '{nfiles} file ({size}) MB in totale',
  moreThan1City: 'Il codice postale corrisponde a più di una città; consultare il menu a discesa o modificare manualmente il campo',
  shipmentDataSectionDescription: 'In questa sezione vengono aggiunti i contenuti della comunicazione. I documenti devono essere in formato PFD e A4',
  zipCodeFormat: 'Inserire un codice postale valido',
  deleteRecipient: 'Eliminare questo destinatario',
  mobileAddRecipientDescription: 'Più di un destinatario?',
  errorFilesTotalSizeExceeded: 'La dimensione totale dei file non può superare i 15 MB',
  fileExtensionNotAccepted: 'L’estensione del file non è valida. (Le estensioni di file ammesse sono= {acceptedExtensions})',
  nameAndSurnameLength: 'Il nome e il cognome non possono superare i 99 caratteri',
  batchModeTitle: 'Invio in batch',
  batchModeCSVStep: 'Destinatari',
  batchModeAttachmentsStep: 'File richiesti',
  batchModeDownloadSampleCSV: 'Scarica un file di esempio',
  batchModeExtraCSVStepIntroduction: 'Innanzitutto, è necessario caricare un file <strong>CSV</strong> o <strong>XLS</strong> con i dati degli invii. Successivamente, è possibile scaricare un file di esempio che include i campi richiesti.',
  batchModeExtraCSVStepIntroduction2: 'Una volta preparato il file, è possibile aggiungerlo facendo clic o trascinandolo nell’apposita area. Il file <strong>non deve contenere righe vuote o errori</strong> in nessuno dei destinatari.',
  batchModeExtraCSVStepIntroduction3: 'Una volta convalidata la struttura del file, sarà abilitato il pulsante <strong>Continua</strong> e sarà possibile proseguire il processo.',
  batchModeExtraFilesStepIntroduction: 'In questo passaggio saranno aggiunte le risorse necessarie a effettuare l’invio a ciascun destinatario.<br><br>È stato rilevato un totale di <b>{count}</b> file. È possibile aggiungerli facendo clic o trascinandoli nell’apposita area.<br><br>Si ricorda che il numero totale di file in ogni invio non può superare i <b>{fileSizeLimit} MB</b> e che l’invio ha una dimensione massima complessiva di <b>{totalSizeLimit} GB</b>.',
  extraFilesTableHeaderFileName: 'Nome del file',
  extraFilesTableHeaderStatus: 'Stato',
  extraFilesTableStatusNotFound: 'In attesa di caricamento',
  extraFilesTableStatusProcessing: 'Elaborazione in corso',
  extraFilesTableStatusUploaded: 'OK',
  extraFilesTableStatusUploadedCorrectly: 'Elaborato',
  extraFilesTableHeaderActions: 'Azioni',
  batchModePreviewStep: 'Previsualizza e invia',
  batchModeStep1RemoveFile: 'Elimina il file',
  batchModeCSVTableTitle: 'Il file <strong>{filename}</strong> contiene <strong>{nOfRecipients}</strong> dei destinatari.',
  batchModeCSVTableSubtitle: 'Di seguito è riportata un’anteprima dei primi destinatari del file; per ulteriori informazioni, fare clic su Visualizza altri.',
  batchModeCSVTableSubtitleNoData: 'Il file selezionato non contiene destinatari.',
  batchModeNoDataTitle: 'Nessun file selezionato. Una volta aggiunto, saranno visualizzati i primi destinatari del file.',
  dropZoneText: 'Fai clic o trascina qui i tuoi file',
  dropZoneTextSingle: 'Fai clic o trascina qui il tuo file',
  batchModeDialogTitle: 'Aggiungi destinatari',
  batchModeDialogManualColTitle: 'Manualmente',
  batchModeDialogManualColDescription: 'Se desideri effettuare uno o più invii singolarmente, scegli questa opzione.',
  batchModeDialogBatchColTitle: 'Invio in batch',
  batchModeDialogBatchColDescription: 'Se desideri effettuare un invio in modo semplice mediante un file csv o xls con diversi destinatari e documenti, scegli questa opzione.',
  batchModeDialogBatchBtnManual: 'Invio singolo',
  batchModeDialogBatchBtnBatch: 'Invio in batch',
  batchModeResourceTableNOfFilesFound: '<b>{nOfRequiredFiles}</b> file da aggiungere',
  batchModeResourceTableAllFilesAdded: 'Tutti i file richiesti sono stati aggiunti',
  // batchModeResourceTableStatus: 'Stato attuale= <b>$currentFiles$</b>/$nOfRequiredFiles$',
  batchModeResourceTableExpandText: 'Fare clic per ulteriori dettagli',
  batchModeResourceTableFilenameText: 'Nome del file',
  batchModeResourceTableFoundOnNRows: 'destinatari interessati',
  batchModeResourceTableErrorFilenameInvalid: 'Uno o più file che si è tentato di aggiungere non corrispondono ai file previsti.',
  batchModeResourceTableErrorFileSizeLimit: 'La dimensione del file è superiore a {size}MB',
  batchModeResourceTableErrorTotalFileSizeLimit: 'Il totale dei file caricati non può superare i {size}GB',
  batchModeResourceTableInfoFileReplaced: 'Il file {filename} è stato sostituito.',
  seeMore: 'Visualizza di più',
  seeLess: 'Visualizza di meno',
  pending: 'In attesa',
  batchModePreviewText: 'A seguito dell’elaborazione, sarà possibile visualizzare le operazioni nella finestra corrispondente e riceverai un’e-mail con le informazioni di ciascun invio e il codice identificativo dell’operazione associato a ciascun destinatario.',
  batchModePreviewText1: 'In caso di elaborazione non riuscita, l’e-mail indicherà gli invii non effettuati e il motivo dell’errore.',
  csvError: 'Impossibile elaborare il file',
  csvErrorDetailStructure: 'La struttura del file non è corretta.',
  csvErrorDetailIncorrectFields: 'I campi non corrispondono ai dati richiesti per il processo',
  csvErrorDetailIncorrectExtensions: 'Sono presenti estensioni di file non valide nelle righe seguenti:',
  batchModeStep2SpaceLeft: 'Spazio utilizzato',
  batchModeStep2PendingFiles: 'File in attesa',
  launchDataKoBatchMode: 'I dati forniti non sono validi',
  launchDataKoBatchModeStep1: 'Non è stato selezionato alcun file',
  launchDataOkBatchModeStep1: 'La struttura dei file è corretta, è possibile continuare il processo',
  launchDataKoBatchModeStep2: 'Aggiungere i file richiesti',
  launchDataOkBatchModeStep2: 'I file richiesti sono stati aggiunti con successo, è possibile continuare il processo',
  launchDataOkBatchMode: 'I dati sono corretti, è possibile continuare il processo',
  batchModeProcessLaunchedStep1: 'Avvio del processo di registrazione',
  batchModeProcessLaunchedStep2: 'Caricamento delle risorse richieste per gli invii',
  batchModeProcessLaunchedStep3: 'Caricamento del file dei destinatari',
  batchModeProcessLaunchedStep4: 'Processo di registrazione completato',
  transactionsProcessedBatchMode: 'Invio in batch registrato con successo',
  transactionProcessingBatchMode: 'Elaborazione dell’invio in batch...',
  transactionProcessingBatchModeError: 'Errore durante l’elaborazione dell’invio in batch',
  transactionProcessingBatchModeErrorDescription: 'Si è verificato un errore durante l’elaborazione del batch, contattare l’assistenza.',
  csvErrorDialogDescription: 'Ricorda che è necessario caricare un file <b>CSV</b> o <b>XLS</b> e che <strong>non deve contenere righe vuote o errori</strong> in nessuno dei destinatari.',
  extraFileError: 'Impossibile aggiungere il file',
  extraFileErrorDialogDescription: 'Ricorda che il limite di dimensione ogni operazione è di <b>{fileSizeLimit} MB</b> e che la dimensione massima per l’intero batch è di <b>{totalSizeLimit} GB</b>.',
  extraFileErrorTransactionSizeLimit: 'La dimensione massima per l’invio è stata superata nelle seguenti registrazioni= {rows}',
  htmlEditorInfoParagraph1: 'Questo passaggio prevede la personalizzazione del contenuto dell’e-mail che verrà inviata a ciascuno dei destinatari.',
  htmlEditorInfoParagraph3: 'È possibile selezionare una modalità di modifica:',
  htmlEditorInfoBullet1: '<span>Selezione del modello</span>. Seleziona un modello e apporta le modifiche necessarie dall’editor.',
  htmlEditorInfoBullet2: '<span>HTML personalizzato</span>. Carica un file HTML personalizzato e apporta le modifiche necessarie nell’editor.',
  htmlEditorButtonUploadFile: 'CARICA HTML',
  htmlEditorDragAndDrop: 'Fare clic qui o trascina gli allegati all’e-mail.',
  batchModeExtraCSVStepIntroductionVariables: 'Ricorda che le seguenti variabili sono disponibili per l’uso nei campi dell’operazione:',
  batchModeContactId: 'Riferimento del destinatario',
  batchModeContactName: 'Nome del destinatario',
  batchModeContactSurname: 'Cognome del destinatario',
  htmlEditorTemplateSelected: 'Modello selezionato',
  htmlSubjectError: 'L’oggetto ha più di <span>{subjectLimit}</span> caratteri',
  htmlEditorInfoParagraph4: 'Ricordare che le dimensioni massime totali degli archivi sono pari a <b>{totalSizeLimit} Mb</b>.',
  backToProcess: 'Torna ai processi',
  infoAndHelp: 'Informazioni e assistenza',
  modelEmailHtmlTemplateSelector: 'Seleziona modello',
  modelEmailEmailBodyNotHtml: "Il file selezionato non è in formato html",
  templateSelectorDefaultTemplate: 'Predefinita',
  templateSelectorCustomTemplate: 'Personalizzata',
  templateSelectorNoLogoTemplate: 'Senza logo',
  filesExcedingSizeLimit: 'File che superano il limite di dimensioni',
  emailAttachmentsSectionTitle: "File allegati all'e-mail",
  emailContent: "Contenuto dell'e-mail",
  emailSubject: "Oggetto dell'e-mail",
  multipleFileExtensionError: "Hay uno o más ficheros con extensiones inválidas. (Las extensiones aceptadas son: {acceptedExtensions})",
  filePasswordProtectedOrDamage: "File non valido. Controlla che il file non sia danneggiato o protetto da password e riprova",
  backLostChangesHTMLText: "Se torni indietro, perderai le modifiche nel modello di email, così come i file allegati. <br>Sei sicuro di voler <b>tornare</b> al passo precedente?",
  backLostChangesHTMLConfirmBack: "Torna al passo precedente",
  backLostChangesHTMLConfirmContinue: "Continua con l'invio",
  processStepperDigitalSignatureConfigurationTitle: 'Configurazione',
  processStepperDigitalSignatureConfigurationDescription: 'Personalizzazione della cerimonia di firma',
  processStepperDigitalSignatureSignersTitle: 'Firmatari',
  processStepperDigitalSignatureSignersDescription: 'Impostazione dei firmatari e esperienza di firma',
  processStepperDigitalSignatureDocumentsTitle: 'Documenti',
  processStepperDigitalSignatureDocumentsDescription: 'Selezione dei documenti e interazione dei firmatari',
  processStepperDigitalSignatureSummaryTitle: 'Conferma e Invio',
  processStepperDigitalSignatureSummaryDescription: 'Revisione e conferma della cerimonia di firma',
  transactionName: 'Nome della transazione',
  transactionInterveners: 'Intervenienti della transazione',
  more: 'di più',
  finished: 'Completata',
  eventsInfo: 'Informazioni sugli eventi',
  transactionInvalidAlert: 'Durante il controllo dei dati di ingresso della transazione, sono stati rilevati possibili errori che potrebbero impedire un\'esecuzione corretta. La transazione è stata bloccata preventivamente. Dovrai avviare una nuova transazione con i dati di ingresso corretti.',
  transactionEntryData: 'Dati di ingresso della transazione',
  goToSignature: 'Vai alla firma',
  intervenersNotDetected: 'Nessun interveniente rilevato',
  transactionEventsRegistered: 'Eventi registrati nella transazione',
  aboutEvents: 'Informazioni sugli eventi',
  notEvents: 'Nessun evento registrato nella transazione.',
  notEventsRegistered: 'Nessun evento è stato registrato nella transazione. Se la transazione è stata creata di recente, potrebbe essere ancora in fase di generazione.',
  downloadCertificate: 'Scarica certificato',
  notValid: 'Non valido',
  transactionPending: 'In corso',
  refresh: 'Aggiorna',
  creation: 'Creazione',
  transactionDigitalSignatureInfo: 'Informazioni sulla transazione di firma digitale',
  reminders: 'Promemoria',
  input: {
    clear: 'Cancella'
  },
  intervenerStartCeremony: 'Per iniziare il turno di firma di questo interveniente, si aprirà una nuova scheda. Ricorda che, se nella transazione è presente un\'ordine di firma, gli intervenienti precedenti devono aver completato i documenti.',
  details: 'Dettagli',
  front_renderer_builder_transaction_opened: 'Transazione di firma visitata',
  front_renderer_builder_transaction_finished: 'La transazione di firma è stata completata da tutti gli intervenienti',
  front_renderer_builder_intervener_signed: 'Un interveniente ha completato la firma dei documenti associati',
  front_renderer_builder_transaction_created: 'La transazione di firma è stata creata correttamente',
  front_renderer_builder_transaction_invalid: 'La transazione di firma contiene errori e non è stata creata correttamente',
  front_renderer_builder_intervener_finished: 'Interveniente ha completato tutte le firme',
  certificate_builder_generated: 'È stato generato il certificato di completamento della transazione, che raccoglie il contenuto della comunicazione e le prove associate agli eventi verificatisi nel processo.',
  transactionFilesParagraph: 'Elenco dei file contenuti nella transazione',
  transactionDigitalProcessed: 'Transazione "{transactionName}" elaborata con successo',
  signatureType: 'Tipo di firma',
  faceToFace: 'Di persona',
  remote: 'Remota',
  transactionWarning: 'Con errori',
  cancelTransaction: 'Annulla transazione',
  identifyTransactionPlataformCommunications: 'Identifica la transazione sulla piattaforma e nelle comunicazioni',
  placeholderTransactionName: 'Nome della transazione',
  determineMethodAccessSigning: 'Determina il metodo di accesso alla cerimonia di firma',
  transactionType: 'Tipo di transazione',
  descriptionRemoteSignature: '<p><b>La firma elettronica remota</b> consente ai firmatari di accedere alla cerimonia di firma da qualsiasi luogo e dispositivo tramite i canali di comunicazione disponibili.</p>',
  descriptionPresentialSignature: '<p><b>La firma di persona</b> consente ai firmatari di accedere dal dispositivo utilizzato per avviare la transazione.</p>',
  setSignatureReminders: 'Imposta promemoria per accedere alla cerimonia di firma',
  transactionReminder: 'Promemoria della transazione',
  transactionRedirectToTransactionDetail: 'Visualizza la transazione',
  transaccion_cancelada: 'Transazione annullata',
  cancelled: 'Annullata',
  digital_certificate: 'Cert.',
  cert: 'Cert.',
  nif: 'ID/NIF',
  sms_otp: 'SMS OTP',
  email_otp: 'Email OTP',
  video_id: 'Identificazione tramite documento',
  ama: 'AMA Portogallo',
  lastUpdate: 'Ultimo aggiornamento',
  cancelTransactionAdvertisement: 'L\'annullamento della transazione non è reversibile. Ricorda che il processo di annullamento potrebbe richiedere alcuni minuti. Sei sicuro di voler annullare la transazione?',
  transaction_cancelled: 'Transazione annullata',
  transaction_not_cancelled: 'La transazione non è stata annullata',
  signed: 'Firma completata',
  addReminder: 'Aggiungi promemoria',
  sendingNewReminder: 'Aggiungi un promemoria, stabilendo i giorni che devono trascorrere tra il primo invio o il promemoria precedente',
  confirm: 'Confermare',
  sinceCreation: 'dalla creazione',
  sinceReminderNumber: 'dal promemoria n°',
  reminderLimitReached: 'Hai raggiunto il limite di promemoria',
  reminderLimitReachedZero: 'Non è possibile aggiungere promemoria',
  customTextComplementSignatureAccessInformation: 'Testo informativo incluso nelle email di accesso alla firma',
  globalMessageInterveners: 'Messaggio globale agli intervenienti',
  exampleInformSignatory: 'Inserisci il contenuto del messaggio',
  days: 'giorni',
  infoReminder: '<p>I promemoria della cerimonia di firma sono comunicazioni inviate ai firmatari dopo la creazione. Il limite è dato dalla scadenza della transazione o, se presenti, dai limiti del processo stesso.</p>',
  infoReminderDays: '<p>I giorni indicati nel primo promemoria saranno conteggiati <b>a partire dalla creazione della transazione</b>, mentre i giorni indicati nei successivi promemoria saranno conteggiati a partire dall\'invio del promemoria precedente.</p>',
  determineDaysSigningProcessRemainActive: 'Definisci i giorni in cui la cerimonia di firma rimarrà attiva',
  transactionExpiration: 'Scadenza della transazione',
  orderOfSignature: 'Ordine di firma',
  signatoriesAdded: 'aggiunti alla transazione',
  infoTransactionWithoutSignatures: 'La transazione non ha ancora firmatari. Puoi iniziare ad aggiungerli tramite il pulsante in basso.',
  intervenersLimitReached: 'Hai raggiunto il limite di firmatari',
  addSigner: 'Aggiungi firmatario',
  signerEdition: 'Impostazione del firmatario',
  nameSurname: 'Nome e cognome / Ragione sociale',
  placeholderSignerName: 'Giovanni Esempio Esempio',
  identificationType: 'Tipo di identificazione',
  withoutIdentification: 'Senza identificazione',
  securitySignature: 'Metodo di conferma',
  shippingChannel: 'Canale di invio',
  completetionEmail: 'Email di completamento',
  personalizedMessage: 'Messaggio personalizzato dell\'email iniziale',
  numberDocument: 'N° Documento',
  contactMethod: 'Metodo di contatto',
  notIndicated: 'Non indicato',
  identificationSignature: 'Identificazione/Firma',
  documents: 'Documento/i',
  infoTransactionWithoutDocuments: 'La transazione non ha ancora documenti. Puoi iniziare ad aggiungerli dal pulsante qui sotto.',
  documentsLimitReached: 'Hai raggiunto il limite di documenti',
  addDocument: 'Aggiungi documento',
  requirementsAddDocument: 'Devi tenere presente che i documenti devono soddisfare i seguenti requisiti:',
  addDocumentError: 'Non è stato possibile aggiungere il documento',
  documentNameRepeated: 'Il nome del documento che stai cercando di aggiungere esiste già nella transazione.',
  documentDetail: 'Dettagli del documento',
  documentActions: 'Azioni',
  withoutActions: 'Senza azioni',
  positionSignature: 'Posizionare le firme',
  deleteDocument: 'Elimina documento',
  size: 'Dimensione',
  totalFileSizeExceeded: 'La dimensione totale dei file caricati non può superare {size&Unit}',
  minCharacters: 'Minimo {min} caratteri',
  maxCharacters: 'Massimo {max} caratteri',
  expirationDays: 'Deve essere maggiore di 0 e minore di {max} giorni',
  expirationMustBeLongerThanReminders: 'La scadenza deve essere maggiore dei promemoria',
  reminderRuleAvailableDaysInput: 'Deve essere maggiore di 0 e minore di {max} giorni',
  remoteDigitalSignature: 'Firma elettronica remota',
  presentialSignature: 'Firma in presenza',
  none: 'Nessuno',
  noneMasc: 'Nessuno',
  certificateDigital: 'Certificato digitale',
  alertAbandonProcess: 'Se esci ora dal processo, perderai i dati inseriti. Sei sicuro di voler uscire?',
  amaPT: 'Ama Portugal',
  graphometricSignature: 'Firma grafometrica',
  signature: 'Firma',
  documentSignatures: 'Firme del documento',
  closeDocument: 'Chiudi documento',
  selectSignersActions: 'Seleziona un firmatario per conoscere le azioni disponibili sul documento',
  sendSignedDocuments: 'Invia documenti firmati',
  notSendSignedDocuments: 'Non inviare documenti firmati',
  documentLimitSizeAlert: 'La dimensione del documento non può superare i 12 MB',
  documentFormatAlert: 'I documenti devono essere in formato PDF e non devono essere criptati o danneggiati',
  documentLimitCountAlert: 'Il limite di documenti per la transazione è di ',
  signersAdded: 'Firmatario/i',
  sendTransaction: 'Invia cerimonia di firma',
  sendTransactionInfo: 'In cima, come riepilogo, hai i dati inseriti durante la configurazione. Una volta esaminati, puoi procedere all\'invio facendo clic sul pulsante qui sotto',
  transactionOkMessage: 'La transazione è stata elaborata con successo; nei prossimi minuti, potrai vedere gli eventi ad essa correlati nella vista "Dettagli della transazione". Puoi accedervi facendo clic sul pulsante qui sotto',
  transactionsOkMessage: 'Le transazioni sono state elaborate con successo; nei prossimi minuti saranno visibili nella vista "Transazioni". Puoi accedervi facendo clic sul pulsante qui sotto',
  goToPresentialCeremony: 'Iniziare la cerimonia di firma in presenza',
  interactionType: 'Tipo di interazione',
  signDocuments: 'Firmare documenti',
  acceptDocuments: 'Accettare documenti',
  requireCompleteReading: 'Revisione completa del documento',
  availableActions: 'Azioni disponibili',
  signerAcceptDocument: 'Il firmatario deve accettare questo documento',
  signaturesNotAvailable: 'Le firme non sono disponibili se il firmatario ha attivato l\'"accettazione dei documenti"',
  selectSigners: 'Seleziona firmatari',
  selectSignersDescription: 'Seleziona i firmatari della transazione che devono partecipare al documento',
  action: 'azione',
  singleClickSignatureInfoText: 'L\'accettazione del documento è stata attivata, quindi sarà firmato con un solo clic.',
  requireScrollToBottomInfoText: 'La revisione completa del documento è attivata',
  page: 'Pagina',
  interveners: 'Interventori',
  "face-to-face": 'In presenza',
  expiration: 'Scadenza',
  signatureStatus: 'Stato della firma',
  remaining: 'Rimanente/i',
  completed: 'Completata',
  contact: 'Contatto',
  events: 'eventi',
  notSpecified: 'Non specificato',
  intervener: 'Interveniente',
  reference: 'Riferimento',
  expired: 'Scaduta',
  documentViewerDialogSubtitle: 'Seleziona i firmatari che interagiranno con il documento, nonché le azioni da eseguire',
  front_renderer_builder_intervener_identification_success: 'L`interveniente è stato identificato correttamente nella cerimonia di firma',
  front_renderer_builder_signature_otp_generated: 'Il codice di accesso alla transazione è stato generato',
  front_renderer_builder_signature_otp_sent: 'Il codice di accesso alla transazione è stato inviato al dispositivo dell`interveniente',
  front_renderer_builder_transaction_expired: 'La transazione non è più accessibile poiché è scaduta',
  batches: 'Invii in batch',
  view_batches: 'Invii in batch',
  open: 'Apri',
  selectProcess: 'Seleziona un processo',
  transactionsSearchPlaceHolder: 'Cerca per ID Transazione o partecipante',
  batchesDescription: 'In questa sezione vengono mostrati gli invii in batch effettuati sulla piattaforma',
  scheduleTime: 'Data di invio',
  schedule: 'Stato/Invio',
  totalRecords: 'Registrazioni',
  showTransactions: 'Visualizza transazioni',
  cancelBatch: 'Annulla invii',
  cancelBatchConfirmText: 'Sei sicuro di voler annullare l\'invio e tutte le transazioni associate?',
  selectDateFilter: 'Filtro per data',
  stateFilterPlaceholder: 'Filtro per stato',
  processFilterPlaceholder: 'Filtro per processo',
  batchSearchTooltip: 'I campi disponibili per la ricerca sono',
  batchSearchTooltipFirstItem: 'ID dell\'invio in batch',
  batchSearchTooltipSecondItem: 'Email dell\'utente mittente',
  batchSearchTooltipThirdItem: 'Nome del file dell\'invio',
  processed: 'Elaborato',
  batchCancelled: 'Annullato',
  batchDetail: 'Dettagli dell\'invio in batch',
  batchInfo: 'Informazioni sull\'invio',
  batchInfoSectionIntroductionText: 'Se desideri, puoi selezionare un nome identificativo che verrà utilizzato durante tutto il processo. Se ometti questo campo, verrà utilizzato il nome del processo.',
  batchNamePlaceholder: 'Nome predefinito (nome del processo)',
  shipmentScheduling: 'Pianificazione dell\'invio',
  scheduleShipmentIntroduction: 'Puoi pianificare l\'invio per una data e un orario specifici. Se non selezioni una data, l\'invio verrà effettuato immediatamente.',
  shippingDate: 'Data di invio',
  time: 'Ora',
  selectDate: 'Data',
  selectTime: 'Ora',
  scheduledShipmentIncompleteDate: 'Devi selezionare una data e un orario per pianificare l\'invio',
  scheduleShipment: 'Pianifica invio',
  edit: 'Modifica',
  digitalSignatureEmptyStepMessage: 'Non ci sono campi disponibili da modificare, premi il pulsante <b>Continua</b> per procedere.',
  shipmentScheduled: 'Invio pianificato per',
  scheduled: 'Pianificato',
  sentTransactions: 'Transazioni inviate',
  sent: 'Inviate',
  pendingTransactions: 'Transazioni pianificate',
  see: 'Vedi',
  backToTransactions: 'Torna alle transazioni inviate',
  shipmentDate: 'Data di invio',
  selectProcessFirst: 'Per visualizzare gli stati disponibili, seleziona prima un processo'

}

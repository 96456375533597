<template>
    <v-card
      :id="`processes-process_card-${process.name.toLowerCase().replace(/\s/g, '_')}`"
      :disabled="!fromProcesses" :tabindex="false" class="cts-h-100 cts-image-card pa-0"
      flat
      @click="executeItem(NEW_PROCESS)">
        <div class="d-flex flex-no-wrap justify-space-between">
            <v-avatar
              :id="`processes-process_card-${process.name.toLowerCase().replace(/\s/g, '_')}-process_icon`"
              class="cts-process-card-icon ma-2 cts-bgcolor-lighten float-left hidden-xs-only"
              size="105"
              tile>
                <icon-base
                  :icon-name="process.name"
                  :src="svgIconName"
                  height="45"
                  icon-color="var(--primary)"
                  width="45">
                </icon-base>
            </v-avatar>
            <div class="pa-1 cts-w-100">
                <div class="cts-card-content cts-h-100 cts-w-100">
                    <v-card-title class="cts-section-card-title pa-0 mt-0 mt-sm-1 row justify-space-between row--dense">
                        <v-row class="ma-0 pa-0" dense>
                            <v-col
                              class="ma-0 pa-0 hidden-sm-and-up"
                              cols="1">
                                <icon-base
                                  :id="`processes-process_card-${process.name.toLowerCase().replace(/\s/g, '_')}-process_icon_sm`"
                                  :icon-name="process.name"
                                  :src="svgIconName"
                                  class="mr-2 mr-sm-0"
                                  height="15"
                                  icon-color="var(--primary)"
                                  width="15">
                                </icon-base>
                            </v-col>
                            <v-col
                              class="ma-0 pa-0 pl-sm-1"
                              cols="11">
                                <p
                                  :id="`processes-process_card-${process.name.toLowerCase().replace(/\s/g, '_')}-process_name`"
                                  class="ma-0 pa-0 text-two-lines"
                                >
                                    {{process.name}}
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-subtitle class="cts-section-card-subtitle pa-0 pr-3 mt-1">
                        <p
                          :id="`processes-process_card-${process.name.toLowerCase().replace(/\s/g, '_')}-process_description_hidden_xs_only`"
                          class="hidden-xs-only text-three-lines">
                            {{process.description}}
                        </p>
                        <p
                          :id="`processes-process_card-${process.name.toLowerCase().replace(/\s/g, '_')}-process_description_hidden_sm_and_up`"
                          class="hidden-sm-and-up cts-process-description-xs py-0 text-six-lines">
                            {{process.description}}
                        </p>
                    </v-card-subtitle>
                    <v-card-actions
                      v-if="fromProcesses"
                      class="cts-action-card-menu py-1 px-0 hidden-xs-only">
                        <v-row class="ma-0 justify-space-between" dense>
                            <div>
                <span
                  :id="`processes-process_card-${process.name.toLowerCase().replace(/\s/g, '_')}-process_card_tags_text`"
                  class="cts-caption cts-color-subtitle-a">{{$vuetify.lang.t('$vuetify.tags')}}:
                </span>
                                <span
                                  v-for="(tag, index) in tags"
                                  :id="`processes-process_card-${process.name.toLowerCase().replace(/\s/g, '_')}-process_card_tags-${tag.toLowerCase()}`"
                                  :key="index">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            class="cts-channel-icon"
                            color="var(--primary) !important" v-on="on">{{const_icons[tag]}}
                          </v-icon>
                        </template>
                        <span>{{$vuetify.lang.t('$vuetify.tag_' + tag.toLowerCase())}}</span>
                      </v-tooltip>
                  </span>
                            </div>
                            <div>
                                <v-row class="ma-1 cts-action-buttons-row justify-end" dense>
                                    <v-tooltip v-if="checkPermission(const_permissions.SECTION_TRANSACTIONS)" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                              :aria-label="$vuetify.lang.t('$vuetify.searchs')"
                                              :id="`processes-process_card-${process.name.toLowerCase().replace(/\s/g, '_')}-process_card_search_button`"
                                              class="cts-color-primary-alternative float-right" icon
                                              small
                                              v-on="on"
                                              @click.stop.prevent="executeItem(SEARCH_PROCESS)">
                                                <v-icon class="float-right" small>{{const_icons.SEARCH}}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span
                                          :id="`processes-process_card-${process.name.toLowerCase().replace(/\s/g, '_')}-process_card_search_tooltip`">{{
                                                $vuetify.lang.t('$vuetify.searchTransactions')
                                            }}
                    </span>
                                    </v-tooltip>
                                </v-row>
                            </div>
                        </v-row>
                    </v-card-actions>
                </div>
            </div>
        </div>

        <v-dialog v-if="continue_process_dialog && fromProcesses" v-model="continue_process_dialog" content-class="cts-process-recovery-modal"
                  max-width="600px">
            <v-card class="col-lg-12 mx-auto d-block" flat tile>
                <v-card-text class="cts-dialog-contextual pa-0">
                    <v-card-title class="ma-0 pa-0 mb-6">
                        <dialog-title :icon="const_icons.ALERT" :text="$vuetify.lang.t('$vuetify.processContinueTitle')"
                                      class="col-12"/>
                    </v-card-title>
                    <v-row class="ma-0 px-3" dense>
                        <v-col cols="7" md="4" sm="6">
                            <span class="font-weight-bold mr-4">{{$vuetify.lang.t('$vuetify.processName')}}:</span>
                        </v-col>
                        <v-col cols="5" md="8" sm="6">
                            <span class="cts-font-size-4" style="line-height: 20px"> {{processName}}</span>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 px-3" dense>
                        <v-col cols="7" md="4" sm="6">
                            <span class="font-weight-bold mr-4">{{$vuetify.lang.t('$vuetify.recipientsNumber')}}:</span>
                        </v-col>
                        <v-col cols="5" md="8" sm="6">
                            <span class="cts-font-size-4" style="line-height: 20px"> {{
                                    processNumberOfRecipients
                                }}</span>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 px-3 pt-4" dense>
                        <v-col cols="12">
                            <span class="">{{$vuetify.lang.t('$vuetify.recoverProcessQuestion')}}.</span>
                            <span
                              class="font-weight-bold mr-4">{{" " + $vuetify.lang.t('$vuetify.recoverProcessQuestionAppend')}}</span>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="cts-dialog-actions mx-auto mt-4">
                    <v-row class="ma-0 pa-0" dense>
                        <v-col cols="12" sm="6">
                            <v-btn block class="cts-button-secondary-block " color="primary" small
                                   @click="executeItem( CONTINUE_PROCESS)">{{$vuetify.lang.t('$vuetify.recover')}}
                                <v-icon class="float-left" small>settings_backup_restore</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-btn block class="cts-button-standard-block" color="primary" small
                                   @click="executeItem(START_NEW_PROCESS)">{{$vuetify.lang.t('$vuetify.newProcess')}}
                                <v-icon class="float-right" small>play_arrow</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <SelectBatchModeDialog
          v-if="batchMode_dialog"
          @batchMode="batchModeDialogHandler"
          @click:outside="batchMode_dialog = false"
        />
    </v-card>
</template>
<script>

import const_global from "@/constants/global"
import const_icons from "@/constants/icons.js"
import {checkPermission} from "@/util/store_utils"
import {
    NAMESPACE as PROCESSES_NAMESPACE,
    STATE as PROCESSES_STATE,
    ACTIONS as PROCESSES_ACTIONS
} from "@/constants/vuex/processes"
import {setProcessInfo, getProcessBehaviorLayer} from "@/services/processServices"
import IconBase from "../../../assets/images/IconBase"
import DialogTitle from "../../structures/dialogTitle"
import const_permissions from "@/constants/permissions"
import {redirect} from "@/util/router_utils"
import {TRANSACTIONS, PROCESS_VIEW} from "@/constants/router/routes"
import {mapActions} from "vuex"
import SelectBatchModeDialog from "@/components/sections/processes/SelectBatchModeDialog"

const NEW_PROCESS = "processes"
const SEARCH_PROCESS = "search"
const INFO_PROCESS = "info"
const CONTINUE_PROCESS = "continue_process"
const START_NEW_PROCESS = "start_new_process"

export default {
    name: "processCard",
    components: {DialogTitle, IconBase, SelectBatchModeDialog},
    props: {
        process: Object,
        tags: Array,
        groupAdministration: Boolean,
        fromProcesses: Boolean
    },

    data() {
        return {
            a: true,
            NEW_PROCESS,
            PROCESSES_STATE,
            SEARCH_PROCESS,
            INFO_PROCESS,
            CONTINUE_PROCESS,
            START_NEW_PROCESS,
            const_permissions,
            checkPermission,
            const_global,
            const_icons,
            recipients: [],
            continue_process_dialog: false,
            batchMode_dialog: false
        }
    },
    computed: {
        svgIconName() {
            return this.process.image ? this.process.image : "default"
        },

        processName() {
            return this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_NAME]
        },

        processNumberOfRecipients() {
            if (Array.isArray(this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_CURRENT_TRANSACTION_RECIPIENTS])) {
                return this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_CURRENT_TRANSACTION_RECIPIENTS].length
            }

            return 0
        }

    },
    methods: {
        ...mapActions({
            actionSetProcessTransaction: `${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_PROCESS_TRANSACTION}`
        }),

        batchModeDialogHandler(result) {
            let process_info = {
                [const_global.PROCESS_CODE]: this.process.code,
                [const_global.PROCESS_NAME]: this.process.name,
                [const_global.PROCESS_IMAGE]: this.process.image,
                [const_global.PROCESS_DESCRIPTION]: this.process.description,
                [const_global.PROCESS_RECIPIENTS]: [],
                batchMode: result
            }

            this.openProcess(process_info, true)
        },

        async executeItem(option) {
            let process_info = {
                [const_global.PROCESS_CODE]: this.process.code,
                [const_global.PROCESS_NAME]: this.process.name,
                [const_global.PROCESS_IMAGE]: this.process.image,
                [const_global.PROCESS_DESCRIPTION]: this.process.description,
                [const_global.PROCESS_RECIPIENTS]: []
            }

            const behaviorLayer = await getProcessBehaviorLayer(process_info.process_code)
            this.continue_process_dialog = false
            this.batchMode_dialog = false

            switch (option) {
                case NEW_PROCESS:

                    this.batchMode_dialog = behaviorLayer && behaviorLayer.batchModeEnabled
                    if (!this.batchMode_dialog) {
                        await this.openProcess(process_info, true)
                    }

                    break;

                case START_NEW_PROCESS:
                    this.batchMode_dialog = behaviorLayer && behaviorLayer.batchModeEnabled
                    if (!this.batchMode_dialog) {
                        this.openProcess(process_info, true)
                    }
                    break;

                case SEARCH_PROCESS:
                    redirect(TRANSACTIONS, {process_code: process_info[const_global.PROCESS_CODE]})
                    break;

                case INFO_PROCESS:
                    /* TODO: Modal de información del proceso */
                    break;
            }
        },
        async openProcess(process_info, restart) {

            if (restart) {
                this.resetTransactionData()

                setProcessInfo(process_info).then((response) => {
                    process_info[const_global.PROCESS_FIELDS] = response.data.parameters
                    process_info[const_global.PROCESS_METADATA] = response.data.metadata
                    this.actionSetProcessTransaction(process_info)

                    if (process_info) redirect(PROCESS_VIEW)

                    this.$gtag.event('test', {
                        'event_category': 'views',
                        'event_label': 'processes',
                        'value': process_info.process_name
                    })
                })
            } else {
                redirect(PROCESS_VIEW)
            }
        },

        resetTransactionData() {
            this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_CURRENT_TRANSACTION_RECIPIENTS}`, null)
            this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_CURRENT_TRANSACTION_DATA}`, null)
            this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_PROCESS_STEPS}`, null)
            this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_POSTAL_PROCESS_USER_CUSTOM_VALUES}`, false)
        }
    },

    // watch: {
    //   batchMode_dialog(newValue) {
    //     this.continue_process_dialog = newValue == null && !newValue
    //   }
    // }
}
</script>
<style>
.text-two-lines,
.text-three-lines,
.text-six-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-two-lines {
    -webkit-line-clamp: 2;
}

.text-three-lines {
    -webkit-line-clamp: 3;
}

.text-six-lines {
    -webkit-line-clamp: 6;
}
</style>

import { render, staticRenderFns } from "./RecipientRow.vue?vue&type=template&id=5b16f688&scoped=true"
import script from "./RecipientRow.vue?vue&type=script&lang=js"
export * from "./RecipientRow.vue?vue&type=script&lang=js"
import style0 from "./RecipientRow.vue?vue&type=style&index=0&id=5b16f688&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b16f688",
  null
  
)

export default component.exports
import {
  ACTIONS as AUTHENTICATION_ACTIONS,
  NAMESPACE as AUTHENTICATION_NAMESPACE,
  STATE as AUTHENTICATION_STATE
} from "@/constants/vuex/authentication"
import Axios from 'axios'
import ROUTES from "@/constants/router/routes"
import {redirect} from "@/util/router_utils"
import store from "@/store"
import router from "@/router"
import {ServerBus} from "@/main"

Axios.interceptors.request.use(
  (config) => {
    let language = store.state.location.currentLocation.iso
    let group_id = store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_GROUP_ID]
    let username = store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_USERNAME]
    let subaccount_code = store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_SUBACCOUNT_CODE]
    let token = store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_TOKEN_CODE]

    config.headers['Lang'] = language
    config.headers['GroupId'] = group_id
    config.headers['Username'] = username
    config.headers['SubaccountCode'] = subaccount_code
    config.headers['Token'] = token
    config.headers['Subdomain'] = window.location.host.split('.')[0].toLowerCase()

    return config
  },
  (error) => {
    return Promise.reject(error);
  }
)

Axios.interceptors.response.use(
  (response) => {
    if (process.env.VUE_APP_DEBUG_MODE == "true") {
      console.log(response)
    }

    ServerBus.$emit("resetSessionTimestamp")
    return response

  }, (error) => {
    store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_CHECKING_LOGIN}`, false)

    if (error.toString().indexOf("500") != -1) {
      localStorage.deleteItem("lastQueryTransactions")
      if (router.currentRoute.name !== ROUTES.LOGIN.NAME) {
        redirect(ROUTES.ERROR_500)
      }
    } else if (error.toString().toUpperCase().indexOf("NETWORK ERROR") != -1) {
      redirect(ROUTES.ERROR_500)
    } else if (error.toString().indexOf("403") != -1) {
      return
    } else {
      store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_LOGOUT_DATA}`)
      redirect(ROUTES.LOGIN)
      return Promise.reject(error.message);
    }

  }
)

export default Axios
